<template>
  <HeaderComponent :pageTitle="'My Overall Aims'" />  
  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
                 
      <!-- Aims Card -->
      <div v-if="getAims.length > 0" class="">
        <div v-for="(aim, index) in getAims" :key="aim.id" class="py-3">
          <!-- Headless UI Accordion -->
          <Disclosure>
            <template #default="{ open }">
              <!-- Ensure elements are stacked vertically -->
              <div class="flex flex-col space-y-2">
                <DisclosureButton class="relative flex items-center justify-between w-full p-4 bg-gradient-to-r from-primary-500 to-primary-400 rounded-xl cursor-pointer">
  <!-- Aim Number in the corner -->
  <h2 class="absolute top-2 left-2 rounded-lg px-1.5 py-0 bg-white text-xs font-bold text-primary-600">
    A{{ index + 1 }}
  </h2>

  <!-- Aim Title -->
  <h2 class="pt-4 pr-4 text-base md:text-base text-left font-normal font-sans text-white">
    {{ aim.aim }}
  </h2>

  <!-- Chevron Icon -->
  <ChevronDownIcon
    :class="{ 'rotate-180': open, 'rotate-0': !open }"
    class="text-white h-8 w-auto transform transition-transform duration-200"
  />
</DisclosureButton>

                <!-- Description Panel (Hidden/Visible based on open state) -->
                <DisclosurePanel class="p-4 text-body-base bg-white font-normal text-gray-600 rounded-lg shadow-sm border border-gray-100">
                  {{ aim.description }}
                </DisclosurePanel>
              </div>
            </template>
          </Disclosure>
        </div>
      </div>
      
      <div v-else>  
        <!-- No Entry Card -->  
        <p class="cf-tp-no-entries-title"> 
          No current entries
        </p>  
        <p class="cf-tp-no-entries-body"> 
          You and your therapist will add your Overall Aims' during treatment.
        </p> 
      </div>
    </main>
  </div>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/vue/20/solid';
</script>

<script>
export default {
  props: {
    aims: {
      type: Array,
      default: () => []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getAims() {
      if (!this.onClientPage) {
        return this.$store.getters.getAims;
      }
      return this.aims;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchAims");
    }
  },
};
</script>
