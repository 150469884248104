<template>
  <section :class="[paddingTopClass, paddingBottomClass, 'px-6']">
    <div class="container mx-auto">
      <!-- Simplified BlockContent Section -->
      <div class="text-center">
        <p v-if="overline" class="ws-text-overline mb-2 text-primary">
          {{ overline }}
        </p>
        <h1 class="ws-text-heading-md mb-4">{{ headline }}</h1>
        <p class="mx-auto max-w-2xl ws-text-body-base mb-6 opacity-75">{{ subheadline }}</p>
      </div>

      <!-- BlockListWithImage Section -->
      <div class="flex flex-col md:flex-row items-center">
        <div class="md:w-1/2 md:order-2">
          <img :src="listImageSrc" :alt="listImageAlt" class="w-full max-w-lg mx-auto md:ml-8" />
        </div>
        <div class="md:w-1/2 md:order-1">
          <ul class="space-y-4">
            <li v-for="(item, index) in listItems" :key="index" class="flex items-start space-x-3">
              <!-- Use a <span> to position the icon next to the title -->
              <span class="flex items-center">
                <CheckIcon class="h-6 w-6 stroke-2 text-primary" />
              </span>
              <div>
                <h3 class="ws-text-body-base-bold mb-1 flex items-center">
                  {{ item.title }}
                </h3>
                <p class="ws-text-body-base opacity-75">{{ item.description }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>





<script>
// Import the CheckIcon from Heroicons
import { KeyIcon, CalendarIcon, CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'BlockWhyChoose',
  components: {
    CheckIcon
  },
  props: {
    // Simplified content section
    overline: {
      type: String,
      default: 'Why Choose Us?'
    },
    headline: {
      type: String,
      default: 'Tailored Therapy & Mental Health Support'
    },
    subheadline: {
      type: String,
      default: 'We offer personalized sessions designed to meet your unique needs.'
    },

    // List with image section
    listImageSrc: {
      type: String,
      default: '/img/website/weekly-therapy-benefits.jpg'
    },
    listImageAlt: {
      type: String,
      default: 'An illustrative image'
    },
    listItems: {
      type: Array,
      default: () => ([
        { title: 'Clear Insights', description: 'Gain a comprehensive understanding of your current mental health and what’s affecting it.' },
        { title: 'Immediate Action', description: 'Leave with practical, actionable steps to start improving your well-being right away.' },
        { title: 'Personalized Approach', description: 'Benefit from a session tailored specifically to your needs and concerns.' },
        { title: 'Enhanced Preparation', description: 'Complete pre-session questionnaires and activities to maximize the effectiveness of our time together.' }
      ])
    },

    // General styling props
    paddingTopClass: {
      type: String,
      default: 'pt-16 sm:pt-20 md:pt-28'
    },
    paddingBottomClass: {
      type: String,
      default: 'pb-12 sm:pb-16 md:pb-28'
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.check-icon {
  width: 24px; /* Set a fixed width */
  height: 24px; /* Set a fixed height */
  color: var(--primary-color); /* Use the primary color */
  margin-right: 12px; /* Space between icon and text */
  stroke-width: 2; /* Ensure the icon is bold enough */
}
</style>

