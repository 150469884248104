<template>
  <HeaderComponent :pageTitle="'My Note'" />

  <div class="overflow-x-hidden">
    <main class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div class="max-w-2xl mx-auto">

        <!-- Date on the left, Save button on the right -->
        <div class="flex justify-between items-center mb-4 px-2 border-b pb-4">
          <h2 class="text-lg text-primary font-semibold">{{ formattedDate }}</h2>
          <button @click="saveNote" class="bg-primary text-white px-5 py-2 rounded">Save</button>
        </div>

        <!-- Edit mode for note content -->
        <ckeditor
          :key="editorKey"
          v-model="editableContent"
          :editor="editor"
          :config="editorConfig"
          placeholder="Edit your note..."
          class="min-h-64"
          ref="editorInstance"
        />
        
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';  // Import useRouter
import axios from 'axios';
import HeaderComponent from '../components/HeaderComponent';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

const route = useRoute();
const router = useRouter();  // Initialize useRouter

const noteContent = ref('');  // Content in view mode
const editableContent = ref('');  // Content in edit mode
const loading = ref(true);
const editor = BalloonEditor;
const editorConfig = {
  toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
};
const formattedDate = ref('');
const editorInstance = ref(null);

const editorKey = ref(0); // For forcing re-render of CKEditor

// Fetch note content from the server
async function fetchNote() {
  try {
    const response = await axios.get(`/api/user-notes/${route.params.id}`);
    noteContent.value = response.data.content; // Ensure this is in HTML format
    editableContent.value = noteContent.value;
    formattedDate.value = new Date(response.data.created_at).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
    nextTick(() => focusEditor());  // Focus editor after content loads
  } catch (error) {
    console.error("Error fetching note:", error);
  } finally {
    loading.value = false;
  }
}

// Focus editor function
function focusEditor() {
  setTimeout(() => {
    if (editorInstance.value && editorInstance.value.editorInstance) {
      editorInstance.value.editorInstance.ui.view.editable.element.focus();
    }
  }, 100);
}

// Save edited content and navigate back
async function saveNote() {
  try {
    const response = await axios.put(`/api/user-notes/${route.params.id}`, { content: editableContent.value });
    noteContent.value = response.data.content;  // Updated HTML content after saving
    
    // Redirect to the /user-notes page after saving
    router.push('/user-notes');
  } catch (error) {
    console.error("Error saving note:", error);
  }
}

onMounted(fetchNote);
</script>

<style scoped>
::v-deep .ck-editor__editable_inline p {
  padding: 0 !important;
  font-size: 16px !important; /* Adjust the font size as needed */
}
</style>