<template>

  <div class="min-h-screen bg-white overflow-hidden"> 
<!--Blur background-->
    <div class="min-h-screen relative isolate overflow-hidden">
      <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2  lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="opacity-20 aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] " style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
    </div>

    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="opacity-40 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
  <!--END background-->
    
  <Header />
  
    <div class=" pt-8 md:pt-12 pb-12 md:pb-16" data-aos="fade-up">
      <h3 class="ws-text-heading-3-black text-center">
        Take a free assessment
      </h3>
    </div>

    <div class="mx-auto px-6 max-w-3xl">  
<!--Card-->
      <div class="mx-auto md:min-w-xl pt-8 md:pt-12 pb-12 ws-card-bg-white-padding" data-aos="slide-left" data-aos-once="true" data-aos-duration="400">
                
<!--Avatar and Text-->

        <div class="px-8 md:px-16 pt-4 pb-8 flex">
          <div class="inline-block h-14 w-14 overflow-hidden ring-2 ring-white shadow-md rounded-full">
            <img class="h-14" src="/img/avatar.jpg" alt="Youlio founder image" />
          </div>
            <div class="my-auto ml-3">
              <p class="text-sm font-semibold text-gray-700 tracking-wide">Tom Mitchell</p>
              <p class="text-sm font-normal text-gray-500">Founder & Clinical Director</p>
            </div>
          </div> 
            
<!--Swiper-->
      
<div class="swiper-container">
<swiper
    :pagination="true"
    :navigation="true"
    :modules="modules"
    class="mySwiper"
    style="
    --swiper-pagination-color: #6597B8;
    --swiper-navigation-size: 24px;
    "
  >

<!--Slide1-->
        <swiper-slide class="pb-12">
          <div class="px-8 md:px-16">              
            <p class="ws-text-body-black">
              "We created this online assessment to help you see where your psychological health is currently at."
            </p>
          </div>  
        </swiper-slide>
<!--Slide2-->
        <swiper-slide>
          <div class="px-8 md:px-16">              
            <p class="ws-text-body-black">
              "It does this by looking at five different areas; depression, anxiety, stress, self-esteem & happiness."
            </p>
          </div>  
        </swiper-slide>
<!--Slide3-->
        <swiper-slide>
          <div class="px-8 md:px-16">              
            <p class="ws-text-body-black">
              "It only takes a few minutes and we will email your results to you straight away."
            </p>
          </div>                 
        </swiper-slide>
<!--END Swiper-->
      </swiper>
      </div>
    
        
<!--CTA--> 
        <div class="px-6 pt-6 pb-3 md:max-w-sm mx-auto">
          <router-link to="/assessment/start">
            <button type="submit" class="ws-button-primary-blue">
              Get Started
            </button>
          </router-link>
        </div>
          <p class="mx-auto   px-12 text-sm md:max-w-sm font-normal text-gray-400 text-center">All information provided will be kept secure and is entirely confidential.</p>    
      </div>
    </div>
  </div>
</div>
</template>
    
<script setup>

import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { CubeIcon } from '@heroicons/vue/24/solid'
import Header from '../../components/Header';
import { useRouter } from 'vue-router';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from "swiper";

const router = useRouter()

    function goBack() {
        return router.go(-1)
}


const modules = [Pagination, Navigation]

</script>

<style>
/* Custom CSS to position the swiper navigation arrows */
.swiper-container {
  position: relative;
  overflow: visible; /* Ensure the navigation arrows are visible outside the container */
  z-index: 1; /* Set z-index to 1 to make sure the arrows are behind the swiper slides */
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  color: #6597B8;
  z-index: 2; /* Ensure the arrows appear above the swiper slides */
  
  cursor: pointer;
}

/* Adjust the positioning of the arrows */
.swiper-button-prev {
  left: 5px;
}

.swiper-button-next {
  right: 5px;
}

/* Media query to hide navigation arrows on mobile */
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
</style>
  