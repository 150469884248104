<template>

<!-- Header -->	
    <header class="header-container">
      <router-link to="/activities">
      <div class="flex items-center">
        <ChevronLeftIcon class="flex items-center chevron-header-dark -ml-2" />
        <h1 class="text-page-title-dark">Questionnaire</h1>
      </div>  
    </router-link>   
  </header>
<!-- END Header -->	
<div class="overflow-x-hidden">
    <main class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250"> 

        <div v-if="getQuestionnaireInstance && getQuestionnaireInstance.questionnaire" class="md:max-w-md">

          <div class="card-bg-white-padding-none pb-8 relative">
            <img class="object-cover top-edge-rounded" :src="'/storage/' + getQuestionnaireInstance.questionnaire.image" alt="Image to represent the questionnaire being displayed" />
        <div class="top-edge-rounded absolute inset-0 h-32 md:h-44 bg-questionnaire opacity-30 " />
              
          <div class="px-4 py-3 bg-questionnaire">
            <h4 class="text-activity-type text-questionnaire-100">Questionnaire</h4>
            <p class="text-activity-display">{{ getQuestionnaireInstance.questionnaire.title }}</p>
           </div>
            
          <div class="px-4">
          <ul class="py-6 flex">
            <li class="py-2 flex items-center">
              <CircleStackIcon class="activity-icon" />
              <div class="ml-1 text-activity-icon-support">{{ getQuestionnaireInstance.status }}</div>
            </li>   
            <li class="ml-8 py-2 flex items-center">
              <ClockIcon class="activity-icon" />
            <div class="ml-1 text-activity-icon-support">{{ getQuestionnaireInstance.questionnaire.estimated_time }}</div>
            </li>            
          </ul>

            <p class="text-activity-body">{{ getQuestionnaireInstance.questionnaire.description }}</p>
  
  <router-link v-if="getQuestionnaireInstance.status !== 'Complete'" :to="{name: 'ActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: getQuestionnaireInstance.id }}">
   <button class="bg-questionnaire button-activity">
      {{ buttonText }}
    </button>
   </router-link>
   <div v-else>
    <p class="text-activity-body">
      <br>Congratulations! You've completed the questionnaire. Now, dive deeper and explore your results here!
    </p>
    <router-link :to="{ name: 'DeepDive' }">
      <button class="bg-questionnaire button-activity">
        Visit Deep Dive
      </button>
    </router-link>
   </div>
  </div> 
</div>
</div>

    <div v-else class="text-display-base">
      Loading...
    </div>
</main>
</div>
</template>

<script setup>
  
  import { ref, onMounted, computed } from "vue";
  import { useStore } from "vuex";
  import {ClockIcon, CircleStackIcon } from '@heroicons/vue/24/solid'
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline'

  //const loaded = false;

</script>

<script>
export default {
  data() {
    return {
      store: null
    };
  },
  mounted() {
    this.store = useStore();
    this.store.dispatch("fetchQuestionnaireInstance");
  },
  computed: {
    getQuestionnaireInstance() {
      if (this.store?.getters?.getQuestionnaireInstance) {
        return this.store.getters.getQuestionnaireInstance;
      }
      return null;
    },
    buttonText() {
      if (this.getQuestionnaireInstance) {
        return this.getQuestionnaireInstance.status == 'New' ? 'Begin' : 'Continue';
      }
      return '';
    }
  },
  methods: {}
};
</script>
