<template>

<!-- Title -->  
<div class="py-8 px-8"> 
        <h1 class="ws-text-overline block text-center">
          Advice
        </h1>
        <h1 class="ws-text-heading-2-black mt-2 text-center mx-auto md:max-w-lg">
          Types of therapy      
        </h1>
      </div> 
<!-- Title END -->
  

  <div class="py-4 px-6 mx-auto sm:max-w-xl">


  <!-- Quote -->
          <p class="ws-text-body-sm">         
              Therapy comes in all shapes and sizes so finding what works best for you is an important decision to finding effective treatment.          
          </p>
  <!-- Quote END -->  

  
  <!-- Image -->  
    <div class="py-4">
      <img class="w-full rounded-lg" src="/img/website/therapy_types/type-1.jpg" alt="Someone sitting on a sofa with their hands gripped together"  />         
    </div>

          <p class="ws-text-body-sm  italic">
            Therapies appear in alphabetical order A-Z
          </p>

<!-- ACT -->
    <h1 class="pt-8 ws-text-display-base  block text-left">
    Acceptance and Commitment Therapy (ACT)
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            Emerging from CBT, this therapy focuses on the idea of acceptance as a way to deal with negative thoughts, feelings and situations. In parallel, it encourages people to carry out more positive activities in line with their values and goals.  
          </p>
          <br />
          <p class="ws-text-body-sm ">
            ACT therapists believe that increasing acceptance increases psychological flexibility. Rather than try to reduce the level of unwanted thoughts and feelings they are simply acknowledged and in doing so their potency naturally diminishes.
          </p> 
          <br />
      
<!-- CBT -->
          <h1 class="ws-text-display-base  block text-left">
            Cognitive Behavioural Therapy (CBT)
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            A very popular and empirically proven therapy that explores the relationship between thoughts, feelings and behaviours. It is often said that it is not the situation but the way we perceive the situation that produces a negative outcome.  
          </p>
          <br />
          <p class="ws-text-body-sm ">
            The therapist and client will work together to uncover negative thought patterns and destructive behaviours. Alternatives will then be carried out, usually involving homework and practise in between sessions. Tools such as thought diaries and behavioural experiments are used to structure the therapy and positive results can be seen in as little as a few sessions.
          </p>   
          <br /> 

<!-- DBT -->
  <h1 class="ws-text-display-base  block text-left">
    Dialectical Behaviour Therapy (DBT)
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            Emerging out of CBT, DBT deals with the paradox of change and acceptance. Seemingly contrasting, these two aspects are brought together as it is thought that together they can produce the most optimal outcome. 
          </p>
          <br />
          <p class="ws-text-body-sm ">
            It can be particularly effective with people who have difficulty with emotional regulation or are exhibiting self-destructive behaviours. Unlike other therapies DBT often involves group work as well as more traditional one to one sessions.
          </p>
          <br />

  <!-- Gestalt -->
  <h1 class="ws-text-display-base  block text-left">
    Gestalt Therapy
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            Gestalt emphasises the here and now, helping clients to become more aware of their present. It falls under the umbrella of humanistic therapies with the word gestalt meaning ‘whole’ or ‘form’. 
          </p>
          <br />
          <p class="ws-text-body-sm ">
            Certain techniques are often encouraged to help bring our thoughts and feelings into light. For example, empty chairs encourages the client to place someone in a chair and engage as if they were actually there. Although techniques such as this may initially seem left field, they can have profound effects on the client and create long lasting positive change. 
          </p>   
          <br />
          
<!-- ITP -->
    <h1 class="ws-text-display-base  block text-left">
    Interpersonal Therapy (IPT)
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            As suggested by the name, this therapy looks at the relationships we have with others. Whether they are friends, partners, family or even work colleagues our interactions with people can contribute to the struggles we might experience. 
          </p>
          <br />
          <p class="ws-text-body-sm ">
            Certain behaviours can perpetuate problems we are already facing and so this therapy helps us to identify what our involvement is, what we can control or change to perhaps create different outcomes moving forward. 
          </p>  
          <br />

            <!-- Image -->  
            <div class="py-4">
      <img class="w-full rounded-lg" src="/img/website/therapy_types/type-2.jpg" alt="Two people engaged in a conversation"  />         
  </div>
    
          
<!-- MBCT -->
  <h1 class=" ws-text-display-base ">
    Mindfulness-based Cognitive Therapy (MBCT)
            </h1>
            <br />
          <p class=" ws-text-body-sm">
            This therapy builds upon cognitive therapy by introducing the concept of mindfulness meditation. This is the idea of encouraging clients to focus on the present with a non-judgemental attitude, especially towards oneself. 
          </p>
          <br />
          <p class=" ws-text-body-sm">
            The cognitive side of this therapy helps people to identify negative thought processes whilst the mindfulness side enables us to sit with these negative thoughts and simply observe them without interpretation. Techniques such as the body scan are used to activate the parasympathetic nervous system which in turn can help to calm down the mind.
          </p>
          <br />
  
  <!-- PC -->
  <h1 class="ws-text-display-base  block text-left">
    Person-centred Therapy
            </h1>
          <p class="ws-text-body-sm  ">
            This type of therapy revolves around the idea that everybody has the capacity for personal growth and change. There is no hierarchy involved and the therapist sees the client as the true expert of themselves. 
          </p>
          <br />
          <p class="ws-text-body-sm ">
            The therapist believes that showing true empathy (understanding) unconditional positive regard (no judgement) and congruence (being genuine) the client is able to work through problems in a safe space and reach their true potential. This kind of therapy is often associated with the term counselling and encourages people to talk freely about their situation.
          </p>    
          <br />

  <!-- PD -->
  <h1 class="ws-text-display-base  block text-left">
    Psychodynamic Therapy
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            This is the therapy associated with psychoanalysis and Dr Sigmund Freud. Key features are the belief that our childhood has shaped who we are today and that unconscious processes outside are awareness impact every part of us.  
          </p>
          <br />
          <p class="ws-text-body-sm ">
            Treatment is often long, traditionally with multiple sessions taking place every week. Techniques like free association along with dream analysis are used to bring our unconsciousness into consciousness. Gaining insight into what drives certain aspects of our being can lead to positive change in the present day.
          </p>   
          <br /> 
          
<!-- ST -->
<h1 class="ws-text-display-base  block text-left">
  Schema Therapy (ST)
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            Schema therapy combines multiple approaches such as CBT, transactional analysis, psychoanalysis and even gestalt therapy into one offering. It is known as an integrative approach and focuses heavily on uncovering our maladaptive schemas. If certain emotional needs are not met during our development then it is possible for these maladaptive schemas to develop and cause problems with every day functioning.
          </p>
          <br />
          <p class="ws-text-body-sm ">
            Schemas can be seen as blue prints for the way we think, feel and behave in certain areas of life and so by addressing them head on in adulthood we are able to finally get our emotional needs met and live a more positive, fulfilling life.
          </p>  
          <br />

<!-- SFT -->
<h1 class="ws-text-display-base  block text-left">
  Solution-focused Therapy (SFT)
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            SFT could be said to differ in comparison to other therapies because it is heavily goal orientated and focuses on our strengths rather than our weaknesses. Our skills, support networks, coping resources are all explored and then maximised to create and maintain long lasting change. 
          </p>
          <br />
          <p class="ws-text-body-sm ">
            It is a very positive approach where the future is considered far more important than the past. Change is assumed possible for all of us and much of the therapeutic work is spent looking at how we can create change utilising the strengths we have at our disposal.
          </p> 
          <br />

  <!-- TA -->
<h1 class="ws-text-display-base  block text-left">
  Transactional Analysis (TA)
            </h1>
            <br />
          <p class="ws-text-body-sm  ">
            Also coming under the humanistic umbrella, TA looks at our social interactions in relation to three ego states that we each exhibit, the adult, parent and child state. We often run scripts and games that ensure we take on a particular state regardless of whether the outcome for us is healthy or not.  
          </p>
          <br />
          <p class="ws-text-body-sm ">
            It is believed that by identifying the states we enter in certain situations we can then choose to perhaps switch to a more healthy state (this is normally seen as the adult state) and dramatically improve our lives in the process.
          </p> 
          <br />
  <!-- Closing Section -->
  <div class="py-8">
          <h1 class="ws-text-display-base block text-left">
          The list of therapies is endless
        </h1>
        <br />
        <p class=" ws-text-body-sm">
          Of course there are 100's of other therapies that are also carried out. These include:      
        </p>  
        <br />
        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-4">
          <li>Behavioural Therapy</li>
          <li>Cognitive analytic therapy (CAT)</li>
          <li>Creative therapies like Art Therapy, Play Therapy, Music Therapy</li>
          <li>Emotion-focused Therapy (EFT)</li>
          <li>Existential Therapy</li>
          <li>Eye Movement Desensitisation Reprogramming (EMDR)</li>
          <li>Family Therapy</li>
          <li>Transpersonal</li>
        </ul>
      </div>
      <br />
      <br />
  
  <!-- END -->

  </div>
  
</template>
  
<script setup>

</script>
  