<template>
  <div class="hidden">
    <button @click="openModal" class="group flex items-center px-2 py-2 bg-white font-sans text-medium font-medium rounded-md text-gray-700 hover:text-gray-700 hover:bg-blue-100"> 
    <ShieldCheckIcon class="mr-4 h-6 w-6 text-primary" aria-hidden="true" />       
      Welcome
    </button>  
  </div>

  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-90" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center py-3 px-4 text-center">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            
            <DialogPanel class="relative w-full transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-xl ">
              <div class="flex justify-end">
              <button type="button" @click=closeModal class="mr-3 p-1 rounded-full bg-secondary-100 hover:bg-secondary-200 mt-4 sm:mt-4 inline-flex justify-center sm:w-auto sm:text-sm focus:outline-none focus:outline-transparent">
                  <XMarkIcon class="h-5 w-auto text-secondary-700 " />
                </button>
              </div>

<!--Swiper-->
<div class="swiper-container items-center">
<swiper
    :pagination="true"
    :navigation="true"
    :modules="modules"
    class="mySwiper"
    style="
    --swiper-pagination-color: #6597B8;
    --swiper-navigation-size: 20px;
    "
  >

<!--Slide1-->
  <swiper-slide>
   
    <div class="pb-36 pt-6">
    <img class="mx-auto h-24 w-auto rounded-full border-2 border-white shadow-md" src="/img/avatar.jpg" alt="Youlio logo " />

<p class="pt-8 text-gray-700 text-center text-gray-700 text-lg font-semibold">Welcome to Youlio</p>

<div class="px-6 md:px-20 pt-4 ">
<p class="text-gray-700 font-light text-lg ">
  “Youlio is your private, safe space to support your mental health. I offer weekly therapy, mental health programs, and wellbeing MOTs to help you in the way that best suits your needs.”
</p>
<div class="pt-4">
<p class="text-gray-800 font-semibold text-sm ">Tom Mitchell</p>
<p class="text-sm font-normal text-primary ">(MSc PGDip MBACP GMBPsS)</p>
</div>
</div>
</div>
  
  </swiper-slide>

<!--Slide2-->

<swiper-slide>

<div class="pb-36">

<img class="mx-auto h-36 w-auto" src="/img/webapp/welcome-2.png" alt="Youlio logo " />

<p class="text-gray-700 text-center text-gray-700 text-lg font-semibold">Book a Free Consultation</p>

<div class="px-6 md:px-20 pt-4 ">
<p class="text-gray-700 font-light text-lg ">
“From your dashboard, you can now book a free 20 minute consultation with me at a time and date that works for you. This gives us an opportunity to meet and talk about your current situation, alongside the options availble to you."
</p>
</div>
</div>



</swiper-slide>
<!--Slide3-->
  <swiper-slide>

<div class="pb-36">
    <img class="mx-auto h-36 w-auto" src="/img/webapp/welcome-1.png" alt="Youlio logo " />

<p class="text-gray-700 text-center text-gray-700 text-lg font-semibold">Self-assessment</p>

<div class="px-6 md:px-20 pt-4 ">
<p class="text-gray-700 font-light text-lg ">
  If you took the self-assessment during sign-up, you'll now see your results on your dashboard. Click 'see more' to find out what your results mean. Click 'Check-In' if you would like to now take it.
</p>
</div>
</div>

  </swiper-slide>




<!--Slide4-->
<swiper-slide>
  <div class="pb-36">

  <img class="mx-auto h-36 w-auto" src="/img/webapp/welcome-3.png" alt="Youlio logo " />

<p class="text-gray-700 text-center text-gray-700 text-lg font-semibold">Reach Out Anytime</p>

<div class="px-6 md:px-20 pt-4 ">
<p class="text-gray-700 font-light text-lg ">
  “You now have access to Youlio chat. This means you can easily send a message to me by going to your chat page. It’s completely private and I will get back to you as soon as possible.”
</p>

</div>
</div>
</swiper-slide>

<!--Slide5-->
<swiper-slide>
  <div class="pb-36">
  <div class="mt-36 sm:mt-36 flex justify-center items-center sm:px-6 lg:px-8">
      <div class="h-48 flex items-center mx-auto sm:max-w-lg">

      <button @click=closeModal class="button-primary">
        OKAY, got it!
      </button>
   
      </div>
    </div>
  </div>
</swiper-slide>

  </swiper>
</div>
        
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>  

</template>
  
<script setup>

  import { ref, computed } from 'vue'
  import { Dialog, DialogPanel, DialogTitle, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot,} from '@headlessui/vue'
  import { useStore } from "vuex";
  import { useRouter } from 'vue-router';
  import { ExclamationTriangleIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline'
  import { ChevronLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline'
  import { sendPost } from './entities/functions';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from "swiper";
  
  const isOpen = ref(true)
  
  function closeModal() {
    isOpen.value = false;
    sendPost('/api/me/welcome-modal-viewed');
    store.dispatch('set_welcome_modal_viewed', true);
  }
  function openModal() {
    isOpen.value = true
  }
  
  const store = useStore();
  const router = useRouter()
  
  const user = computed(() => {
    return store.getters.user;
  });
  
  const modules = [Pagination, Navigation]
    
  </script>

<script>
export default {
  data() {
    return {
      shouldUsePoster: true
    };
  }
}
</script>

<style>
/* Custom CSS to position the swiper navigation arrows */
.swiper-container {
  position: relative;
  overflow: visible; /* Ensure the navigation arrows are visible outside the container */
  z-index: 1; /* Set z-index to 1 to make sure the arrows are behind the swiper slides */
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  color: #6597B8;
  z-index: 2; /* Ensure the arrows appear above the swiper slides */
  
  cursor: pointer;
}

/* Adjust the positioning of the arrows */
.swiper-button-prev {
  left: 0px;
}

.swiper-button-next {
  right: 0px;
}

/* Media query to hide navigation arrows on mobile */
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
</style>