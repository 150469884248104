<template>
 <HeaderComponent :pageTitle="client?.name || 'Client'" />
    <div class="overflow-x-hidden">
      <main class="layout-clientview px-4" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

<!-- Disclosure - Spotlights -->
        <div class="pt-4 text-secondary-600 w-full text-lg text-left font-semibold">
          <h3>Spotlights</h3>
        </div>
       
          <div class="mt-4">
            <div class="flex justify-between">
              <div class="mb-2 flex items-center text-page-subtitle-dark">To Do</div>
            </div>
            <div v-if="spotlightsTodo.length" class="grid md:grid-cols-2">
              <spotlight-card :spotlights="spotlightsTodo" :is-interactive="false" />
            </div>
            <p v-else class="card-no-entries">
              There are currently no 'Spotlights' in ToDo list.
            </p>

            <div v-if="spotlightsArchive.length">
              <div class="flex justify-between mt-12">
                <div class="mb-2 flex items-center text-page-subtitle-dark">Archive</div>
              </div>
              <div class="grid md:grid-cols-2">
                <spotlight-card
                  :spotlights="spotlightsArchive"
                  :is-interactive="false"
                  :is-show-answer="true"
                />
              </div>
            </div>
          </div>
 


</main>
</div>
</template>

<script setup>
  import { ref } from 'vue'
  import { AdjustmentsVerticalIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  import PsychologicalHealthFactorHistory from '../components/PsychologicalHealthFactorHistory';
  import DeepDiveValues from '../components/DeepDiveValues';
  import SpotlightCard from '../components/SpotlightCard';
  import HeaderComponent from '../components/HeaderComponent';

</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
      totalTodoRecords() {
        let total = 0;
        if (this.activitiesTodo) {
          if (this.activitiesTodo.questionnaire_instances) {
            total += this.activitiesTodo.questionnaire_instances.length;
          }
          if (this.activitiesTodo.reading_instances) {
            total += this.activitiesTodo.reading_instances.length;
          }
          if (this.activitiesTodo.worksheet_instances) {
            total += this.activitiesTodo.worksheet_instances.length;
          }
        }
        return total;
      },
      totalArchiveRecords() {
        let total = 0;
        if (this.activitiesArchive) {
          if (this.activitiesArchive.questionnaire_instances) {
            total += this.activitiesArchive.questionnaire_instances.length;
          }
          if (this.activitiesArchive.reading_instances) {
            total += this.activitiesArchive.reading_instances.length;
          }
          if (this.activitiesArchive.worksheet_instances) {
            total += this.activitiesArchive.worksheet_instances.length;
          }
        }
        return total;
      },
      reversedNotes() {
      // Return a reversed copy of the notes array
      return this.client.note ? this.client.note.slice().reverse() : [];
    }
    },

    watch: {
      activitiesTodoFilters() {
        this.getClientInfo();
      },
      activitiesArchiveFilters() {
        this.getClientInfo();
      },
    },

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      toggleMenu(type) {
        if (type == 'todo') {
          this.showToDoMenu = !this.showToDoMenu;
        } else {
          this.showArchiveMenu = !this.showArchiveMenu;
        }
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>
