export default {
  namespaced: true,
  state: {
    onlineStatuses: []
  },
  mutations: {
    SET_ONLINE_STATUSES(state, onlineStatuses) {
      state.onlineStatuses = onlineStatuses;
    }
  },
  actions: {
    updateOnlineStatuses({ commit }, onlineStatuses) {
      commit('SET_ONLINE_STATUSES', onlineStatuses);
    }
  },
  getters: {
    onlineStatuses(state) {
      return state.onlineStatuses;
    },
    unreadNotifications(state) {
      return state.onlineStatuses.filter(status => status.unread > 0);
    }
  }
};
