<template>

<HeaderComponent :pageTitle="'Spotlight'" />

<div class="overflow-x-hidden">
    <main class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250"> 


<!--Spotlight Question-->
      <div class="relative max-w-lg bg-secondary-200 rounded-t-xl "
        style="
          background-image: url('/img/webapp/dashboard/spotlight.jpg');
          background-repeat: no-repeat;
          background-size: cover;
          background-blend-mode: multiply;
        ">

<img class="pt-4 h-16" src="/img/webapp/dashboard/spotlight-icon.png" alt="imageAltText" />
        
      <div class="card-status-outline">
        {{ getSpotlight?.status }}
      </div>
   
   
      <div class="pt-2 px-4 items-center">
        <h4 class="text-activity-type text-primary-200">Spotlight</h4>
        <p class="pb-3 spotlight-question-text"> {{ getSpotlight?.spotlight_question }}</p>
      </div>
    </div>

<!--Answer Box-->
        <form v-if="getSpotlight" class="bg-white md:max-w-lg" @submit.prevent="updateSpotlight">
          <div class="mx-auto bg-white rounded-lg card-shadow">                    
            <textarea v-model="getSpotlight.spotlight_answer" rows="12" class="pt-4 px-4 spotlight-answer border-none w-full " placeholder="Write your answer here"></textarea>
           
          
<!--SubmitButton-->   
        <div class="px-4 py-4 flex mx-auto bg-gray-50 rounded-b-lg">
          <button type="submit" class="
          rounded-md 
  bg-primary hover:bg-primary-600
  px-16 py-2 
  text-base
  tracking-wide 
  font-semibold 
  text-white 
  text-center
  shadow-sm
  mx-auto 
          disabled:opacity-50 disabled:bg-gray-300 disabled:text-gray-600"
          :disabled="!getSpotlight.spotlight_answer">
          Save
          </button>
        </div> 
          </div>
        </form> 
         
<!--<p class="mb-2 text-right text-gray-600 text-xs">Date - 11th May</p>-->

  </main>
</div>
</template>

<script setup>
  
import { ref, onMounted, computed } from "vue";
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import HeaderComponent from '../components/HeaderComponent';

import { useStore } from "vuex";
import router from '../router';

const store = useStore();

const getSpotlight = computed(() => {
return store.getters.getSpotlight;
});

const updateSpotlight = () => {
  store.dispatch("updateSpotlight", getSpotlight.value);
  router.push('/spotlights');
};

onMounted(() => {
store.dispatch("fetchSpotlight"); 
});

</script>