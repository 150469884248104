<template>

<!--Button-->
<div class="card-cf-tp">
  <button type="button" @click="openModal" class="flex w-full items-center justify-between py-1">
    <img class="h-16" :src="imageSource" :alt="imageAltText" />
    <div class="flex-1 space-y-1">
      <div class="flex items-center justify-between">  
        <div class="ml-4">
          <h3 class="text-display-base">{{ title }}</h3>
          <h3 class="pt-1 text-xs font-bold text-primary tracking-wide text-left">LEARN MORE</h3>
        </div>
        <ChevronRightIcon class="h-9 w-auto text-gray-800 rounded-full" />
      </div>
    </div>
  </Button>
  </div>
<!--END Button-->
  
  
  <!--Modal Container-->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-40">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0" >
          <div class="fixed inset-0 bg-black bg-opacity-90" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div class="modal-placement">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95" >
              <DialogPanel class="modal-open-container" >
  <!--END Modal Container-->
  
  
  <!-- Modal Heading Section -->
          <DialogTitle class="modal-open-heading-container">
            <div class="flex items-center"> 
              <img class="h-16" :src="imageSource" :alt="imageAltText" />       
              <h3 class="pl-2 text-display-base-thin">{{ title }}</h3>
            </div>   
              <button type="button" @click="closeModal" class="modal-dismiss-button">
                <XMarkIcon class="modal-dismiss-icon" aria-hidden="true" />
              </button>         
          </DialogTitle>
  <!-- END Modal Heading Section -->

<!-- Modal Body Section -->
        <div v-if="getParentstyles.length > 0">
          <div v-for="parentstyle in getParentstyles" v-bind:key="parentstyle.id">
            
            <div class="modal-list">
            <UserGroupIcon class="modal-icon" />
            <p class="text-body-cf-tp-semibold">{{ parentstyle.parentstyle }}</p>
          </div>
            <p class=" px-2 mx-2 py-2 text-body-base text-left"><span class="font-medium italic">My Example:</span> {{ parentstyle  .category }}</p>        
          </div>
        </div>
          <div v-else>    
            <p class="cf-tp-no-entries-title"> 
              No current entries
            </p>  
            <p class="cf-tp-no-entries-body"> 
              You and your therapist will add your 'Received Parenting Styles' during treatment.
            </p>   
        </div>
<!-- END Modal Body Section -->

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
      </Dialog>
    </TransitionRoot>
</template>

<script setup>
  
  import { ref } from "vue";
  import { ChevronRightIcon, SunIcon, TrophyIcon, XMarkIcon, CubeIcon } from '@heroicons/vue/20/solid'
  import { UserGroupIcon } from '@heroicons/vue/24/outline'
  import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'

  const title = 'My Received Parenting'

  const isOpen = ref(false)

function closeModal() {
  isOpen.value = false
}
function openModal() {
  isOpen.value = true
}

</script>

<script>
export default {
  props: {
    parentstyles: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      imageSource: "/img/webapp/case_formulation/parenting-style.png",
      imageAltText: 'three people sitting round a table',
    };
  },

  computed: {
    getParentstyles() {
      if (!this.onClientPage) {
        return this.$store.getters.getParentstyles;
      }
      return this.parentstyles;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchParentstyles");
    }
  },
};
</script>
