<template>

  <!-- Title -->  
  <div class="py-8 px-8"> 
          <h1 class="ws-text-overline block text-center">
            Introducing
          </h1>
          <h1 class="ws-text-heading-2-black mt-2 text-center mx-auto md:max-w-lg">
            Stress     
          </h1>
        </div> 
  <!-- Title END -->
  
  <div class="py-4 px-6 mx-auto sm:max-w-xl">


<!-- What is stress -->
        <h1 class="ws-text-display-base  block text-left">
          What is stress?
          </h1>
          <br />
        <p class="ws-text-body-sm  ">
          Stress is our response to a change, threat or pressure put upon us from external or internal forces. Everyone experiences stress and like anxiety small amounts enable us to get things done and keep us motivated. The well known fight or flight (also known as the stress response) kicks in and we deal with the problem at hand before turning back to a more relaxed state.         
        </p>
        <br />
        <p class="ws-text-body-sm ">
          The problem is when stress becomes chronic and that response never gets switched off. The body and mind is not designed to exist like this for extended periods of times and the effects of doing so can be devastating.       
        </p> 
        <br />     
<!-- What is stress END -->

<!-- What causes stress -->
<h1 class="ws-text-display-base  block text-left">
          What causes stress?
        </h1>
        <br />
        <p class=" ws-text-body-sm">
          When discussing stress we often talk about stressors, these are the factors that are often igniting the stress response like financial problems, a breakup, issues at work. Perception is crucial because the stressor needs to be perceived as such by the receiver. Internal filters such as self-esteem, beliefs and life experiences can all interact with the stressor.        
        </p>  
        <br />
        
<!-- What causes stress END -->

<!-- Diagnosing stress -->
        <h1 class="ws-text-display-base  block text-left">
          Diagnosing Stress
          </h1>
          <br />
        <p class=" ws-text-body-sm">
          Stress is subjective and cannot be officially diagnosed but there are tests that can help us to identify how much distress it is causing.       
        </p>   
        <br /> 
<!-- Diagnosing stress END -->

<!-- Image -->  
        <figure>
          <img class="w-full rounded-lg" src="/img/website/common_problems/depression.jpg" alt="Decorative image" width="1310" height="873" />
        </figure>
        <br />
<!-- Image END -->

<!-- The stress response -->
        <h1 class="ws-text-display-base  block text-left">
          The stress response
        </h1>
        <br />
          <p class=" ws-text-body-sm">
          When we experience stress our sympathetic nervous system gets activated. This means…         
        </p>  
        <br />
        
        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-4">
          <li>Adrenaline gets pumped into the blood.</li>
          <li>Our heart beats faster which pushes blood to the heart, muscles and vital organs. </li>
          <li>Our pulse and blood pressure go up.</li>
          <li>We breathe more rapidly causing more oxygen to reach the brain which in turn raises alertness.</li>
          <li>Adrenaline also triggers the release of glucose and fats. These nutrients hit the bloodstream to provide increased energy.</li>
           
        </ul>
        <br />

        <p class=" ws-text-body-sm">
          We are now ready for fight or flight.....        
      </p>  
      <br />
      <p class=" ws-text-body-sm">
        If the body still perceives there to be a danger, cortisol is then released to keep us revved up and on high alert.       
      </p> 
      <br /> 
<!-- The stress response END -->

<!-- How do we treat stress-->
<h1 class="ws-text-display-base  block text-left">
  How do we treat stress?
        </h1>
        <br />
        <p class=" ws-text-body-sm">
          Fundamentally there are two effective ways of treating stress. One is to find ways to activate the parasympathetic nervous system, also known as the rest and digest response. Exercises that help to calm the body and mind can work well.         
        </p>  
        <br />
        <p class=" ws-text-body-sm">
          The second way is to reduce the impact of the stressors themselves if possible. From a behaviour perspective this may involve changing certain life situations and from a cognitive perspective it may mean changing the way we perceive the stressors.        
        </p>  
        <br />
        <br />
<!-- How do we treat stress END-->


  </div>
</template>

<script setup>

</script>
