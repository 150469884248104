<template>

<div class="min-h-full bg-black">
    <router-view />
  </div>

</template>

<script setup>
  
</script>


