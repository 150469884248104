<template>
  <HeaderComponent :pageTitle="client?.name || 'Client'" />
    <div class="overflow-x-hidden">
      <main class="layout-clientview px-4" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

      <div class="pt-4 text-secondary-600 w-full text-left font-semibold">
        <h3>Stage 2 - What Do We Want It To Look Like?</h3>
      </div>
 
            <div class="mt-8">
              <treatment-plan-aims :aims="client?.aim || []" :on-client-page="true" />
            </div>
           
       
</main>
</div>
</template>

<script setup>
  import { sendPost } from '../components/entities/functions.js';
  import HeaderComponent from '../components/HeaderComponent';
  import TreatmentPlanAims from '../components/TreatmentPlanAims';

 

</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
      totalTodoRecords() {
        let total = 0;
        if (this.activitiesTodo) {
          if (this.activitiesTodo.questionnaire_instances) {
            total += this.activitiesTodo.questionnaire_instances.length;
          }
          if (this.activitiesTodo.reading_instances) {
            total += this.activitiesTodo.reading_instances.length;
          }
          if (this.activitiesTodo.worksheet_instances) {
            total += this.activitiesTodo.worksheet_instances.length;
          }
        }
        return total;
      },
      totalArchiveRecords() {
        let total = 0;
        if (this.activitiesArchive) {
          if (this.activitiesArchive.questionnaire_instances) {
            total += this.activitiesArchive.questionnaire_instances.length;
          }
          if (this.activitiesArchive.reading_instances) {
            total += this.activitiesArchive.reading_instances.length;
          }
          if (this.activitiesArchive.worksheet_instances) {
            total += this.activitiesArchive.worksheet_instances.length;
          }
        }
        return total;
      },
      reversedNotes() {
      // Return a reversed copy of the notes array
      return this.client.note ? this.client.note.slice().reverse() : [];
    }
    },

    watch: {
      activitiesTodoFilters() {
        this.getClientInfo();
      },
      activitiesArchiveFilters() {
        this.getClientInfo();
      },
    },

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      toggleMenu(type) {
        if (type == 'todo') {
          this.showToDoMenu = !this.showToDoMenu;
        } else {
          this.showArchiveMenu = !this.showArchiveMenu;
        }
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>
