<template>
  <form @submit.prevent="update" class="space-y-6">
    <div class="pt-4">
      <label class="block text-display-sm" for="name">
        Name
      </label>
      <input v-model="data.name" class="text-body-base p-2 rounded-md bg-white border border-gray-300  block mt-1 w-full" type="text" id="name" name="name" required="required">
    </div>
    <div>
      <label class="block text-display-sm" for="email">
        Email
      </label>
      <input v-model="data.email" class="text-body-base p-2 rounded-md bg-white border border-gray-300 block mt-1 w-full" type="email" id="email" name="email" required="required">
    </div>
    <div v-if="errors" class="text-red-500 py-2 font-semibold">
      <span>{{ errors.message }}</span>
    </div>

    <div v-if="profileUpdated" class="alert-green">
  Profile updated successfully!
</div>  

    <div class="flex items-center">
      <button type="submit" class="mt-4 button-secondary">
        Update
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      profileUpdated: false,
      errors: null,
      data: {
        name: null,
        email: null,
      }
    }
  },
  mounted() {
    this.data.name = this.user.name
    this.data.email = this.user.email
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  methods: {
    update() {
      axios.put('/user/profile-information', this.data)
        .then((response) => {
          this.$store.dispatch('attempt_user');

          this.profileUpdated = true; // Set profileUpdated to true

        setTimeout(() => {
          this.profileUpdated = false; // Reset profileUpdated to false after a few seconds (e.g., 3 seconds)
        }, 3000); // 3000 milliseconds = 3 seconds
      })
    
        .catch((error) => {
          this.errors = error.response.data
        })
    },
  }
}
</script>
