<template>
    <div>
      <p class="text-left text-4xl md:text-5xl font-serif font-light">
        <span v-for="(char, index) in displayedCharacters" :key="index" :class="char.class">{{ char.char }}</span>
      </p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        text: "Hello, I'm Tom...",
        displayedCharacters: [],
        currentIndex: 0,
      };
    },
    mounted() {
      this.typeWriter();
    },
    methods: {
      typeWriter() {
        if (this.currentIndex < this.text.length) {
          const nextChar = this.text.charAt(this.currentIndex);
          let charClass = 'text-primary'; // Default color
  
          if (this.currentIndex < 6) {
            // First 5 characters "Hello"
            charClass = 'text-gray-700'; // Change this to your desired color
          }
  
          this.displayedCharacters.push({ char: nextChar, class: charClass });
          this.currentIndex++;
          setTimeout(this.typeWriter, 75); // Adjust typing speed here
        }
      },
    },
  };
  </script>