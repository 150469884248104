<template>
  <HeaderComponent :pageTitle="client?.name || 'Client'" />
    <div class="overflow-x-hidden">
      <main class="layout-clientview px-4" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">


      <div class="pt-4 text-secondary-600 w-full text-left font-semibold">
        <h3>Stage 1 - What Does It Currently Look Like?</h3>
      </div>

            <div class="mt-8">
              <case-formulation-symptoms :symptoms="client?.symptom || []" :on-client-page="true" />
            </div>
            <div class="mt-4">
              <case-formulation-triggers :problems="client?.problem || []" :on-client-page="true" />
            </div>

            <div class="mt-4">
              <case-formulation-life-traps :lifetraps="client?.lifetrap || []" :on-client-page="true" />
            </div>
            <div class="mt-4">
              <case-formulation-states :emotionalstates="client?.emotionalstate || []" :on-client-page="true" />
            </div>
            <!-- Triad
            <div class="mt-4 md:max-w-lg">
              <div class="flex justify-center">
                <div>
                  <case-formulation-feelings :feelings="client?.feeling || []" :on-client-page="true" />
                </div>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <div class="md:ml-16">
                  <case-formulation-thoughts :thoughts="client?.thought || []" :on-client-page="true" />
                </div>
                <div class="items-center">
                  <img class="h-28 w-auto" src="/img/webapp/case_formulation/brain.png" alt="Human brain" />
                </div>
                <div class="md:mr-16">
                  <case-formulation-behaviours :behaviours="client?.behaviour || []" :on-client-page="true" />
                </div>
              </div>
            </div> 

            <div class="mt-4">
              <case-formulation-life-balance :lifeslices="client?.lifeslice || []" :on-client-page="true" />
            </div>

           
            <div class="mt-4">
              <case-formulation-parenting :parentstyles="client?.parentstyle || []" :on-client-page="true" />
            </div>
            <div class="mt-4">
              <case-formulation-values :values="client?.value || []" :on-client-page="true" />
            </div>
            <div class="mt-4">
              <case-formulation-personality :characters="client?.character || []" :on-client-page="true" />
            </div>-->
  
</main>
</div>
</template>

<script setup>
  import { sendPost } from '../components/entities/functions.js';
  import CaseFormulationSymptoms from '../components/CaseFormulationSymptoms';
  import CaseFormulationTriggers from '../components/CaseFormulationTriggers';
  import CaseFormulationBehaviours from '../components/CaseFormulationBehaviours';
  import CaseFormulationThoughts from '../components/CaseFormulationThoughts';
  import CaseFormulationFeelings from '../components/CaseFormulationFeelings';
  import CaseFormulationPersonality from '../components/CaseFormulationPersonality';
  import CaseFormulationValues from '../components/CaseFormulationValues';
  import CaseFormulationLifeBalance from '../components/CaseFormulationLifeBalance';
  import CaseFormulationLifeTraps from '../components/CaseFormulationLifeTraps';
  import CaseFormulationStates from '../components/CaseFormulationStates';
  import CaseFormulationParenting from '../components/CaseFormulationParenting';

  import HeaderComponent from '../components/HeaderComponent';

</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
      totalTodoRecords() {
        let total = 0;
        if (this.activitiesTodo) {
          if (this.activitiesTodo.questionnaire_instances) {
            total += this.activitiesTodo.questionnaire_instances.length;
          }
          if (this.activitiesTodo.reading_instances) {
            total += this.activitiesTodo.reading_instances.length;
          }
          if (this.activitiesTodo.worksheet_instances) {
            total += this.activitiesTodo.worksheet_instances.length;
          }
        }
        return total;
      },
      totalArchiveRecords() {
        let total = 0;
        if (this.activitiesArchive) {
          if (this.activitiesArchive.questionnaire_instances) {
            total += this.activitiesArchive.questionnaire_instances.length;
          }
          if (this.activitiesArchive.reading_instances) {
            total += this.activitiesArchive.reading_instances.length;
          }
          if (this.activitiesArchive.worksheet_instances) {
            total += this.activitiesArchive.worksheet_instances.length;
          }
        }
        return total;
      },
      reversedNotes() {
      // Return a reversed copy of the notes array
      return this.client.note ? this.client.note.slice().reverse() : [];
    }
    },

    watch: {
      activitiesTodoFilters() {
        this.getClientInfo();
      },
      activitiesArchiveFilters() {
        this.getClientInfo();
      },
    },

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      toggleMenu(type) {
        if (type == 'todo') {
          this.showToDoMenu = !this.showToDoMenu;
        } else {
          this.showArchiveMenu = !this.showArchiveMenu;
        }
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>
