<template>
  <div id="app" class="bg-transparent pt-safe-area">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view v-slot="{ Component }">
          <component :is="Component" :key="$route.path" />   
      </router-view>
    </component>
  </div>
</template>

<script>
  import axios from 'axios'
  import router from '../js/router'
  import store from '../js/store'

  export default {
    mounted() {
      axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if (error.response.status === 401 || error.response.status === 419) {
          if(error.response.data.message === 'CSRF token mismatch.') return
          store.dispatch('logout')
          router.replace({name: 'Login'})
        } else if(error.response.status === 403) {
          router.push({name: 'VerifyEmail'})
        } else if(error.response.status === 423) {
          router.push({name: 'ConfirmPassword'})
        }

        return Promise.reject(error);
      });
    },
  }
</script>

<style>
#app {
  background-color: transparent;
}

.pt-safe-area {
  padding-top: env(safe-area-inset-top);
}
</style>