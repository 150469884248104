<template>
<section class="bg-white overflow-x-hidden">
    <div>
      <img src="/img/website/landing/wave-blue-3.png" alt="Blue vector wave" class="" />  
    </div> 

    <div class="pt-16 pb-8" data-aos="flip-up" data-aos-once="true" data-aos-duration="1000">
    <p class="mx-auto md:max-w-xl ws-text-heading-2-black px-8 text-center" >
      <span class="font-light">How Do I</span> <br /> Approach Therapy?
    </p>
    <div class="max-w-xs md:max-w-md mx-auto mt-1 md:mt-2">
      <img src="/img/website/landing/line.png" alt="green line" />  
    </div>

    <div class="bg-white overflow-x-hidden pb-16 md:pb-32 px-6 md:px-0 mx-auto md:max-w-4xl " >    
        <div class="md:pt-16 flex flex-col md:flex-row gap-4">
          <div class="order-2 md:order-1 flex-1">
          <!-- Content for the first column -->
            <p class="md:pr-6 mt-2 md:mt-6 text-xl md:text-xl font-light text-gray-700">
              “The relationship between us is one of the most important factors throughout your therapy journey. There are a number of ways this can be approached so to offer clarity and guidance, I've distilled my therapeutic approach into six key principles outlining how we will navigate our journey together."            </p>
            <!-- CTA -->
          <div class="mt-6 md:mt-6 flex items-center md:justify-start gap-x-6">
    <router-link to="/our-approach" class="flex items-center ">
      <div class="font-bold text-primary text-lg  underline underline-offset-4">
        Learn More
      </div>
      <ChevronRightIcon class="pt-1 h-8 w-8 text-primary" />
    </router-link>
  </div>
          </div>
          <div class="order-1 md:order-2 flex-1">
          <!-- Content for the second column -->
          <div class="mx-auto mb-6 md:mb-0 mt-6 md:mt-0 max-w-sm">
          <video class="rounded-xl shadow-lg" controls playsinline preload="metadata" 
          :poster="shouldUsePoster ? '/img/website/landing/video-poster-approach.jpg' : null">
            <source src="/img/website/landing/video-approach.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          </div>
          </div>
        </div>      
      </div>   
    </div>
    </section> 

</template>

<script setup>

import { ChevronRightIcon } from '@heroicons/vue/20/solid'  

</script>

<script>
    export default {
      data() {
        return {
          shouldUsePoster: true
        };
      }
    }
</script>