<template>
  <div class="">
<!-- Title -->
    <div class="flex justify-between items-center mt-8 md:mt-12 mb-4">
      <router-link to="/activities">
      <h1 class="text-page-subtitle-dark">Next Activity</h1>
    </router-link>
      <router-link to="/activities">
        <div class="flex items-center justify-center text-primary-500 hover:text-primary-400">
      <h3 class="text-sm font-medium">See all</h3>
      <chevron-right-icon class="chevron-dashboard" />
    </div>
    </router-link>
    </div>
<!-- END Title -->

<!-- Card Body -->
    <div class="">
      <!-- Content goes here -->
      <activity-card v-if="totalTodoRecords" :activities="todo"/>
      <div v-else class="card-no-entries">
        <p class="">There are currently no 'Activities' in your Todo list.</p>
      </div>
      <!-- Content goes here -->
    </div>
    <!-- Card END -->
  </div>
</template>
  
<script setup>
  
  import ActivityCard from './ActivityCard' ;
  import { sendPost } from './entities/functions.js';
  import { ChevronRightIcon } from '@heroicons/vue/20/solid'

</script>

<script>
export default {
  data: function () {
    return {
      todo: [],
    }
  },
  mounted() {
    this.getContent();
  },

  computed: {
    totalTodoRecords() {
      let total = 0;
      if (this.todo) {
        if (this.todo.questionnaire_instances) {
          total += this.todo.questionnaire_instances.length;
        }
        if (this.todo.reading_instances) {
          total += this.todo.reading_instances.length;
        }
        if (this.todo.worksheet_instances) {
          total += this.todo.worksheet_instances.length;
        }
      }
      return total;
    },
  },

  methods: {
    async getContent() {
      const response = await sendPost('/api/content/activities', {
        todo_types: ['questionnaires', 'worksheets', 'readings'],
        limit: 1
      });
      this.todo = response.todo;
    },
  }
}
</script>
