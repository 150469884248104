<template>

<!-- Hero Section -->
<section class="-mt-24 bg-white" >
      <div class="relative isolate overflow-hidden" >
<!-- Background Blur -->
        <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2  lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
          <div class="opacity-50 aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0]" style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
        </div>
          <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
            <div class="opacity-40 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
          </div>
<!-- END Background Blur -->
          <div class="mx-auto px-6 mt- mb-16 mt-36 md:mt-48 md:mb-20">
            <h1 class="ws-text-overline text-primary text-center" data-aos="flip-right" data-aos-once="true" data-aos-duration="1000">
              Common Problems
            </h1>
            <p class="mt-2 ws-text-heading-sm text-gray-700 text-center mx-auto md:px-12 sm:max-w-3xl" data-aos="slide-right" data-aos-once="true" data-aos-duration="1000">
              Learn about common mental health problems
            </p>          
            </div>
          </div>
    </section>
<!-- END Hero Section -->


<section class="bg-white">
  <div class="mx-auto px-4 py-16 md:p-28  ">
    <div class="mx-auto grid grid-cols-1 gap-x-4 sm:gap-x-24 gap-y-8 sm:grid-cols-2">
      <div v-for="problem in problems" :key="problem.source" class="relative" data-aos="flip-right" data-aos-once="true" data-aos-duration="1000">
        <a :href=problem.ahref>
        <div class="bg-primary-600 border h-32 overflow-hidden rounded-md hover:bg-primary-700">
        <p class="pointer-events-none mt-2 block truncate ws-text-body-base-bold text-white p-8">{{ problem.title }}</p>
        </div>
        </a>
      </div>
    </div>
  </div>
</section>


  
</template>

<script setup>

  const problems = [
  {
    title: 'Depression',
    ahref:'/common-problems/depression',
    source:
      '/img/website/common_problems/depression.jpg',
  },

  {
    title: 'Anxiety',
    ahref:'/common-problems/anxiety',
    source:
    '/img/website/common_problems/anxiety.jpg',
  },

  {
    title: 'Self-esteem',
    ahref:'/common-problems/self-esteem',
    source:
    '/img/website/common_problems/self_esteem.jpg',
  },

  {
    title: 'Stress',
    ahref:'/common-problems/stress',
    source:
    '/img/website/common_problems/stress.jpg',
  },

]
</script>
