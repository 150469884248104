<template>
  <div class="min-h-screen bg-white">  
    
    <Header />

    <div class="relative mx-auto flex items-center max-w-6xl flex-col px-6 sm:pt-16 lg:px-16">
      <div class="w-full sm:max-w-xl pt-16 overflow-hidden sm:rounded-lg">
        <h1 class="ws-text-heading-thin-black text-center" data-aos="slide-left" data-aos-once="true" data-aos-duration="500">
            Forgot your password?
          </h1>
          <p class="mx-auto sm:max-w-sm pt-4 pb-8 px-8 ws-text-body-sm text-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500">
          Enter your email address and we'll send you a link to reset it.
        </p>
      </div>

    <div class="w-full sm:max-w-md">
      <div class="ws-card-bg-white-padding px-4 py-8">
      <form @submit.prevent="resetPassword">
        <div>
          <label class="ws-text-label" for="email">
            Email
          </label>
          <input v-model="email" class="ws-text-input" id="email" type="email" name="email" required="required" autofocus="autofocus">
        </div>
        <div v-if="errors" class="text-red-500 py-2 font-semibold">
          <span>{{ errors.message }}</span>
        </div>

        <div v-if="passwordReset" class="mt-4 ws-alert-green">
      Your password reset link has been sent!
    </div>

        <div class="flex items-center justify-end mt-8">
          <button type="submit" class="ws-button-primary-green">
            Reset Password
          </button>
        </div>
      </form>
    </div>
  </div>
  </div>
</div>
  
</template>

<script>

export default {
  data: () => {
    return {
      passwordReset: false,
      email: null,
      errors: null,
    }
  },
  components: {
  },
  methods: {
    resetPassword() {
      this.errors = null
      axios.post('/forgot-password', { email: this.email })
        .then((response) => {
          console.log(response)

          this.passwordReset = true; // Set passwordUpdated to true
            setTimeout(() => {
        this.passwordReset = false;
      }, 3000); // 3000 milliseconds = 3 seconds
          })
       
        
        .catch((error) => {
          this.errors = error.response.data
        })
    }
  }
}
</script>

<script setup>

import Header from '../../website/components/Header';

</script>
