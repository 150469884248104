<template>
  <div>
    <div v-if="showOverlay" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
      <div class="text-center">
        <div role="status">
          <SpinnerIcon class="w-16 h-16 text-primary animate-spin" />
        </div>
        <button v-show="showRealoadButton" @click="reloadPage" class="mt-2">Reload</button>
      </div>
    </div>
  </div>
</template>
    
<script setup>
  import { ArrowPathIcon as SpinnerIcon } from '@heroicons/vue/24/outline'
</script>

<script>
  export default {
    props: {
      loading: Boolean
    },

    data: function () {
      return {
        showOverlay: false,
        showRealoadButton: false,
        timer: null,
        timeoutTimer: null,
      }
    },

    mounted() {
      this.timer = setTimeout(() => {
        this.showOverlay = true;
      }, 300);
      this.timeoutTimer = setTimeout(() => {
        this.showRealoadButton = true;
      }, 30000);
    },

    watch: {
      loading: function (newValue) {
        if (!newValue) {
          clearTimeout(this.timer);
          clearTimeout(this.timeoutTimer);
          this.showOverlay = false;
          this.showRealoadButton = false;
        }
      }
    },

    methods: {
      reloadPage() {
        location.reload();
      }
    }
  }
</script>
