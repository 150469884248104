<template>
  <dl class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">
    <component
      :is="isInteractive ? 'router-link' : 'span'"
      v-for="(spotlight, index) in spotlights"
      :key="spotlight.id"
      :to="{ name: 'Spotlight', params: { spotlightid: spotlight.id } }"
    >
      <div class="relative rounded-xl max-w-sm bg-secondary-200 h-32 hover:opacity-80"
        style="
          background-image: url('/img/webapp/dashboard/spotlight.jpg');
          background-repeat: no-repeat;
          background-size: cover;
          background-blend-mode: multiply;
        "
      >
        <img class="pt-2 h-14" src="/img/webapp/dashboard/spotlight-icon.png" alt="imageAltText" />

        <div class="card-status-outline">
          <p>{{ spotlight.status }}</p>
        </div>

        <div class="px-4">
          <h4 class="text-activity-type text-primary-200">Spotlight</h4>
          <p class="mb-2 spotlight-card-text">{{ spotlight.spotlight_question }}</p>
        </div>

        <div v-if="isShowAnswer && spotlight.status == 'Complete'"
          class="bg-cyan-800 text-white px-2 py-1 rounded-full text-xs font-semibold absolute cursor-pointer right-3 top-10"
          @click="openModal(spotlight)"
        >
          Show Answer
        </div>

     
      </div>
    </component>

    <!-- Use the modal component for showing the answer -->
    <SpotlightAnswerModal
      v-if="modalVisible"
      :isOpen="modalVisible"
      :spotlightQuestion="activeSpotlight.spotlight_question"
      :spotlightAnswer="activeSpotlight.spotlight_answer"
      @close="handleCloseModal"
    />
  </dl>
</template>

<script>
import SpotlightAnswerModal from './SpotlightAnswerModal.vue';

export default {
  components: {
    SpotlightAnswerModal
  },
  props: {
    spotlights: Array,
    isInteractive: {
      type: Boolean,
      default: true
    },
    isShowAnswer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalVisible: false,
      activeSpotlight: {}
    };
  },
  methods: {
    openModal(spotlight) {
      this.activeSpotlight = spotlight;
      this.modalVisible = true;
    },
    handleCloseModal() {
      console.log('Closing modal');
      this.modalVisible = false;
    }
  }
};
</script>
