<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-90" />
      </TransitionChild>

      <!-- Modal Wrapper -->
      <div class="px-4 fixed inset-0 flex items-end justify-center overflow-y-auto">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="translate-y-full opacity-0"
          enter-to="translate-y-0 opacity-100"
          leave="duration-200 ease-in"
          leave-from="translate-y-0 opacity-100"
          leave-to="translate-y-full opacity-0"
        >
          <DialogPanel
            class="bg-white rounded-t-xl w-full md:w-1/3 lg:w-1/3 h-[50vh] max-w-full md:max-w-lg lg:max-w-xl transform overflow-hidden text-left align-middle transition-all"
            style="margin-bottom: env(safe-area-inset-bottom);"
          >
            <!-- Close Button at Top Right -->
            <button
              @click="closeModal"
              class="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <XMarkIcon class="h-7 w-7" aria-hidden="true" />
              <span class="sr-only">Close</span>
            </button>

            <!-- Title -->
            <DialogTitle
              as="h3"
              class="px-4 py-4 text-left text-secondary-600 font-semibold text-lg bg-gradient-to-r from-primary-200 to-secondary-200"
            >
              {{ title }}
            </DialogTitle>

            <!-- Content -->
            <div v-for="(text, index) in content" :key="index" class="mt-6 px-4">
              <p class="text-gray-600 font-normal text-base" v-html="text"></p>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
 import { XMarkIcon } from '@heroicons/vue/24/outline'
</script>

<script>
export default {
  data: function () {
    return {
      isOpen: false,
      title: String,
      content: String
    }
  },

  inject: ['informationModalActions'],

  mounted() {
    if (this.informationModalActions) {
      this.informationModalActions.openModal = this.openModal;
      this.informationModalActions.closeModal = this.closeModal;
    }
  },

  beforeUnmount() {
    if (this.informationModalActions) {
      this.informationModalActions.openModal = null;
      this.informationModalActions.closeModal = null;
    }
  },

  methods: {
    closeModal() {
      this.isOpen = false;
    },

    openModal(title, text) {
  if (!Array.isArray(text)) {
    text = [text];
  }

  this.title = title;
  this.content = this.splitSentences(text);  // Pass the array to splitSentences
  this.isOpen = true;
},
splitSentences(arr) {
  const result = [];
  arr.forEach(item => {
    const sentences = item.split('. ');
    sentences.forEach((sentence, index) => {
      // Add the sentence back with a full stop (except for the last sentence in the array)
      result.push(index === sentences.length - 1 ? sentence : sentence + '.');
    });
  });
  return result;
},
  },
}
</script>
