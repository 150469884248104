<template>
    <div class="w-full my-2 ">
        <div v-for="client in data" :key="client.id" class="py-3 my-1">
            <div @click="goToClientPage(client.id)" class="bg-gradient-to-r from-primary-200 to-secondary-200 w-full p-4 shadow-md rounded-md text-lg font-semibold text-secondary-600 cursor-pointer">
                <p>{{ client.name }}</p>
                <span v-for="status in client.statuses" :key="'status_' + client.id + '_' + status.id" class="bg-white text-gray-700 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                  {{ status.name }}
                </span>
            </div>
        </div>
    </div>
  </template>

  <script setup>

  </script>

  <script>
  export default {
    props: {
      data: Object
    },
    data: function () {
      return {

      }
    },

    methods: {
      goToClientPage(id) {
          this.$router.push({ name: 'ClientView', params: { clientId: id }});
      }
    }
  }
  </script>
