<template>

<!--Button-->
  <button type="button" @click="openModal" class="modal-closed-container-sm card-height-cf">  
    <h3 class="text-display-base">{{ title }}</h3>  
    <img class="mx-auto h-10 w-auto" :src="imageSource" :alt="imageAltText" />
  </button>
<!--ENDButton-->

  <!--Modal Container-->
  <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-40">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0" >
          <div class="fixed inset-0 bg-black bg-opacity-90" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div class="modal-placement">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95" >
              <DialogPanel class="modal-open-container" >
  <!--END Modal Container-->
  
  
  <!-- Modal Heading Section -->
          <DialogTitle class="modal-open-heading-container">
            <div class="pl-2 flex items-center py-2"> 
              <img class="h-10" :src="imageSource" :alt="imageAltText" />       
              <h3 class="pl-2 text-display-base-thin">{{ title }}</h3>
            </div>   
              <button type="button" @click="closeModal" class="modal-dismiss-button">
                <XMarkIcon class="modal-dismiss-icon" aria-hidden="true" />
              </button>         
          </DialogTitle>
  <!-- END Modal Heading Section -->

<!-- Modal Body Section -->
        <div v-if="getFeelings.length > 0">
          <div v-for="feeling in getFeelings" v-bind:key="feeling.id" class="modal-list">
            <HeartIcon class="modal-icon" />
            <p class="text-body-cf-tp">I feel <span class="font-semibold text-secondary-600">{{ feeling.feeling }}</span></p>      
          </div>
        </div>
          <div v-else>    
            <p class="cf-tp-no-entries-title"> 
              No current entries
            </p>  
            <p class="cf-tp-no-entries-body"> 
              You and your therapist will add your 'Feelings' during treatment.
            </p>   
        </div>
<!-- END Modal Body Section -->


          </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
    
</template>

<script setup>
  import { ref } from "vue";
  import { XMarkIcon, ChevronUpIcon, SunIcon, InformationCircleIcon, ClockIcon } from '@heroicons/vue/20/solid'
  import { HeartIcon } from '@heroicons/vue/24/outline'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
  
  const title = 'Feelings'
  
  const isOpen = ref(false)

  function closeModal() {
    isOpen.value = false
  }
  function openModal() {
    isOpen.value = true
  }

  </script>

<script>
export default {
  props: {
    feelings: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      imageSource: "/img/webapp/case_formulation/feelings.png",
      imageAltText: 'Heart inside a circle',
    };
  },

  computed: {
    getFeelings() {
      if (!this.onClientPage) {
        return this.$store.getters.getFeelings;
      }
      return this.feelings;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchFeelings");
    }
  },
};
</script>
