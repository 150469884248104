<template>

<!-- CTAs --> 
<div class="px-2 flex flex-col items-center mt-4 md:mt-8" data-aos="flip-up" data-aos-once="true" data-aos-duration="1000">
        <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-8"> <!-- Use flex-col for mobile and flex-row for desktop -->
      <!-- Button1 -->
      <router-link to="/register/overview-consult">
        <div class="mt-2 sm:mt-0 flex items-center justify-center w-full rounded-full bg-primary px-6 py-3 text-lg font-normal tracking-wide text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">
         <span>Book a Free Consultation</span>
         <chevron-right-icon class="text-primary-100 h-7 w-auto" />
         </div>
      </router-link>
      
    <!-- Button2 -->
    <router-link to="/register/overview-mot">

      <div class="mt-2 sm:mt-0 flex items-center justify-center w-full rounded-full bg-secondary-600 px-6 py-3 text-lg font-normal tracking-wide text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">
         <span>Take a Free Mental Health MOT</span>
         <chevron-right-icon class="text-primary-100 h-7 w-auto" />
         </div>
      </router-link>
    </div>
  </div>

  </template>
  
  <script setup>

  </script>