<template>
    <!--FullViewport-->  
    <div v-if="!imagesLoaded" class="text-center text-sm text-semibold">Loading images...</div>
    <div ref="contentContainer" class="custom-activity">
        <div ref="tempDiv" v-if="!isSwiperVisible" class="h-full w-full px-8 invisible"></div>
        <div ref="hiddenImageContainer" v-if="!imagesLoaded" class="invisible"></div>
        <swiper v-show="imagesLoaded && isSwiperVisible"
                @swiper="initializeSwiper"
                @slideChange="handleSlideChange"
                @nextSlide="nextSlide"
                :pagination="{
                    type: 'progressbar',
                }"
                :navigation="false"
                :modules="modules"
                class="mySwiper h-full"
                :style="[baseStyles, overridingStyles]"
                >
            <swiper-slide v-for="slide in slides">
                <div class="bg-white pt-6 md:pt-10 pb-4" v-html="slide"></div> 
            </swiper-slide>
        </swiper>
    </div>
</template>
    
    
<script setup>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

const modules = [Pagination, Navigation]

</script>

<script>
export default {
    props: {
        htmlContent: String,
        overridingStyles: null
    },
    emits: ['slide-count', 'slide-active'],
    data: function () {
        return {
            slides: [],
            isSwiperVisible: true,
            imagesLoaded: false,
            isTrimmed: false,
            swiperInstance: null,
            baseStyles: {
                '--swiper-navigation-size': '20px',
                '--swiper-pagination-progressbar-size': '6px',
                '--swiper-pagination-fraction-color': 'inherit',
                '--swiper-pagination-progressbar-bg-color': 'rgba(0, 0, 0, 0.25)',
            }
        }
    },
    inject: ['slideActions'],
    mounted() {
        window.addEventListener('resize', this.adjustContentContainerHeight);
        this.slideActions.nextSlide = this.nextSlide;
        this.slideActions.previousSlide = this.previousSlide;
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.adjustContentContainerHeight);
        this.slideActions.nextSlide = null;
        this.slideActions.previousSlide = null;
    },

    watch: {
        htmlContent() {
            this.adjustContentContainerHeight();
        },

        isSwiperVisible() {
            if (this.imagesLoaded && this.isSwiperVisible && !this.isTrimmed) {
                this.isTrimmed = true;
                this.adjustContentContainerHeight();
            }
        }
    },

    methods: {
        adjustContentContainerHeight() {
            const contentContainer = this.$refs.contentContainer;
            const offsetTop = contentContainer.offsetTop;
            const offsetBottom = 96;
            let offset = offsetTop + offsetBottom;
            contentContainer.style.height = `calc(100vh - ${offset}px)`;
            this.isSwiperVisible = false;
            this.createSlides();
        },

        createSlides() {
            if (!this.imagesLoaded) {
                console.log('Waiting for images to load...');
                this.$nextTick(async () => {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(this.htmlContent, "text/html");
                    const images = Array.from(doc.querySelectorAll('img'));
                    console.log('Total', images.length);

                    if (images.length > 0) {
                        const loadPromises = images.map((image) => {
                            const clonedImage = image.cloneNode(true);
                            this.$refs.hiddenImageContainer.appendChild(clonedImage);
                            return new Promise((resolve) => {
                                if (clonedImage.complete) {
                                    resolve();
                                } else {
                                    clonedImage.addEventListener('load', resolve);
                                }
                            });
                        });

                        const timeoutPromise = new Promise((resolve, reject) => {
                            setTimeout(() => {
                                reject(new Error('The timeout for image loading has expired'));
                            }, 3000);
                        });

                        try {
                            await Promise.race([Promise.all(loadPromises), timeoutPromise]);
                            while (this.$refs.hiddenImageContainer.firstChild) {
                                this.$refs.hiddenImageContainer.removeChild(this.$refs.hiddenImageContainer.firstChild);
                            }
                            console.log('All images have been loaded');
                        } catch (error) {
                            console.error(error);
                        }

                        while (this.$refs.hiddenImageContainer.firstChild) {
                            this.$refs.hiddenImageContainer.removeChild(this.$refs.hiddenImageContainer.firstChild);
                        }
                    }

                    this.imagesLoaded = true;
                    this.createSlides();
                });
                return;
            }

            if (this.isTrimmed) {
                console.log('Starting to build slides');
            }

            this.$nextTick(() => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(this.htmlContent, "text/html");
                const elements = Array.from(doc.body.childNodes);

                const tempSlides = [];
                let slide = [];
                let slideHeight = 0;

                if (elements.length > 0) {
                    elements.forEach((element) => {
                        if (element.nodeType === Node.ELEMENT_NODE) {
                            if (element.tagName.toLowerCase() === 'hr') {
                                if (slide.length > 0) {
                                    tempSlides.push(slide.join(''));
                                    slide = [];
                                    slideHeight = 0;
                                }
                                return;
                            }

                            if (slideHeight > 0 && !this.isTrimmed) {
                                return;
                            }

                            const clonedElement = element.cloneNode(true);
                            this.$refs.tempDiv.appendChild(clonedElement);

                            const elementHeight = clonedElement.offsetHeight;
                            if (slideHeight + elementHeight > this.$refs.tempDiv.clientHeight) {
                                if (slide.length > 0) {
                                    tempSlides.push(slide.join(''));
                                    slide = [];
                                    slideHeight = 0;
                                }

                                if (elementHeight > this.$refs.tempDiv.clientHeight) {
                                    tempSlides.push(element.outerHTML);
                                } else {
                                    slide.push(element.outerHTML);
                                    slideHeight += elementHeight;
                                }
                            } else {
                                slide.push(element.outerHTML);
                                slideHeight += elementHeight;
                            }

                            this.$refs.tempDiv.removeChild(clonedElement);
                        }
                    });

                    if (slide.length > 0) {
                        tempSlides.push(slide.join(''));
                    }
                }

                this.slides = tempSlides;
                this.$emit('slide-count', this.slides.length);
                this.isSwiperVisible = this.imagesLoaded;
            });
        },

        handleSlideChange(event) {
            const { activeIndex } = event;
            this.$emit('slide-active', activeIndex + 1);
        },

        initializeSwiper(swiper) {
            this.swiperInstance = swiper;
        },

        nextSlide() {
            if(this.swiperInstance) {
                this.swiperInstance.slideNext();
            }
        },

        previousSlide() {
            if(this.swiperInstance) {
                this.swiperInstance.slidePrev();
            }
        },
    }
}
</script>
