<template>

<!-- Banner -->
  <div class="rounded-md bg-red-50 p-4">
    <div class="flex">  
        <ExclamationTriangleIcon class="h-7 w-7 text-red-600" aria-hidden="true" />
        <div class="ml-3 font-sans text-sm font-medium text-red-700">
          <p>Call 999 or go to A&E now if you feel your life or someone's life is at risk</p>
        </div>
      </div>
    </div>
 <!-- END Banner -->

<!-- Block -->
<div class="bg-white">
    <div class="max-w-2xl mx-auto py-12 px-8 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8"> 
        <div class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-16 lg:items-center">
          <div class="lg:col-start-1' : 'lg:col-start-8 xl:col-start-9', 'mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-5">
            <h3 class="mt-4 pt-4 ws-text-heading-2-black">Getting Help During a Crisis</h3>
            <p class="mt-2 ws-text-body-black">If you're in crisis and need to talk right now, there are many helplines staffed by trained people ready to listen. They won't judge you, and could help you make sense of what you're feeling. </p>
          </div>
          <div class="lg:col-start-6 xl:col-start-5' : 'lg:col-start-1', 'flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-7">
            <div class="aspect-w-5 aspect-h-2 rounded-md bg-gray-100 overflow-hidden">
              <img src='img/website/help/helplines.jpg' alt='A mixtue of compamy logos of mental health charities' class="object-center object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- END Block -->

  <!-- Helplines --> 
  <div class="bg-secondary-700">
    <div class="max-w-7xl mx-auto py-8 px-8 sm:py-8 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
  <dl class="mt-6 space-y-8 divide-y divide-gray-200">
    <p class="bg-black/20 p-2 rounded-md font-bold text-xl text-white my-4">Helplines</p>
            <Disclosure as="div" v-for="helpline in helplines" :key="helpline.title" class="px-2 pt-6" v-slot="{ open }">
              <dt class="text-lg">
                <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                  <span class="font-medium text-white">
                    {{ helpline.title }}
                  </span>
                  <span class="ml-6 h-7 flex items-center text-white">
                    <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                  </span>
                </DisclosureButton>
              </dt>
              <DisclosurePanel as="dd" class="mt-2 pr-12">
                <p class="text-base text-white">
                  {{ helpline.description }}
                </p>
                <a :href="helpline.href">
                  <p class="text-base font-semibold italic hover:text-teal-700 text-teal-500">Go to {{ helpline.title }}</p>
              </a>
              </DisclosurePanel>
            </Disclosure>
          </dl>
          </div>
        </div>
      </div>
<!--  END Helplines --> 
      
<!-- CTA -->
<section class="relative bg-white ">
    <div class="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8"> 
        <h1 class=" ws-text-heading-1-black">
          <span class="block text-primary">Take the First Step</span>
          <span class="block  ">Start Therapy Today</span>
        </h1>
      <div>
        <a href="/assessment" class="mt-8 inline-flex items-center justify-center ws-button-primary-blue">Start your free assessment</a>
      </div>
    </div>                
  </section>
<!-- END CTA -->

  </template>
  
<script setup>
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/24/outline'
  import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
  
  const helplines = [
    {
      title: "NHS 111",
      description:
        "If you need urgent help for your mental health, but it's not an emergency:",
      href: "https://111.nhs.uk/"
      },
    {
      title: "Samaritans",
      description:
        "This is a listening service for anyone in distress.",
        href: "https://www.samaritans.org"
      },
    {
      title: "Papyrus",
      description:
        "A charity dedicated to the prevention of suicide in young people under 35. Papyrus also supports friends and family worried about someone under the age of 35.",
        href: "https://www.papyrus-uk.org/"
      },
      {
      title: "C.A.L.M (Campaign Against Living Miserably)",
      description:
        "Aimed specifically at men. Their helpline is open between 5pm and midnight every day of the year.",
        href: "https://www.thecalmzone.net"
      },
      {
      title: "Shout",
      description:
        "If you’re experiencing a personal crisis, are unable to cope and need support, text Shout to 85258. Shout can help with urgent issues such as suicidal thoughts, abuse or assault, self-harm, bullying and relationship challenges.",
        href: "https://www.giveusashout.org/"
      },
    {
      title: "Mind",
      description:
        "Mind offers information and advice to people with mental health problems",
        href: "https://www.mind.org.uk/"
      },
      
    
  
    ]  
  
 
  </script>
  