<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >


          <DialogPanel class="mx-auto m-4 w-full max-w-md overflow-hidden rounded-xl bg-white p-8 shadow-xl transition-all">
              <DialogTitle>
                <img class="mx-auto h-24 w-auto" src="/img/webapp/activities/activity-complete.png" alt="activity complete" />
                <h3 class="text-display-base text-center">Questionnaire Complete</h3>
              </DialogTitle>
              <div class="pt-8 pb-8 mx-auto">
                <p class="text-center text-body-base">
                  Congrats on finishing the questionnaire. Your scores will help you to see where your current mental health is at.
                </p>
              </div>
              <div class="px-12 mx-auto">
                <a type="button" class="button-primary" @click="goToPage">Got it, thanks</a>
              </div>
            </DialogPanel>

          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
</script>

<script>
export default {
  props: {
    title: String,
    slug: String
  },
  data: function () {
    return {
      isOpen: false,
      isRegistration: false,
      isAssessment: false
    }
  },
  inject: ['questionnaireGroupFinishActions'],
  mounted() {
    this.questionnaireGroupFinishActions.openModal = this.openModal;
    this.questionnaireGroupFinishActions.closeModal = this.closeModal;
  },

  beforeUnmount() {
    this.questionnaireGroupFinishActions.openModal = null;
    this.questionnaireGroupFinishActions.closeModal = null;
  },

  methods: {
    closeModal() {
      this.isOpen = false;
    },
    openModal(userId = null, isAssessment) {
      if (userId && !isAssessment) {
        this.isRegistration = true;
      }
      if (userId && isAssessment) {
        this.isAssessment = true;
      }
      this.isOpen = true;
    },
    goToPage() {
      if (this.isRegistration) {
        this.$router.push({ name: 'RegisterStart'});
        return;
      }
      if (this.isAssessment) {
        this.$router.push({ name: 'AssessmentStart'});
        return;
      }
      this.$router.push({ name: 'Collections', params: { slug: this.slug }});
    }
  },
}

</script>
