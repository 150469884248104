<template>
  <section
    :class="[
      sectionBgColor,
      paddingTopClass,
      paddingBottomClass,
      'px-6'
    ]"
  >
    <swiper
      :spaceBetween="30"
      :loop="true"
      :effect="'flip'"
      :grabCursor="true"
      :cubeEffect="{
        shadow: true,
        slideShadows: true,
        shadowOffset: 40,
        shadowScale: 0.54,
      }"
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      class="mySwiper"
      style="--swiper-pagination-color: white;
             --swiper-pagination-bullet-opacity: 0;
             --swiper-pagination-bullet-inactive-color: white;
             --swiper-pagination-bullet-inactive-opacity: 0;"
    >
      <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
        <div class="mx-auto max-w-2xl lg:max-w-2xl">
          <div>
            <ChatBubbleLeftEllipsisIcon class="h-20 mx-auto text-primary-400" />
          </div>
          <figure class="mt-4">
            <blockquote class="ws-text-body-lg text-white/90 text-center">
              <p>{{ testimonial.text }}</p>
            </blockquote>
            <figcaption class="mt-6 flex items-center justify-center">
              <div class="flex items-center">
                <UserCircleIcon class="h-10 text-white" />
              </div>
              <div class="flex items-center">
                <p class="ml-1 ws-text-body-sm text-white">{{ testimonial.name }}, {{ testimonial.age }}</p>
              </div>
            </figcaption>
          </figure>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import icons
import { UserCircleIcon } from '@heroicons/vue/20/solid';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/24/outline';

// Import required Swiper modules
import { Autoplay, Navigation, Pagination, EffectFlip } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
    UserCircleIcon,
    ChatBubbleLeftEllipsisIcon,
  },
  props: {
    testimonials: {
      type: Array,
      required: true,
      default: () => []
    },
    sectionBgColor: {
      type: String,
      default: 'bg-gray-100' // Default background color class
    },
    paddingTopClass: {
      type: String,
      default: 'pt-16 sm:pt-20 md:pt-24'
    },
    paddingBottomClass: {
      type: String,
      default: 'pb-16 sm:pb-20 md:pb-24'
    }
  },
  setup() {
    return {
      modules: [Autoplay, EffectFlip, Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
/* Additional styling if needed */
</style>
