<template>

<section class="bg-secondary-600">
    <div class="mx-auto max-w-4xl pt-16 px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h2 class="text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">Pricing</h2>
        <p class="text-center mt-6 text-lg leading-8 text-white">Invest in your mental well-being with a comprehensive 90-minute session and a collection of readings and questionnaires designed to provide deep insights and actionable steps.</p>
      </div>
    </div>
  
  <div class="bg-secondary-600 py-16 px-6">
        <div class="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:flex lg:max-w-6xl ">
          <div class="p-8 sm:p-10 lg:flex-auto">
            
            <p class="mt-6 text-base leading-7 text-white">Take a deep dive into your mental health by taking my mental wellbeing MOT.</p>
            <div class="mt-10 flex items-center gap-x-4">
              <h4 class="flex-none text-sm font-semibold leading-6 text-white">Each MOT includes:</h4>
              <div class="h-px flex-auto bg-gray-100" />
            </div>
            <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-white sm:grid-cols-2 sm:gap-6">
              <li v-for="feature in includedFeatures" :key="feature" class="flex gap-x-3">
                <CheckIcon class="h-6 w-5 flex-none text-white" aria-hidden="true" />
                {{ feature }}
              </li>
            </ul>
          </div>
          <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div class="mx-auto max-w-xs px-8">
                <p class="text-base font-semibold text-gray-600">Discover, Understand, Thrive</p>
                <p class="mt-6 flex items-baseline justify-center gap-x-2">
                  <span class="text-5xl font-bold tracking-tight text-gray-900">£99</span>
                  <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600"></span>
                </p>
                <a href="/register/overview" class="mt-10 block w-full ws-cta-primary">Get started</a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   
  
  
  </template>
  
  
  <script setup>
        
  import Wave from '../../website/components/Wave' ;
  import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
  import { CheckIcon, CalendarIcon, ClipboardIcon, KeyIcon } from '@heroicons/vue/24/outline' 
  
  const includedFeatures = [
    '90-minute deep dive session ',
    'Collection of readings and questionnaires prior to the session',
    'Personalised action plan',
  ]
  
  </script>
  