<template>
  <HeaderComponent :pageTitle="'My Dominant Parts'" />
  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div>
        <div v-if="emotionalstate" class="bg-white shadow-xl shadow-gray-300/10 rounded-md overflow-hidden">
          <h1 class="flex bg-gradient-to-r from-primary-200 to-secondary-200 px-4 py-4 text-base font-semibold text-secondary-600">
            {{ emotionalstate.emotionalstate }}
          </h1>

             <!-- Lifetrap Examples
          <p class="px-3 py-4 text-gray-600">
            <span class="font-medium italic text-secondary-600">Description:</span> {{ emotionalstate.category }}
          </p>

          <h2 class="px-3 text-lg font-semibold text-secondary-600">Characteristics</h2> 
          -->
          <div v-if="emotionalstateBehaviours.length > 0" class="py-6">
            <ul>
    <li v-for="behaviour in emotionalstateBehaviours" :key="behaviour.id" class="font-normal text-gray-600 px-4 py-4 border-b">
      <FaceFrownIcon class="h-5 w-5 inline-block mr-2 text-secondary-600" />
      {{ behaviour.behaviour }}
    </li>
  </ul>
        </div>
         <!-- No Examples Message -->
         <div v-else>
            <p class="text-center font-normal text-gray-500 text-base py-12 px-16">We will enter information about this part throughout our journey</p>
          </div>

        </div>
        <div v-else-if="loading">
          <p>Loading... Please wait</p>
        </div>
        <div v-else>
          <p>Error: Emotional state not found.</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent';
import { TagIcon, FaceFrownIcon } from '@heroicons/vue/24/outline';
</script>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    emotionalstate() {
      return this.$store.getters.getEmotionalstateById(this.id);
    },

    emotionalstateBehaviours() {
      return this.$store.getters.getEmotionalstateBehaviours(this.id);
    }
  },
  
  watch: {
    // Watch the id prop for changes and refetch the data when it changes
    id: {
      immediate: true,
      handler(newId) {
        this.fetchEmotionalState(newId);
        this.fetchEmotionalstateBehaviours(newId);
      }
    }
  },
  methods: {
    async fetchEmotionalState(id) {
      this.loading = true;
      console.log('Fetching emotional state with ID:', id); // Log the new ID
      try {
        if (!this.emotionalstate || this.emotionalstate.id !== id) {
          await this.$store.dispatch('fetchEmotionalstate', id);
        }
      } catch (error) {
        console.error('Error fetching emotional state', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchEmotionalstateBehaviours(id) {
  console.log(`Fetching emotional state behaviours for ID: ${id}`); // Debug log
  this.loading = true;
  try {
    await this.$store.dispatch('fetchEmotionalstateBehaviours', id);
    console.log('Behaviours fetched successfully'); // Debug log
  } catch (error) {
    console.error('Error fetching emotional state behaviours', error);
  } finally {
    this.loading = false;
  }
}
  },
  mounted() {
    // Initial fetch based on the current id
    this.fetchEmotionalState(this.id);
    this.fetchEmotionalstateBehaviours(this.id);
  }
};
</script>
