import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'chartkick/chart.js'
import 'chartjs-adapter-date-fns'
import VueChartkick from 'vue-chartkick'

// Check if the browser supports service workers
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.withCredentials = true;

window.axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 419) {
    location.reload();
  }
  return Promise.reject(error);
});

store.dispatch('attempt_user')
  .catch((error) => {
    if(error.response?.status !== 401) {
        console.error(error)
    }
  })
  .finally(() => {
    const app = createApp(App)
        app.use(store)
        app.use(router);
        app.use(VueChartkick)
        app.use(AOS.init());

        

    app.mount('#app');
  })
