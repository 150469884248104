<template>
 
<button type="button" @click="openModal" class="">
  <information-circle-icon class="info-icon" />
</button>

  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0" >
      <div class="fixed inset-0 bg-black bg-opacity-90" />
      </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex justify-center">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95" >
          
          <DialogPanel class="relative introcard-container align-middle" >  
            <DialogTitle class="introcard-header-container">
<!-- X button -->         
              <button type="button" @click="closeModal" class="introcard-dismiss-button">
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>         
<!-- END X button -->

              <div class="flex">
                <div class="flex inline-block overflow-hidden ring-2 ring-white shadow-md rounded-full">
                <img class="h-12" src="/img/avatar.jpg" alt="Therapist face image" />
                </div>
                  <div class="my-auto ml-4">
                  <h3 class="text-base font-sans text-left font-semibold tracking-normal text-gray-700">Tom from Youlio</h3>
                  <h3 class="text-sm font-sans text-left font-normal text-gray-600">Psychotherapist</h3>
                </div>
              </div>         
            </DialogTitle>
<!-- END Top Panel --> 

<!-- Modal Body -->      
              <div class="px-2 md:px-4 pt-8 pb-12">
                <slot name="content"></slot> 
              </div>      
<!-- END Modal Body --> 

          </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>

  import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
  import { Popover, PopoverButton, PopoverOverlay, PopoverPanel } from '@headlessui/vue'
  import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
  import { ref, onMounted, computed } from "vue";
  import { useStore } from "vuex";
  const store = useStore();

  const props = defineProps({ 
        title: String,     
      }); 

      const isOpen = ref(false)

function closeModal() {
  isOpen.value = false
}
function openModal() {
  isOpen.value = true
}

</script>