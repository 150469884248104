<template>
  <section class="pt-4 pb-20 sm:py-16 px-6">
    <div class="container mx-auto" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
        <!-- First Card -->
        <div :class="['flex flex-col items-center p-6 rounded-lg shadow-md text-center', card1.bgColorClass]">
          <div v-if="card1.icon" class="text-primary mb-2">
            <component :is="card1.icon" class="h-12 w-12" />
          </div>
          <h3 class="ws-text-heading-sm mb-4 text-primary-700 text-opacity-75">{{ card1.title }}</h3>
          <p class="ws-text-body-base text-gray-900 text-opacity-75 mb-4">{{ card1.description }}</p>
          <router-link
            v-if="card1.ctaText && card1.link"
            :to="card1.link"
            class="text-primary tracking-wide font-semibold hover:underline"
          >
            {{ card1.ctaText }}
          </router-link>
        </div>

        <!-- Second Card -->
        <div :class="['flex flex-col items-center p-6 rounded-lg shadow-md text-center', card2.bgColorClass]">
          <div v-if="card2.icon" class="text-gray-800 mb-4">
            <component :is="card2.icon" class="h-12 w-12" />
          </div>
          <h3 class="ws-text-heading-sm mb-4 text-secondary-800 text-opacity-75">{{ card2.title }}</h3>
          <p class="ws-text-body-base text-gray-900 text-opacity-75 mb-4">{{ card2.description }}</p>
          <router-link
            v-if="card2.ctaText && card2.link"
            :to="card2.link"
            class="text-gray-800 tracking-wide font-semibold hover:underline"
          >
            {{ card2.ctaText }}
          </router-link>
        </div>

        <!-- Third Card -->
        <div :class="['flex flex-col items-center p-6 rounded-lg shadow-md text-center', card3.bgColorClass]">
          <div v-if="card3.icon" class="text-secondary-500 mb-4">
            <component :is="card3.icon" class="h-12 w-12" />
          </div>
          <h3 class="ws-text-heading-sm mb-4 text-secondary-700 text-opacity-75">{{ card3.title }}</h3>
          <p class="ws-text-body-base text-gray-900 text-opacity-75 mb-4">{{ card3.description }}</p>
          <router-link
            v-if="card3.ctaText && card3.link"
            :to="card3.link"
            class="text-secondary-600 tracking-wide font-semibold hover:underline"
          >
            {{ card3.ctaText }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  card1: {
    type: Object,
    required: true,
  },
  card2: {
    type: Object,
    required: true,
  },
  card3: {
    type: Object,
    required: true,
  },
});

// Compute background color class for each card
const card1BgColorClass = computed(() => `bg-${props.card1.bgColor}`);
const card2BgColorClass = computed(() => `bg-${props.card2.bgColor}`);
const card3BgColorClass = computed(() => `bg-${props.card3.bgColor}`);

props.card1.bgColorClass = card1BgColorClass.value;
props.card2.bgColorClass = card2BgColorClass.value;
props.card3.bgColorClass = card3BgColorClass.value;
</script>
