<template>
  <div class="ongoing-weekly-therapy-page">


<!-- SECTION-Hero -->
    <section class="relative bg-gradient-to-r from-secondary-600 to-secondary-500" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div class="px-6 pb-4 pt-16 sm:pb-0 lg:col-span-7 lg:px-0 lg:pb-24 lg:pt-20 xl:col-span-6">
                <div class="mx-auto max-w-2xl lg:mx-0">
                    <h1 class="text-center sm:text-left mt-4 sm:mt-8 text-4xl font-bold text-white sm:text-5xl">Weekly Therapy</h1>
                    <p class="text-center sm:text-left mt-4 text-lg text-white sm:text-xl">Weekly Therapy provides consistent support through regular sessions with me. It’s perfect for those who prefer a flexible, less structured approach, offering supportive, open-ended care without rigid goals.</p>
                    <div class="mt-8 sm:mt-10 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <!-- Button1 -->
          <a href="/register/overview" class="w-56 mx-auto sm:mx-0 text-center rounded-full bg-primary px-4 py-3 text-lg font-medium tracking-wide text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
            Get Started  
          </a>
        </div>
          </div>
            </div>
            <div class="pb-8 sm:pt-12 relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                <div class="py-8 px-8  relative w-full h-0" style="padding-bottom:50%;">
                    <img class="sm:px-16 absolute inset-0 w-full h-full object-contain object-center" src="/img/website/hero-weekly.png" alt="" />
                </div>
            </div>
        </div>
    </section>
  
<!-- SECTION-How Youlio Supports Your Journey -->
      <BlockSwiper
        :slides="4"
        overline="Personalised Care"
        headline="Why Choose Weekly Therapy"
        subheadline="Weekly therapy provides a stable and personalised pathway to mental wellness, allowing you to work through issues at your own pace while receiving continuous, tailored support."
        
        >
        <template #slide-0>
          <BlockContentInSwiper
        headline="Flexible Structure"
        subheadline="Benefit from a flexible approach that lets you focus on your needs without rigid goals or deadlines."
        imageSrc="/img/website/weekly-therapy-why-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Consistent Connection"
        subheadline="Weekly therapy helps maintain a strong therapeutic relationship. Regular interactions create a deeper connection and builds a strong foundation for your progress."
        imageSrc="/img/website/weekly-therapy-why-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Customised Experience"
        subheadline="With weekly therapy on the Youlio platform, your progress is supported by structured, between-session work. I can easily send you tailored activities to enhance our sessions and keep your momentum going."
        imageSrc="/img/website/weekly-therapy-why-3.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiper  
        headline="Open-Ended Engagement"
        subheadline="Experience continuous support with the option to end therapy whenever you feel ready, providing a comfortable and adaptable care environment."
        imageSrc="/img/website/weekly-therapy-why-4.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
      </BlockSwiper>

<!-- SECTION-Testimonials-->
      <BlockTestimonial
  :testimonials="[
    {
      text: 'My Therapy sessions with Tom have been life-changing. His empathic approach and practical advice have helped me navigate through my anxiety and build a healthier mindset. Highly recommend!',
      name: 'Daniel',
      age: 23
    },
    {
      text: 'Starting therapy with Tom has been a crucial part of my mental health journey. His unwavering support has empowered me to make positive changes in my life. Thank you, Tom!',
      name: 'Amanda',
      age: 31
    },
    {
      text: 'I love the Youlio platform, Tom and his genuine care have guided me through some tough times, helping me develop healthier coping mechanisms and a more positive outlook on life.',
      name: 'Michael',
      age: 42
    }
  ]"
  sectionBgColor="bg-primary-600"
/>

<!-- SECTION-Pricing -->
<BlockPricingTherapy />

<!-- SECTION-Getting Started -->
      <BlockGettingStarted
        title="Getting Started"
        bgClass="bg-white"
        :steps="[
          { icon: UserIcon, title: 'Step 1: Create a Free Youlio Account', description: 'Get started by creating your free Youlio account. This will allow you to book a free consultation and meet me.' },
          { icon: CalendarIcon, title: 'Step 2: Book a Consultation', description: 'Schedule a consultation to discuss your needs and expectations. We’ll explore how I can best support your mental health journey.' },
          { icon: RocketLaunchIcon, title: 'Step 3: Begin Your Therapy Journey', description: 'Start your personalised therapy sessions and begin working towards your mental health goals with consistent, supportive guidance from me and the Youlio platform.' }
        ]"
      />


<!-- SECTION-CTA -->
<Wave colorClass="fill-white" shadow position="down" /> 
    <BlockCTA    
      heading="Ready to Start Your Therapy Journey"
      message="Take the first step towards continuous support and personal growth with weekly therapy."
      primaryButtonText="Get Started"
      primaryButtonLink="/register/overview"
      secondaryButtonText="Contact Me"
      secondaryButtonLink="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Weekly%20Therapy"
      imageSrc="/img/website/weekly-therapy-cta.jpg"
      bgClass="bg-white"
      theme="light"
    />

  </div>
</template>

<script>
export default {
  name: "OngoingWeeklyTherapyPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockWhyChoose from '../../website/components/BlockWhyChoose' ;
import BlockPricingTherapy from '../../website/components/BlockPricingTherapy' ;
import Wave from '../../website/components/Wave' ;
import BlockContent from '../../website/components/BlockContent' 
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper';
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockGettingStarted from '../../website/components/BlockGettingStarted' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { RocketLaunchIcon, CheckIcon, UserIcon, CalendarIcon, ClipboardIcon, KeyIcon } from '@heroicons/vue/24/outline' 
import { ChevronRightIcon } from '@heroicons/vue/20/solid'


</script>
