<template>

<div v-if="!consentGiven" class="fixed inset-0 bg-black opacity-50 z-40"></div>

  <div v-if="!consentGiven" class="cookie-consent z-50 fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
    <p class="max-w-4xl text-sm leading-6 text-gray-900">This website uses cookies to provide the best possible experience for our visitors. Accepting our cookies is entirely optional but we recommend it as they are delicious.
      See our <a href="/privacy" class="font-semibold text-primary">cookie policy</a>.</p>
    <div class="flex flex-none items-center gap-x-5">
      <button @click="acceptCookies" type="button" class="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Accept all</button>
      <button @click="rejectAll" type="button" class="text-sm font-semibold leading-6 text-gray-900">Reject all</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consentGiven: false, // Track if the user has given consent
    };
  },
  methods: {
    acceptCookies() {
      // Set a flag in local storage to indicate that the user has given consent
      localStorage.setItem('cookieConsent', 'true');
      
      // Update the component's data to reflect the user's consent
      this.consentGiven = true;
      
      // You can also trigger any other necessary actions here, such as loading third-party scripts or setting cookies.
    },
    rejectAll() {
      // Handle the logic for rejecting all cookies here
      // You can perform actions like clearing cookies or other necessary steps
      // For this example, we will hide the consent banner without further action
      this.consentGiven = true;
    },
  },
  created() {
    // Check if the user has previously given consent
    const previousConsent = localStorage.getItem('cookieConsent');
    
    if (previousConsent === 'true') {
      // If the user has previously given consent, update the component's data
      this.consentGiven = true;
      
      // You can also perform any other necessary actions here based on the user's previous consent.
    }
  },
};
</script>