<template>

<!--Button-->
<div class="card-cf-tp">
  <button type="button" @click="openModal" class="flex w-full items-center justify-between py-1">
    <img class="h-16" :src="imageSource" :alt="imageAltText" />
    <div class="flex-1 space-y-1">
      <div class="flex items-center justify-between">  
        <div class="ml-4">
          <h3 class="text-display-base">{{ title }}</h3>
          <h3 class="pt-1 text-xs font-bold text-primary tracking-wide text-left">LEARN MORE</h3>
        </div>
        <ChevronRightIcon class="h-9 w-auto text-gray-800 rounded-full" />
      </div>
    </div>
  </Button>
  </div>
<!--END Button-->
  
  
  <!--Modal Container-->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-40">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0" >
          <div class="fixed inset-0 bg-black bg-opacity-90" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div class="modal-placement">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95" >
              <DialogPanel class="modal-open-container" >
  <!--END Modal Container-->
  
  
  <!-- Modal Heading Section -->
          <DialogTitle class="modal-open-heading-container">
            <div class="flex items-center"> 
              <img class="h-16" :src="imageSource" :alt="imageAltText" />       
              <h3 class="pl-2 text-display-base-thin">{{ title }}</h3>
            </div>   
              <button type="button" @click="closeModal" class="modal-dismiss-button">
                <XMarkIcon class="modal-dismiss-icon" aria-hidden="true" />
              </button>         
          </DialogTitle>
  <!-- END Modal Heading Section -->


<!-- Modal Body Section -->
        <div v-if="getGoalActions.length > 0">
          <div v-for="(goal, index) in getGoalActions" :key="goal.id">
            <div class="flex bg-secondary-50 py-6 px-6">    
              <p class="modal-goal-number">G{{ index + 1 }} </p>
              <p class="text-display-base">{{ goal.goal.goal }}</p>   
            </div>        
              <div v-for="(action, index) in goal.actions" :key="action.id" class="px-4 flex py-4 mx-2 border-t border-gray-100">            
                <p class="modal-action-number">{{ index + 1 }} </p>
                <p class="text-body-base">{{ action.goal_action }}</p>        
              </div>
            </div>
          </div>
          <div v-else>    
            <p class="cf-tp-no-entries-title"> 
              No current entries
            </p>  
            <p class="cf-tp-no-entries-body"> 
              You and your therapist will add your 'Actions' during treatment.
            </p>    
        </div>
<!-- END Modal Body Section -->

              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

</template>

<script setup>
  import { ref } from "vue";
  import { ChevronRightIcon, PaperAirplaneIcon, XMarkIcon } from '@heroicons/vue/20/solid';
  import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

  const title = 'My Actions';

  const isOpen = ref(false);

  function closeModal() {
    isOpen.value = false;
  }
  function openModal() {
    isOpen.value = true;
  }
</script>

<script>
export default {
  props: {
    goals: {
      type: Array,
      default: []
    },
    actions: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      imageSource: "/img/webapp/treatment_plan/actions.png",
      imageAltText: 'Two arrows pointing to the right',
    };
  },

  computed: {
    getGoalActions() {
      let goals = [];
      let goalActions = [];

      if (!this.onClientPage) {
        goals = this.$store.getters.getGoals;
        goalActions = this.$store.getters.getGoalActions;
      } else {
        goals = this.goals;
        goalActions = this.actions;
      }

      // Group goal actions by goal ID
      const groupedActions = goalActions.reduce((acc, action) => {
        if (acc[action.goal_id]) {
          acc[action.goal_id].actions.push(action);
        } else {
          acc[action.goal_id] = {
            id: action.goal_id,
            goal: goals.find((goal) => goal.id === action.goal_id),
            actions: [action],
          };
        }
        return acc;
      }, {});

      // Convert grouped actions into an array
      return Object.values(groupedActions);
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchGoalActions");
    }
  },
};
</script>
