<template>

<HeaderComponent :pageTitle="'My Symptoms'" />
<div class="overflow-x-hidden">
<main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
              
  <p class=" py-4 text-base md:text-base font-medium text-secondary-600 text-left"> 
            I am showing the following symptoms:
        </p>  
<!-- Symptom Card -->
      <div v-if="getSymptoms.length > 0" class="rounded-lg border shadow-2xl shadow-secondary-400/15 py-2 bg-gradient-to-r from-primary-200 to-secondary-200">

        <div v-for="symptom in getSymptoms" v-bind:key="symptom.id" class="
        flex items-center 
        border-b border-gray-50 
        px-2 mx-2 py-5">
          <ClipboardDocumentListIcon class="modal-icon" />
          <p class="text-base font-semibold text-secondary-600">{{ symptom.symptom }}</p>      
        </div>
      </div>
<!-- No Entry Card -->
        <div v-else>    
          <p class="cf-tp-no-entries-title"> 
            No current entries
          </p>  
          <p class="cf-tp-no-entries-body"> 
            We will add your 'Symptoms' during your journey.
          </p>    
      </div>
 
    </main>
  </div>
  </template>
  
  <script setup>
    
    import { XMarkIcon, ChevronUpIcon, ChevronRightIcon, ChevronLeftIcon, SunIcon, ChatBubbleLeftIcon, CubeIcon } from '@heroicons/vue/20/solid'
    import { ClipboardDocumentListIcon } from '@heroicons/vue/24/outline'
    import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
    import InfoIcon from '../components/InfoIcon';
    import HeaderComponent from '../components/HeaderComponent';
  
  </script>
  
  <script>
  export default {
    props: {
      symptoms: {
        type: Array,
        default: []
      },
      onClientPage: {
        type: Boolean,
        default: false
      }
    },
  
    computed: {
      getSymptoms() {
        if (!this.onClientPage) {
          return this.$store.getters.getSymptoms;
        }
        return this.symptoms;
      }
    },
    mounted() {
      if (!this.onClientPage) {
        this.$store.dispatch("fetchSymptoms");
      }
    },
  };
  </script>
  