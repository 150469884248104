<template>
  <TransitionRoot appear :show="isOpen" as="template">
  <Dialog :open="isOpen" @close="close" class="fixed inset-0 z-50 overflow-hidden" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
    <Dialog.Overlay class="fixed inset-0 bg-gray-800 bg-opacity-75" />
    <div class="bg-white rounded-l-lg p-6 w-full max-w-md h-full z-10 transition-transform transform"
         :class="{'translate-x-0': isOpen, 'translate-x-full': !isOpen}">
      <DialogTitle class="text-xl text-gray-800 font-semibold mb-4">
        <header class="bg-page-background h-20 fixed top-0 left-0 right-0 z-30 md:top-20 md:left-64 md:px-0 md:mx-12 px-4 md:pt-4 flex items-center justify-between">
          <div class="flex items-center space-x-2">
            <button @click="close" class="flex items-center">
              <ChevronLeftIcon class="-ml-1 h-7 w-auto text-gray-600 stroke-2" />
            </button>
            <h1 class="text-lg md:text-xl font-normal font-sans text-gray-700">New note</h1>
          </div>
          <div class="flex justify-end">
            <button @click="handleCreate" class="bg-primary font-normal text-base text-white px-3 py-2 rounded">Save Note</button>
          </div>
        </header>
      </DialogTitle>
      <div class="ck-content">
  <ckeditor
    v-model="content"
    :editor="editor"
    :config="editorConfig"
    class="h-96 mt-16 custom-editor"
    ref="editorInstance"
  />
</div>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import { Dialog, DialogTitle } from '@headlessui/vue';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

const props = defineProps({
  isOpen: Boolean,
  onClose: Function,
  onCreate: Function,
});

const emits = defineEmits(['close']);

const editor = BalloonEditor;
const editorConfig = {
  toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
  placeholder: 'Start writing...', // Placeholder text
  placeholderConfig: {
    styles: {
      color: '#888', // Greyed-out color
      fontStyle: 'italic', // Italic styling for placeholder
    },
  },
};

const content = ref('');
const editorInstance = ref(null);

const close = () => {
  content.value = ''; // Reset the content when closing
  emits('close');
};

const handleCreate = () => {
  if (content.value.trim() === '') {
    alert('Please write something before saving.');
    return;
  }

  props.onCreate(content.value); // Pass content back to parent
  close();
};

onMounted(() => {
  // Use a brief delay to ensure CKEditor fully loads
  setTimeout(() => {
    if (editorInstance.value && editorInstance.value.editing) {
      editorInstance.value.editing.view.focus();
    }
  }, 100); // 100ms delay to allow the editor to load before focusing
});
</script>

<style scoped>
::v-deep .ck-editor__editable_inline p {
  
  padding: 0 !important;
 
  font-size: 16px !important; /* Adjust the font size as needed */
}

/* Placeholder styling */
::v-deep .ck.ck-editor__editable > .ck-placeholder::before {
  color: #6b7280; /* Customize color as needed */
  
}

@keyframes pulse-border {
  0% {
    border-color: #ccc;
  }
  50% {
    border-color: #999;
  }
  100% {
    border-color: #ccc;
  }
}
</style>