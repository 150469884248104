<template>

<HeaderComponent :pageTitle="'My Activities'" />

  <loading-overlay :loading="loading"></loading-overlay>
  <div class="overflow-x-hidden">
  <main v-if="!firstLoading" class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

<!--To Do--> 
<!--Title&Filter-->
      <div class="mb-2 flex justify-between">
        <div class="flex items-center text-page-subtitle-dark">To Do</div>
        <div v-if="totalTodoRecords || todoFilters.length !== activityTypes.length" class="flex relative">
          <adjustments-vertical-icon class="filter" @click="toggleMenu('todo')" />
          <div v-show="showToDoMenu" class="absolute right-3 top-4 mt-2 w-48 py-2 bg-white rounded-lg rounded-tr-none shadow-xl z-10">
            <div v-for="todoType in activityTypes" class="block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white">
              {{ capitalizeFirstLetter(todoType) }}
              <input type="checkbox" :value="todoType" v-model="todoFilters" class="mt-1 text-primary float-right focus:ring-transparent" />
            </div>
          </div>
        </div>
      </div>
      <activity-card v-if="totalTodoRecords" :activities="todo" class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8" />
      <div v-else class="card-no-entries">
        <div v-if="todoFilters.length == activityTypes.length">
         <p>
          There are currently no 'Activities' in your ToDo list.
         </p>
        </div>
        <div v-else>
          <p class="py-8 px-12 md:px-8 text-center text-body-base">
            Oops! It seems your search didn't match any 'Activities' in your ToDo list.</p>
        </div>
      </div>
  
<!--Archive-->
<!--Title&Filter-->
      <div class="mt-12 flex justify-between mb-2">
        <div class=" flex items-center  text-page-subtitle-dark">Archive</div>
        <div v-if="totalArchiveRecords || archiveFilters.length !== activityTypes.length" class="flex relative">
          <adjustments-vertical-icon class="filter" @click="toggleMenu('archive')" />
          <div v-show="showArchiveMenu" class="absolute right-3 top-4 mt-2 w-48 py-2 bg-white rounded-lg rounded-tr-none shadow-xl z-10">
            <div v-for="archiveType in activityTypes" class="block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white">
              {{ capitalizeFirstLetter(archiveType) }}
              <input type="checkbox" :value="archiveType" v-model="archiveFilters" class="mt-1 text-primary float-right focus:ring-transparent" />
            </div>
          </div>
        </div>
      </div>
      <activity-card v-if="totalArchiveRecords" :activities="archive" class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8"/>
      <div v-else class="card-no-entries">
        <div v-if="archiveFilters.length == activityTypes.length">
          <p>
          There are currently no 'Activities' in your Archive.
         </p>
        </div>
        <div v-else>
          <p class="py-8 px-12 md:px-8 text-center text-body-base">
            Oops! It seems your search didn't match any 'Activities' in your Archive.</p>
        </div>
      </div>
  </main>
</div>
</template>

<script setup>
    
  import { ref } from 'vue'
  import ActivityCard from '../components/ActivityCard';
  import InfoIcon from '../components/InfoIcon';
  import LoadingOverlay from '../components/LoadingOverlay';
  import { AdjustmentsVerticalIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'
  import { sendPost } from '../components/entities/functions.js';
  import HeaderComponent from '../components/HeaderComponent';

</script>

<script>
export default {
  data: function () {
    return {
      todo: [],
      archive: [],
      activityTypes: ['questionnaires', 'worksheets', 'readings'],
      todoFilters: ['questionnaires', 'worksheets', 'readings'],
      archiveFilters: ['questionnaires', 'worksheets', 'readings'],
      showToDoMenu: false,
      showArchiveMenu: false,
      loading: true,
      firstLoading: true
    }
  },
  mounted() {
    this.getContent();
  },

  computed: {
    totalTodoRecords() {
      let total = 0;
      if (this.todo) {
        if (this.todo.questionnaire_instances) {
          total += this.todo.questionnaire_instances.length;
        }
        if (this.todo.reading_instances) {
          total += this.todo.reading_instances.length;
        }
        if (this.todo.worksheet_instances) {
          total += this.todo.worksheet_instances.length;
        }
      }
      return total;
    },
    totalArchiveRecords() {
      let total = 0;
      if (this.archive) {
        if (this.archive.questionnaire_instances) {
          total += this.archive.questionnaire_instances.length;
        }
        if (this.archive.reading_instances) {
          total += this.archive.reading_instances.length;
        }
        if (this.archive.worksheet_instances) {
          total += this.archive.worksheet_instances.length;
        }
      }
      return total;
    }
  },

  watch: {
    todoFilters() {
      this.getContent();
    },
    archiveFilters() {
      this.getContent();
    },
  },

  methods: {
    async getContent() {
      this.loading = true;
      const response = await sendPost('/api/content/activities', {
        todo_types: this.todoFilters,
        archive_types: this.archiveFilters
      });
      this.loading = false;
      this.firstLoading = false;
      this.todo = response.todo;
      this.archive = response.archive;
    },

    toggleMenu(type) {
      if (type == 'todo') {
        this.showToDoMenu = !this.showToDoMenu;
      } else {
        this.showArchiveMenu = !this.showArchiveMenu;
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}
</script>
