import { createStore } from "vuex";
import auth from "../../js/store/modules/auth.module";
import caseFormulation from "../../js/store/modules/caseFormulation.module";
import treatmentPlan from "../../js/store/modules/treatmentPlan.module";
import spotlight from "../../js/store/modules/spotlight.module";
import activity from "../../js/store/modules/activity.module";
import chatStore from './modules/chatStore';

const store = createStore({
  modules: {
    auth,
    caseFormulation,
    treatmentPlan,
    spotlight,
    activity,
    chatStore
  }
});

export default store;