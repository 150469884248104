<template>

  <section class="-mt-24 bg-white" >
        <div class="relative isolate overflow-hidden" >
  
            <div class="mx-auto px-6 mt-40 mb-40 md:mt-48 md:mb-36">
             
              <p class="mt-2 ws-text-heading-1-black text-center mx-auto md:px-12 sm:max-w-4xl">
                Slack Test
              </p>  
  
              <div class="my-8">
                <button @click="sendSlackNotification" class="ws-button-primary-green">Send Slack Notification</button>
              </div>
              
              </div>
            </div>
      </section>
  
  </template>
  
  <script>
  export default {
    methods: {
      sendSlackNotification() {
        // Make an API request to your backend to trigger the Slack notification
        // For demonstration purposes, you can use Axios or any other HTTP library to send a POST request.
        axios.post('/api/send-slack-notification')
          .then(response => {
            console.log('Slack notification sent successfully!');
          })
          .catch(error => {
            console.error('Error sending Slack notification:', error);
          });
      },
    },
  };
  </script>


