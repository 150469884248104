import { is } from "date-fns/locale";

export const state = {
  user: null,
  user_id: null,
  is_assessment: false
};

export const mutations = {
  updateUser: (state, user) => {
      state.user = user
  },
  updateUserId: (state, user_id) => {
    state.user_id = user_id;
  },
  updateAssessment: (state, is_assessment) => {
    state.is_assessment = is_assessment;
  },
  updateWelcomeModalViewed: (state, value) => {
    if(state.user) {
      state.user.welcome_modal_viewed = value;
    }
  },
  logoutUser: (state) => {
      state.user = null
  }
};

export const actions = {
  ['set_user'](context, user) {
      context.commit('updateUser', user);
  },
  ['attempt_user'](context) {
     return axios.get('/api/me')
          .then((response) => {
              context.commit('updateUser', response.data.data)
              return response
          })
          .catch((error) => {
              context.commit('updateUser', null);
              throw error
          })
  },
  ['set_user_id'](context, user_id) {
    context.commit('updateUserId', user_id);
  },
  ['set_is_assessment'](context, is_assessment) {
    context.commit('updateAssessment', is_assessment);
  },
  ['set_welcome_modal_viewed'](context, value) {
    context.commit('updateWelcomeModalViewed', value);
  },
  ['logout'](context) {
      return axios.post('/logout')
          .then((response) => {
              return context.commit('logoutUser');
          })
          .catch((error) => {
              console.error(error)
              throw error
          })
  },
};

export const getters = {
  ['user'](state) {
      return state.user
  },
  ['user_id'](state) {
      return state.user_id;
  },
  ['is_assessment'](state) {
    return state.is_assessment;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};