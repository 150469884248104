<template>

  <div class="bg-secondary-50 sm:fixed">
    
    <!-- Header -->	
<div class="h-16 py-4 px-4 flex flex-row items-center bg-white top-0 z-10 relative flex-shrink-0 flex">
        <div class="go-back grid items-center">
          <button @click="goBack">      
            <ChevronLeftIcon class="h-8 w-8 text-gray-700 stroke-2"/>
          </button>
        </div>
<!-- Logo -->	
          <div class="">
            <router-link to="/">
              <img class="h-10 w-auto sm:h-11" src="/img/logo-dark.svg" alt="Youlio logo " />
            </router-link>
        </div>
      </div>
<!-- END Header -->

<!-- Static sidebar for desktop -->
    <div class="sm:ml-64 bg-white sm:border-l">
      <router-view />
    </div>
    </div>
  
  </template>
  
  <script setup>

import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
  import { useRouter } from 'vue-router';

  const router = useRouter()

  function goBack() {
  return router.go(-1)
  }
  </script>
  
  
  