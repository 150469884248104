<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="close" class="relative z-40">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-75" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-3 text-center">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative w-full transform overflow-hidden rounded-lg bg-white px-4 py-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-secondary-200 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon class="h-8 w-8 text-gray-700" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Confirm Deletion</DialogTitle>
                  <div class="mt-2">
                    <p class="mb-4 text-body-base">Are you sure you want to delete this note?</p>
                  </div>
                </div>
              </div>
              <div class="my-6 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" @click="confirm" class="inline-flex w-full justify-center rounded-md border border-gray-700 bg-secondary-200 px-4 py-2 text-base font-medium text-gray-700 hover:bg-secondary-300 sm:ml-3 sm:w-auto sm:text-sm">
                  Delete
                </button>
                <button type="button" @click="close" class="mt-4 inline-flex w-full justify-center rounded-md border border-gray-700 bg-white px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 sm:mt-0 sm:w-auto sm:text-sm">
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

// Define the props that the component expects
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  onConfirm: {
    type: Function,
    required: true
  },
  onClose: {
    type: Function,
    required: true
  },
  title: {
    type: String,
    default: 'Confirmation'
  },
  message: {
    type: String,
    default: 'Are you sure?'
  }
});

// Define emits for closing the modal
const emits = defineEmits(['close']);

// Function to handle closing the modal
const close = () => {
  emits('close');
};

// Function to handle confirmation action
const confirm = () => {
  props.onConfirm(); // Call the passed onConfirm function
  close(); // Close the modal after confirming
};
</script>