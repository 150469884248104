<template>

<HeaderComponent :pageTitle="'My Goals'" />
<div class="overflow-x-hidden">
<main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      
 <!-- Goals Card -->
<div v-if="getGoals.length > 0" class="card-journey">
  <div v-for="(goal, index) in getGoals" v-bind:key="goal.id" class="modal-list">
    <p class="modal-goal-number">G{{ index + 1 }} </p>
            <p class="text-body-base">{{ goal.goal }}</p>      
          </div>
        </div>
  <!-- No Entry Card -->
          <div v-else>    
            <p class="cf-tp-no-entries-title"> 
              No current entries
            </p>  
            <p class="cf-tp-no-entries-body"> 
              You and your therapist will add your 'Goals' during treatment.
            </p>  
             
        </div> 
    </main>
  </div> 
</template>

<script setup>
    
import HeaderComponent from '../components/HeaderComponent';

</script>

<script>
export default {
  props: {
    goals: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getGoals() {
      if (!this.onClientPage) {
        return this.$store.getters.getGoals;
      }
      return this.goals;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchGoals");
    }
  },
};
</script>