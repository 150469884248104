<template>

<!-- Title -->  
      <div class="py-8 px-8"> 
        <h1 class="ws-text-overline block text-center">
          Advice
        </h1>
        <h1 class="ws-text-heading-2-black mt-2 text-center mx-auto md:max-w-lg">
          What can therapy provide?      
        </h1>
      </div> 
<!-- Title END -->

<div class="py-4 px-6 mx-auto sm:max-w-xl">
<!-- 1 -->
        <div class="">
        <h1 class="ws-text-heading-4-black">
          A psychological outlet
          </h1>
          <br />
        <p class="ws-text-body-sm ">
          It is very common today to look after your physical health. Running, exercising, hitting the gym are all part of a typical week for many people yet when it comes to our psychological health we tend to pop it on the backburner.  
        </p>
        <br />
        <!-- Image -->  
        <div class="py-4">
          <img class="w-full rounded-lg" src="/img/website/therapy_provides/provide1.jpg" alt="Four people with emojis covering their faces showing different expressions" />       
        </div>
        <p class="ws-text-body-sm">
          It's important that we strive to meet  our psychological needs just as much as our physical needs and therapy can be a way of achieving this. When someone listens without judgement and tries to truly understand a person's situation, profound changes can occur that might have felt impossible before.        </p>   
        <br />   
        
      </div>
<!-- END 1 -->

<!-- 2 -->
<div class="">
        <h1 class="ws-text-heading-4-black">
          A safe and non-judgmental space separate from family and friends
          </h1>
          <br />
        <p class="ws-text-body-sm ">
          Sometimes friends and family can only get you so far. This is because they are naturally keen to "fix you", giving loads of advice, relating everything to their own experiences and perhaps saying or doing anything to make you feel better. 
        </p>
        <br />
        <!-- Image -->  
        <div class="py-4">
          <img class="w-full rounded-lg" src="/img/website/therapy_provides/provide2.jpg" alt="Someone holding some soil with a flower growing out of it" />       
        </div>
        <p class="ws-text-body-sm">
          Although helpful at the time it can be counter productive in the long run because the route of the problems are rarely looked at. Revealing delicate information to people we know might also be too difficult, we may feel shame, guilt or embarrassment. Friends and family can instinctively judge you which tends to close us up and bury the problem within.
        </p>   
        <br />   
        
      </div>
<!-- END 2 -->

<!-- 3 -->
<div class="">
        <h1 class="ws-text-heading-4-black">
          A way to gather unbiased perspective and find potential blind spots
          </h1>
          <br />
        <p class="ws-text-body-sm ">
          We often know ourselves far too well so trying to solve a problem with the headspace of the person with the problem can be a tad over optimistic. It’s no reflection on how smart or self-aware we are, it’s just incredibly hard to be objective about ourselves.  
        </p>
        <br />
        <!-- Image -->  
        <div class="py-4">
          <img class="w-full rounded-lg" src="/img/website/therapy_provides/provide3.jpg" alt="Woman with a cloud covering her face" />       
        </div>
        <p class="ws-text-body-sm">
          We’ve all experienced the situation when we’re telling someone else what they should do with 100% clarity but when we flip it onto ourselves, it all seems to fall apart.
        </p>   
        <br />   
        
      </div>
<!-- END 3 -->

<!-- 4 -->
<div class="">
        <h1 class="ws-text-heading-4-black">
          A way of learning some helpful techniques to take on life’s challenges more effectively
          </h1>
          <br />
        <p class="ws-text-body-sm ">
          In most areas of life there are tricks of the trade and the area of psychology is no different. Yes google can help but it can also cause feelings of overwhelm and lead to conflicting information.  
        </p>
        <br />
        <!-- Image -->  
        <div class="py-4">
          <img class="w-full rounded-lg" src="/img/website/therapy_provides/provide4.jpg" alt="Lighbulb made of scrunched up paper on a lime background" />       
        </div>
        <p class="ws-text-body-sm">
          Therapists have seen the reality of these techniques time and time again among many different individuals. Once clients have learnt and practised these techniques throughout therapy they are then there to utilise for the rest of their lives.
        </p>  
        <br />    
        
      </div>
<!-- END 4 -->


<!-- 5 -->
<div class="">
        <h1 class="ws-text-heading-4-black">
          A craving to find meaning and purpose
          </h1>
          <br />
        <p class="ws-text-body-sm ">
          Some people blissfully go through life content with whatever is in front of them. Others cling onto an overarching reason for their existence and can get lost in a wormhole trying to find it. You become the fly stuck in a web trying to break free over and over again until eventually it seems impossible.  
        </p>
        <br />
        <!-- Image -->  
        <div class="py-4">
          <img class="w-full rounded-lg" src="/img/website/therapy_provides/provide5.jpg" alt="Silhouette of a person looking up into the universe" />       
        </div>
        <p class="ws-text-body-sm">
          It’s not always easy to casually bring up the ‘meaning of life’ question in a pub when your friends are discussing a difficult day at work. Therapy is a great way to have the opportunity and freedom to work through life’s bigger questions with no imaginable constraints.
        </p> 
        <br />     
        
      </div>
      <br />
<!-- END 5 -->

</div>

</template>

<script setup>

</script>
