<template>

  <img src="/img/website/landing/wave-white-3.png" alt="white vector wave" /> 
  
    <!-- Hero BLock -->
    <section>
        <div class="bg-white overflow-x-hidden pb-16 md:pb-36 px-6 md:px-0 mx-auto md:max-w-4xl " > 
          
        <div class="md:pt-16 flex flex-col md:flex-row gap-4">
          <div class="order-2 md:order-1 flex-1">
            <!-- Content for the first column -->
            <TypingEffect />
            <p class="md:pr-6 mt-2 md:mt-6 text-xl md:text-xl font-light text-gray-700" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
              "I'm a licensed psychotherapist who's developed Youlio, an online platform that enables me to deliver therapy to you in a simple and affordable way. Book a free consultation with me today and start your journey towards better mental health!”              <br /><span class="text-sm font-semibold text-primary">(Youlio Founder, MSc PGDip MBACP GMBPsS)</span>
            </p>
            <div class="mt-12 md:mt-6 flex items-center justify-center md:justify-start gap-x-6">
              <router-link to="/register/overview-consult">
                <div class="ws-button-pill-landing">
                  Book a Free Consultation
                </div>
              </router-link>
            </div>
          </div>
          <div class="order-1 md:order-2 flex-1">
            <!-- Content for the second column -->
            <div class="mx-auto mb-6 md:mb-0 mt-6 md:mt-0 max-w-sm">
              <video class="rounded-xl shadow-lg" controls playsinline preload="metadata" 
              :poster="shouldUsePoster ? '/img/website/landing/video-poster-consult.jpg' : null">
                <source src="/img/website/landing/video-consult.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>      
        </div>   
      </section> 
  
    

<!-- Features Block-->
    <Features />
       
  <!-- Testimonial Block-->
        <section class="bg-secondary-600 overflow-x-hidden pt-16 md:pt-28 pb-24 md:pb-20">           
        
      </section>

<!-- My Approach -->
      <Approach />
    
<!-- FAQ Block-->   
<BlockStructureTitle structureTitle="FAQ" />  
    <BlockFAQ
      heading="Frequently Asked Questions"
      :faqs="[
        { question: 'What is Youlio?', answer: 'Youlio is an online therapy service provided by myself, Tom Mitchell. I carry out psychotherapy through secure video and chat alongside activities and psychological health tracking to help you live a better life.' },
        { question: 'Who is Youlio for?', answer: 'Youlio is available to people 18 years and older who need help with their psychological health.' },
        { question: 'What therapeutic approach do you use?', answer: 'The Youlio approach is based on a combination of therapies that are carried out using an overall structured framework. CBT, ACT, DBT and schema based therapy are all commonly used and you can find explanations of each under the \'types of therapy\' page. This approach allows the therapeutic relationship to grow organically in its own way whilst maintaining an overall sense of progress which leads to positive change.' },
        { question: 'How long does therapy with Youlio take?', answer: 'I base typical treatment over a period of 12 weeks. This is partly because I believe in action orientated change whilst ensuring that the treatment doesn\'t keep costing you money longer than it has to. Of course this not to say that treatment might be shorter or longer and ultimately it is up to you. It is not uncommon for this aspect to evolve with the therapy.' },
        { question: 'What is evidence based therapy?', answer: 'Evidence based therapy is any therapy where scientific research has been carried out to prove that it does what it claims to do. Cognitive Behavioural Therapy is currently the most researched type of therapy with an overwhelming number of studies that show it\'s effectiveness at tackling mental health problems.' }
      ]"
      bgClass="bg-primary-300"
    />
  
<!-- Final Block-->
        <section class="bg-white overflow-hidden" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">  
          <div class="px-6 sm:px-6 pt-16 sm:py-24 lg:px-8">
            
              <h2 class="mx-auto px-8 md:px-4 sm:max-w-3xl ws-text-heading-2-black text-center">
                Book a Free <br />Consultation Today!
              </h2>
              <img src="/img/website/landing/line.png" alt="green line" class="max-w-xs md:max-w-lg mx-auto mt-2" />  
             
              <dl class="mt-10 max-w-xl space-y-4 text-lg text-left leading-7 text-gray-600 lg:max-w-md mx-auto">
                            
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <CheckIcon class="absolute left-1 top-1 h-6 w-6 text-primary" aria-hidden="true" />
                    Quick and Easy
                  </dt>
                  <dd class="inline">It only takes a few minutes to book your free consultation.                
                  </dd>
                </div>

                  <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <CheckIcon class="absolute left-1 top-1 h-6 w-6 text-primary" aria-hidden="true" />
                    Private and Safe
                  </dt>
                  <dd class="inline">The consultation takes place in your own private, safe space created by Youlio.              
                  </dd>
                </div>

                  <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <CheckIcon class="absolute left-1 top-1 h-6 w-6 text-primary" aria-hidden="true" />
                    No commitments 
                  </dt>
                  <dd class="inline">This is simply an opportunity to meet me and see if we're a good fit for each other.                
                  </dd>
                </div>          
              </dl>
           
      <!--CTA-->  
          <div class="pt-12 pb-12 md:pb-6 mx-auto md:max-w-xs">
            <router-link to="/register/overview-consult">
              <div class="ws-button-pill-landing text-center">                   
                Book a Free Consultation     
              </div>
            </router-link>
            </div>

            <div class="max-w-3xl mx-auto pb-20 md:pb-8 pb-12 md:pt-12">
              <img src="/img/website/landing/block-final-landing.jpg" alt="lady wearing a hat with her arms out stretched" />  
            </div>

            </div>
        </section>
<!-- END Final Block-->   

<!--  Emergency Block --> 
        <section class="test-border-black overflow-x-hidden">
          <div class="flex items-center justify-start overflow-hidden bg-gray-100 px-6 py-2.5 sm:px-8">
            <div class="flex my-4">
            <MegaphoneIcon class="h-6 text-gray-800 shrink-0" aria-hidden="true" />
            <p class="ml-2 text-sm leading-6 text-gray-900">
            If you or someone else is in crisis - don't use this site. <a href="/helplines" class="whitespace-nowrap font-semibold">THESE RESOURCES</a> can provide you with immediate help.
            </p>
            </div>
          </div>
        </section>
    
    </template>
        
    <script setup>
        
          import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
          import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
          import { MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/vue/24/outline'
          import Banner from '../../website/components/Banner' ;
          import BlockTestimonial from '../../website/components/BlockTestimonial' ;
          import BlockFAQ from '../../website/components/BlockFAQ' ;
          
          import TypingEffect from '../../website/components/TypingEffect' ;
          import Features from '../../website/components/Features' ;
          import Approach from '../../website/components/Approach' ;
          import GettingStarted from '../../website/components/GettingStarted' ;
          import { KeyIcon, BanknotesIcon, CogIcon, WrenchScrewdriverIcon, PaperAirplaneIcon, ShieldCheckIcon, MegaphoneIcon, InboxIcon, SparklesIcon, VideoCameraIcon, ChatBubbleLeftEllipsisIcon, ChartBarIcon, PuzzlePieceIcon, BookOpenIcon } from '@heroicons/vue/24/outline'
          import { ChevronRightIcon, ChevronUpIcon, InformationCircleIcon, CubeIcon, CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/vue/20/solid'   
          import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/vue/20/solid'
  
    </script>
        
    <script>
        export default {
          data() {
            return {
              shouldUsePoster: true
            };
          }
        }
    </script>
        
        