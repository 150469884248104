<template>
  <div class="pt-16 px-6 swiper-block-container" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
    <!-- Overline, Headline, and Subheadline Section -->
    <div class="text-center max-w-3xl mx-auto mb-8">
      <p v-if="overline" class="ws-text-overline mb-2 text-primary">
        {{ overline }}
      </p>
      <h1 v-if="headline" class="ws-text-heading-md text-gray-900 mb-4">
        {{ headline }}
      </h1>
      <p v-if="subheadline" class="ws-text-body-base text-gray-600 mb-6">
        {{ subheadline }}
      </p>
    </div>

    <!-- Swiper -->
    <div class="swiper-container mb-12">
      <swiper
        :pagination="true"
        :navigation="true"
        :modules="modules"
        class="mySwiper"
        style="
          --swiper-pagination-color: #6597B8;
          --swiper-navigation-size: 32px;
        "
      >
        <!-- Slide Content Slot -->
        <swiper-slide v-for="(slideContent, index) in slideContents" :key="index" class="px-0 sm:px-12">
          <slot :name="'slide-' + index"></slot>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';

const modules = [Pagination, Navigation];

const props = defineProps({
  slides: {
    type: Number,
    required: true,
  },
  overline: {
    type: String,
    default: ''
  },
  headline: {
    type: String,
    default: ''
  },
  subheadline: {
    type: String,
    default: ''
  }
});

const slideContents = Array.from({ length: props.slides });
</script>

<style>
/* Custom CSS to position the swiper navigation arrows */
.swiper-container {
  position: relative;
  overflow: visible;
  z-index: 1;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  color: #2b7fb6;
  z-index: 10;
  cursor: pointer;
}

.swiper-button-prev {
  left: -10px !important;
}

.swiper-button-next {
  right: -10px !important;
}

@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

.swiper-block-container {
  max-width: 1200px;
  margin: 0 auto;
 
}
</style>
