<template>

  <!-- Title -->  
  <div class="py-8 px-8"> 
          <h1 class="ws-text-overline block text-center">
            Introducing
          </h1>
          <h1 class="ws-text-heading-2-black mt-2 text-center mx-auto md:max-w-lg">
            Anxiety     
          </h1>
        </div> 
  <!-- Title END -->
  
  <div class="py-4 px-6 mx-auto sm:max-w-xl">

<!-- Quote -->
        <blockquote>
          <p class="ws-text-body-sm">
            In the UK, over 8 million people are experiencing an anxiety disorder at any one time - <span class="font-semibold">Mental Health UK</span>
          </p>
          <br />
        </blockquote>
<!-- Quote END -->   

<!-- What is anxiety -->
        <h1 class="ws-text-display-base  block text-left">
          What is anxiety?
          </h1>
          <br />
        <p class="ws-text-body-sm  ">
          Everyone experiences anxiety throughout their lives. However, when it gets out of whack and starts to rear its head at every available opportunity it can have devastating effects on our ability to function.
        </p>
        <br />
        <p class="ws-text-body-sm ">
          Anxiety can come in many different flavours but generalised anxiety disorder (GAD) is by far the most common. A feeling of dread in the pit of your stomach, worrying about anything and everything, not being able to remember the last time you actually felt relaxed, these are all possible signs that someone may have GAD.
        </p>    
        <br />  
<!-- What is anxiety END -->

<!-- Image -->  
        <figure>
          <img class="w-full rounded-lg" src="/img/website/common_problems/depression.jpg" alt="Decorative image" width="1310" height="873" />
        </figure>
        <br />
<!-- Image END -->

<!-- Types of Anxiety -->
<h1 class="ws-text-display-base  block text-left">
        Types of anxiety
        </h1>
        <br />
          <p class=" ws-text-body-sm">
            Although GAD is by far the most common type, anxiety can also be about specific triggers (phobias) cause panic attacks to occur (panic disorder), develop due to traumatic events (PTSD) and turn into obsessive behaviour (OCD).       
          </p>  
          <br />

<!-- Fear vs Anxiety -->
        <h1 class="ws-text-display-base  block text-left">
          Is anxiety the same as fear?
        </h1>
        <br />
          <p class=" ws-text-body-sm">
            The main difference between anxiety and fear is that with anxiety the impending doom often doesn’t exist but your body still reacts as if it did. This means our survival mode still kicks into gear wreaking havoc on our bodies and minds.       
          </p> 
          <br />

<!-- Fear vs Anxiety END -->

<!-- Types of Anxiety -->
<h1 class="ws-text-display-base  block text-left">
          Diagnosing anxiety
        </h1>
        <br />
          <p class=" ws-text-body-sm">
            A standard set of criteria are used to diagnose GAD. People need to have experienced at least 5 of the symptoms below for a duration of at least 6 months.         
        </p>  
        <br />

<!-- Types of Anxiety END -->
        
<ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-4">
          <li>Excessive, ongoing worry and tension.</li>
          <li>Unrealistic view of problems.</li>
          <li>Restlessness or a feeling of being "edgy".</li>
          <li>Trouble concentrating.</li>
          <li>Tiring easily or being fatigued.</li>
          <li>Increased crankiness or irritability.</li>
          <li>Trouble sleeping.</li>
          <li>Muscle tension or muscle aches and soreness.</li>
      
        </ul>
        <br />

<!-- Diagnosing Anxiety END -->

<!-- What causes anxiety -->
        <h1 class="ws-text-display-base  block text-left">
          What causes anxiety?
        </h1>
        <br />
        <p class=" ws-text-body-sm">
          As with depression the biopsychosocial model can be used to explain anxiety disorders. This means three components are mostly responsible for its development.       
        </p>  
        <br />
        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-4">
          <li>A biological component (genes, biochemistry, diseases, drugs)</li>
          <li>A psychological component (cognitive distortions, history, coping styles, attributional style)</li>
          <li>A sociological component (social disturbances, distress, cultural influences)</li>
        </ul>
        <br />

<!-- What causes anxiety END -->
     
<!-- Treatment -->
<h1 class="ws-text-display-base  block text-left">
        How do you treat anxiety?
        </h1>
        <br />
   
        <p class=" ws-text-body-sm">
          The good news is many treatment options are available for anxiety disorders and therapy has been shown to be a very effective solution especially when approaches such as CBT are used. Anxiety and depression often co-exist together too which means it is not uncommon to find yourself experiencing low mood whilst feeling also constantly on edge.        
        </p> 
        <br />
<!-- Treatment END -->
<br />
  
  </div>
</template>

<script setup>

</script>
