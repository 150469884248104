<template>

  <div class="min-h-screen bg-white overflow-hidden"> 
      
      <Header />
  
      <div class="mx-auto px-4 py-4 max-w-3xl">
<!--Card-->
      <div class="px-4 py-4 mx-auto md:max-w-lg ">
<!--Step 1-->  

        <div v-if="step == 1">
          <div>
          <h1 class="ws-text-heading-3-black text-center" data-aos="slide-left" data-aos-once="true" data-aos-duration="500">
              Step 1 of 2
            </h1>
            <p class="pt-2 pb-16 ws-text-body-sm text-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500">
              Tell us your name and where you'd like your scores to be sent
            </p>
            <label class="ws-text-label" for="email">
              Name
            </label>
            <input v-model="data.name" class="ws-text-input" id="name" type="text" name="name" required="required">
            <div v-if="errors && errors.name">
              <p v-for="(error, index) in errors.name" :key="'name-'+index" class="text-red-500">{{ error }}</p>
            </div>
          </div>

          <div class="my-8">
            <label class="ws-text-label" for="email">
              Email
            </label>
            <input v-model="data.email" class="ws-text-input" id="email" type="email" name="email" required="required">
            <div v-if="errors && errors.email">
              <p v-for="(error, index) in errors.email" :key="'email-'+index" class="text-red-500">{{ error }}</p>
            </div>
          </div>

          <div class="mt-16">
            <button class="ws-button-primary-blue" @click="preRegistration">
              Begin Assessment
            </button>
          </div>
        </div>

<!--Step 2--> 

        <div v-if="step == 2">

          <div>
          <h1 class="ws-text-heading-3-black text-center" data-aos="slide-left" data-aos-once="true" data-aos-duration="500">
              Step 2 of 2
            </h1>
            <p class="pt-4 pb-16 ws-text-body-sm text-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500">
              Would you like to setup a free account just by adding a password? You'll then be able to book a free 30min consultation with me at a time and date that works for you.
            </p>

              <div class="w-full sm:max-w-md px-6 pb-4">
              <router-link to="/assessment/password">
                <div class="mx-auto">
                  <button type="submit" class="ws-button-primary-blue">
                    Yes please
                  </button>
                </div>
              </router-link>

              <div class="my-6 border-b border-gray-200"></div>
              <div @click="finish" class="mx-auto">
                <button type="submit" class="ws-button-secondary">
                  No thanks
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: () => {
    return {
      data: {
        name: null,
        email: null,
      },
      errors: null,
      step: 1,
      userId: null
    }
  },
  components: {
    
  },

  computed: {
    isQuestionnaireCompleted() {
      return Boolean(localStorage?.getItem("isQuestionnaireCompleted"));
    }
  },

  watch: {
    'data.name'() {
      if (this.errors && this.errors.name) {
        this.errors.name = null;
      }
    },
    'data.email'() {
      if (this.errors && this.errors.email) {
        this.errors.email = null;
      }
    }
  },

  mounted() {
    this.$store.dispatch('set_is_assessment', true);
    this.userId = this.$store.getters['user_id'];
    if (this.userId && this.isQuestionnaireCompleted) {
      this.step = 2;
    }

    if (localStorage?.getItem("email")) {
      this.data.email = localStorage.getItem("email");
      this.data.name = localStorage.getItem("name");
    }
  },

  methods: {
    async preRegistration() {
      await sendPost('/api/registration/check', {name: this.data.name, email: this.data.email}).then(response => {
        if (response) {
          this.errors = response.errors;
          if (!this.errors) {
            this.questionnaireinstanceid = response.questionnaire_instance_id;
            this.$store.dispatch('set_user_id', response.user_id);
            localStorage.email = this.data.email;
            localStorage.name = this.data.name;
            localStorage.isQuestionnaireCompleted = '';
            this.$router.push({ name: 'RegisterActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: this.questionnaireinstanceid} });
          }
        }
      });
    },
    finish() {
      // send results to user email here
      this.$store.dispatch('set_user_id', null);
      this.$router.push({ name: 'AssessmentFinish' });
    },
    register() {
      this.errors = null
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/register', this.data)
          .then((response) => {
            this.$store.dispatch('attempt_user')
              .then(() => {
                this.$router.push({name: 'Home'})
              })
              .catch((error) => {
                console.error(error);
              })
          })
          .catch((error) => {
            this.errors = error.response.data.errors
          })
      });
    }
  }
}
</script>

<script setup>

import Header from '../../../website/components/Header';
import { sendPost } from '../../../components/entities/functions.js';

</script>
