<template>

  <div class="py-4 md:max-w-2xl px-8 mx-auto">
    
    <br />
  <h1 class="ws-text-body-black-strong-sm">
    Effective Date: August 1, 2023    
  </h1>
  <br />
  <h1 class="ws-text-body-black-strong-sm">
    Terms of Use  
  </h1>
  <br />
        <p class="ws-text-body-sm">
          Welcome to Youlio Health Ltd. ("we," "our," or "us"). Please read these Terms of Use (the "Terms") carefully, as they govern your use of our therapy platform and services.
        </p> 
        <br /> 
        <p class="ws-text-body-sm">
          IF YOU HAVE A MEDICAL EMERGENCY, IMMEDIATELY CALL YOUR PRIMARY CARE PROVIDER. OUR SERVICES ARE NOT APPROPRIATE FOR EMERGENCY MEDICAL CONCERNS.
        </p> 
        <br /> 
        <h1 class="ws-text-body-black-strong-sm">
          1. Description of Services
        </h1>  
        <br />

        <p class="ws-text-body-sm">
          Our platform provides a space for individuals to connect with healthcare providers for mental health treatment, including clinical care for issues like depression and anxiety. The Services encompass:
        </p> 
        <br />
        

        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-2">
          <li>Completing self-administered electronic screening assessments for anxiety and depression symptoms.</li>
          <li>Accessing information about mental health conditions, treatments, and resources.</li>
          <li>Connecting with licensed healthcare providers for therapy.</li>
          <li>Receiving mental health services on a recurring basis.</li>
          <li>Monitoring your care and progress through additional tools and resources.</li>
        </ul>  
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          2. Nature of Services
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          Our services may evolve, and we may discontinue certain features at our discretion. Healthcare services, including therapy are provided by licensed healthcare providers, and decisions regarding your care are made based on professional judgment.
        </p> 

        <br />
        <h1 class="ws-text-body-black-strong-sm">
          3. Telehealth Consent Policy
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          To receive healthcare services, you must agree to our Telehealth Consent policy, which includes:
        </p> 
        <br />
          <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-2">
          <li>Voluntary authorisation for treatment by healthcare providers.</li>
          <li>Acknowledgment that treatment outcomes are not guaranteed.</li>
          <li>Right to refuse treatment and discuss options with healthcare providers.</li>
          <li>Responsibility for payment of provided services.</li>
        </ul>
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          4. Healthcare Provider Complaints
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          If you have concerns about a healthcare provider's professional standards, you can file a complaint with our administrative clinical team. Details should be sent to our Support team at hello@youliohealth.com. Filing a complaint won't result in discrimination.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          5. Agreement to Terms
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          By using our services, you agree to abide by these Terms. The terms also extend to your heirs, successors, assigns, and legal representatives. If you disagree with these Terms, please do not use our services.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          6. Privacy
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          Refer to our Privacy Policy for information about data handling and your privacy rights. By using our services, you consent that your personal information is subject to the terms of our Privacy Policy.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          7. Changes to Terms
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          We may update these Terms at any time, and continued use constitutes agreement to the updated terms. Regularly review the Terms while using our services.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          8. Who May Use the Services
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          You must be 18 years or older and capable of forming a binding contract to use our services. Registering an account is necessary to access our services.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          9. Account Protection
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          Protect your account password and notify us of unauthorised use promptly. You're responsible for all activities under your account.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          10. Feedback
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          We appreciate feedback for service improvement. By submitting feedback, you grant us a license to use and exploit the feedback.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          11. Payment and Billing
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          Certain services may require payment. Subscriptions may be available, and we may charge your provided payment information for these services. Review our payment terms and obligations.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          12. Prohibited Uses
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          Use our services for personal, non-commercial purposes only. Do not engage in prohibited activities, including violating laws, stalking, or disrupting services.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          13. Indemnity and Limitation of Liability
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          You will indemnify us against claims arising from your use of the services. Our liability is limited as specified in this section.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          14. Dispute Resolution
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          Any claims arising from this agreement must be resolved exclusively by binding arbitration, except for injunctive remedies.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          15. Notices and Communications
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          Notices will be provided via the platform or other appropriate means. By using our services, you consent to receiving electronic and traditional communications.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          16. Contact Information
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          For inquiries or complaints, contact our Support team at hello@youliohealth.com.
        </p> 
        <br />
        <br />
        




  </div>

</template>

<script setup>

</script>