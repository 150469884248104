<template>

<HeaderComponent :pageTitle="'Deep Dive'" />

<loading-overlay :loading="loading"></loading-overlay>
<div class="overflow-x-hidden">
<!--Title&Filter-->
<main v-if="!loading" class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
    
    <div class="my-4" >

    <div v-if="Object.keys(results).length">
      <deep-dive-values v-for="result in results" :data="result" />
    </div>
    <div v-else class="card-no-entries">
      <p>There are currently no entries in your 'Deep Dive'.</p>
    </div>

      </div>
    </main>
  </div>
</template>

<script setup>

  import { ref } from 'vue'
  import { ChevronLeftIcon, BellIcon, UserCircleIcon  } from '@heroicons/vue/24/outline'
  import InfoIcon from '../components/InfoIcon';
  import DeepDiveValues from '../components/DeepDiveValues';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  import HeaderComponent from '../components/HeaderComponent';

</script>

<script>
export default {
  data: function () {
    return {
        results: {},
        loading: false
    }
  },

  mounted() {
    this.getResults();
  },

  methods: {
    async getResults() {
      this.loading = true;
      const responce = await sendPost('/api/deep-dive');
      if (responce) {
        this.results = responce.results;
      }
      this.loading = false;
    }
  }
}
</script>
