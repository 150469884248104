<template>

  <div class="py-4 md:max-w-2xl px-8 mx-auto">
    
    <br />
  <h1 class="ws-text-body-black-strong-sm">
    Effective Date: August 1, 2023    
  </h1>
  <br />
  <h1 class="ws-text-body-black-strong-sm">
    Youlio Health Privacy Policy   
  </h1>
  <br />
        <p class="ws-text-body-sm">
          Welcome to Youlio Health Ltd. ("we," "our," or "us"). This Privacy Policy explains how we collect, use, and protect the information you provide when using our therapy platform.
        </p> 
        <br /> 
        <h1 class="ws-text-body-black-strong-sm">
          Introduction  
        </h1>  
        <br />

        <p class="ws-text-body-sm">
          We provide a platform for individuals to connect with a Healthcare Provider for clinical care as well as carry out treatment for mental health problems like depression and anxiety. This Privacy Policy outlines our information practices, the types of information we collect, how we use and share that information, and your choices regarding the sharing of your information.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          1. Information We Collect. 
        </h1>  
        <br />

        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-2">
          <li>Account Creation: When you create a user account, we collect your name, email, and password.</li>
          <li>Communications with Us: We may collect personal information from you such as your email address, phone number, or mailing address when you request information about our Services, register for our newsletter, request customer or technical support, or otherwise communicate with us.</li>
          <li>Automatically Collected Information: We may collect certain information automatically when you use our platform. This may include your IP address, user settings, cookie identifiers, mobile carrier, details about your browser, operating system, or device, location information, pages you visit, and other information about your usage.</li>
          <li>Cookies, Pixel Tags/Web Beacons, Analytics Information, and Interest-Based Advertising: We, as well as third-parties that provide content, advertising, or other functionality on the Site or Services, may use cookies, pixel tags, local storage, and other technologies (“Technologies”) to automatically collect information. Technologies are essentially small data files placed on your computer, tablet, mobile phone, or other devices that allow us to record certain pieces of information whenever you visit or interact with our Site or Services.</li>

          <p class="pl-4">- Cookies: Cookies are small text files placed in visitors’ device browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Site or Services may not work properly.</p>
          <p class="pl-4">- Pixel Tags/Web Beacons: A pixel tag (also known as a web beacon) is a piece of code embedded on the Site that collects information about users’ engagement on that web page. The use of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement.</p>
          <p class="pl-4">- These Technologies may be employed for the following purposes:</p>
          <p class="pl-8">Operational Necessity: This includes Technologies that allow you access to our Site, Services, applications, and tools that are required to identify irregular Site behavior, prevent fraudulent activity and improve security or that allow you to make use of our functions such as saved searches.</p>
          <p class="pl-8 pt-1">Performance Assessments: We may use Technologies to assess the performance of our Site and Services, including as part of our analytic practices to help us understand how our visitors use the Site and Services.</p>
          <p class="pl-8 pt-1">Functionality: We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Site and Services. This may include identifying you when you sign into our Site or Services, or keeping track of your specific preferences, interests, or past items viewed.</p>
          <p class="pl-8 pt-1">Advertising or Targeting Related: We may use first-party or third-party Technologies to deliver content, including ads relevant to your interests, on our Site and Services or on third-party sites.</p>
          
        </ul>
  
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          2. How We Use Your Information.
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          We use your information for various purposes, including:
        </p> 
        <br />
          <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-2">
          <li>Account Management: Managing your accounts and providing access to our platform's functionalities.</li>
          <li>Communication: Responding to your inquiries, providing customer or technical support, and communicating about your account and activities.</li>
          <li>Services: Offering you access to specific areas and features of our web app, processing transactions, and improving our services.</li>
          <li>Analytics: Analysing and improving our platform's performance, understanding user behavior, and conducting research.</li>
        </ul>

        <br />
        <h1 class="ws-text-body-black-strong-sm">
          3. Data Sharing.
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          We may share your information with:
        </p> 
        <br />
          <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-2">
          <li>Therapists: Your treatment-related information is shared with the therapists assigned to your sessions.</li>
          <li>Service Providers: Third-party service providers who assist in delivering our services.</li>
        </ul>
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          4. International Data Transfers.
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          By using our services, you agree that your information may be processed, transferred, and stored in locations around the world, including the United Kingdom and other countries.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          5. Your Choices.
        </h1>  
        <br />
        <p class="ws-text-body-sm">
          In accordance with applicable law, you may have the right to access, correct, or request deletion of your personal information. You can exercise these rights by contacting us.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          6. Security.
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          We take steps to secure your information, but no system is entirely secure. We are not liable for unintentional disclosures.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          7. Changes to this Policy.
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          We may revise this Privacy Policy. If there are significant changes, we will notify you.
        </p> 
        <br />
        <h1 class="ws-text-body-black-strong-sm">
          8. Contact Us.
        </h1> 
        <br />
        <p class="ws-text-body-sm">
          For questions or requests related to your personal information or this policy, please contact our Support team at hello@youliohealth.com.
        </p> 
        <br />
        <br />
        




  </div>

</template>

<script setup>

</script>