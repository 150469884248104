<template>

<img src="/img/website/landing/wave-white-3.png" alt="white vector wave" /> 

  <!-- Hero BLock -->
  <section>
      <div class="bg-white overflow-x-hidden pb-16 md:pb-36 px-6 md:px-0 mx-auto md:max-w-4xl " > 
        
        <!-- Title
        <div class="pt-8 md:pt-0 text-center">
            <h2 class="mx-auto px-4 md:px-4 sm:max-w-3xl text-4xl sm:text-6xl font-bold text-gray-700">
              Your Free Mental Health MOT
            </h2>
            <div class="max-w-xs md:max-w-lg mx-auto">
            <img src="/img/website/landing/line.png" alt="green line" />  
            </div>
          </div>
        -->

      <div class="md:pt-16 flex flex-col md:flex-row gap-4">
        <div class="order-2 md:order-1 flex-1">
          <!-- Content for the first column -->
          <TypingEffect />
          <p class="md:pr-6 mt-2 md:mt-6 text-xl md:text-xl font-light text-gray-700" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
            "I’m a licensed psychotherapist and the creator of Youlio, an online platform that enables me to provide therapy and mental health support in a simple and affordable way. My free ‘Mental Health MOT’ is a great way to assess your current mental health and discover ways to improve it. Take yours today and join me on a journey to better mental health!"
            <br /><span class="text-sm font-semibold text-primary">(Youlio Founder, MSc PGDip MBACP GMBPsS)</span>
          </p>
          <div class="mt-12 md:mt-6 flex items-center justify-center md:justify-start gap-x-6">
            <router-link to="/register/overview-mot">
              <div class="ws-button-pill-landing-secondary">
                Get Started
              </div>
            </router-link>
          </div>
        </div>
        <div class="order-1 md:order-2 flex-1">
          <!-- Content for the second column -->
          <div class="mx-auto mb-6 md:mb-0 mt-6 md:mt-0 max-w-sm">
            <video class="rounded-xl shadow-lg" controls playsinline preload="metadata" 
            :poster="shouldUsePoster ? '/img/website/landing/video-poster-mot-2.jpg' : null">
              <source src="/img/website/landing/video-mot-2.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>      
      </div>   
    </section> 

<!-- MOT Block --> 
    <section class="bg-none">
      <img src="/img/website/landing/wave-white-3.png" alt="white vector wave" /> 
        <div class="mx-auto max-w-4xl pt-12 md:pt-12 pb-4 md:pb-16"> 
          <div class="mx-auto px-6 max-w-4xl">
              
            <h3 class="mx-auto sm:max-w-3xl text-4xl sm:text-6xl font-bold text-gray-700 text-center ">        
              DISCOVER. <span class="text-secondary-400">UNDERSTAND.</span><span class="text-primary"> THRIVE.</span>
            </h3>
          </div>
    <div class="px-6">
    <p class="mx-auto max-w-xl my-6 md:mt-10 md:mb-2 text-xl md:text-xl font-light text-gray-700" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
      My mental health MOT involves a self-assessment that measures 5 key areas of your mental health, followed by a free 20 minute consultation with me to help you understand your results and suggest some actionable next steps.   
    </p>
  </div>
</div>    
  

     
    <div class="px-4">
<!-- DISCOVER -->
     <div class="w-full">
     <div class="mx-auto w-full max-w-2xl rounded-2xl bg-gray-100 p-2">
      <Disclosure v-slot="{ open }">
         <DisclosureButton
           class="flex w-full justify-between rounded-xl bg-gray-200 px-4 py-5 text-left text-lg md:text-lg font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-secondary-500/75"
         >
           <span class="font-bold text-xl">DISCOVER
          <span class="font-normal"></span></span>
          <ChevronUpIcon
    :class="['h-7 w-auto text-gray-700', { 'rotate-180 transform': open, 'transition-transform duration-200': true }]"
  />
         </DisclosureButton>
       <!--Transition-->
       <transition
           enter-active-class="transition duration-100 ease-out"
           enter-from-class="transform scale-95 opacity-0"
           enter-to-class="transform scale-100 opacity-100"
           leave-active-class="transition duration-75 ease-out"
           leave-from-class="transform scale-100 opacity-100"
           leave-to-class="transform scale-95 opacity-0"> 
         <!--Content Here-->   
           <DisclosurePanel class="-mt-2 p-4 md:p-8 rounded-b-xl card-shadow leading-normal text-base font-sans font-normal text-white bg-white">
              
                <p class="ws-text-body-sm mt-4 ">               
                  You'll begin by completing a brief questionnaire designed to assess five key aspects of your mental health: depression, anxiety, stress, self-esteem and happiness. Once finished, you'll get immediate access to your personalised dashboard, where you can see your results right away. 
                </p>
             <div class="my-8 max-w-sm mx-auto">
             <img class="rounded-xl" src="/img/website/landing/step1-2.jpg" alt="Someone holding a mobile phone with the Youlio app on it" />
           </div>
         </DisclosurePanel>
       </transition>
     </Disclosure>
    </div>
    </div>
    
<!-- UNDERSTAND -->
    <div class="w-full my-6 ">
     <div class="mx-auto w-full max-w-2xl rounded-2xl bg-secondary-100 p-2">
      <Disclosure v-slot="{ open }">
         <DisclosureButton
           class="flex w-full justify-between rounded-xl bg-secondary-200 px-4 py-5 text-left text-lg md:text-lg font-medium text-gray-700 hover:bg-secondary-300 focus:outline-none focus-visible:ring focus-visible:ring-secondary-500/75"
         >
         <span class="font-bold text-xl">UNDERSTAND
            <span class="font-normal"></span></span>
            <ChevronUpIcon
    :class="['h-7 w-auto text-gray-700', { 'rotate-180 transform': open, 'transition-transform duration-200': true }]"
  />
         </DisclosureButton>
       <!--Transition-->
       <transition
           enter-active-class="transition duration-100 ease-out"
           enter-from-class="transform scale-95 opacity-0"
           enter-to-class="transform scale-100 opacity-100"
           leave-active-class="transition duration-75 ease-out"
           leave-from-class="transform scale-100 opacity-100"
           leave-to-class="transform scale-95 opacity-0">
       <!--Content Here--> 
           <DisclosurePanel class="-mt-2 p-4 md:p-8 rounded-b-xl card-shadow leading-normal text-base font-sans font-normal text-white bg-white">     
            
                <p class="ws-text-body-sm mt-4 ">
                  Next, you can book a free 20-minute consultation with me, directly from your Youlio dashboard. Simply find a date and time that works for you and schedule your consultation. During our meeting, I’ll help you understand your results and suggest steps you can take to improve your mental health.              
                </p>
             <div class="my-8 max-w-sm mx-auto">
             <img class="rounded-xl" src="/img/website/landing/step2-2.jpg" alt="Someone holding a mobile phone with the Youlio app on it" />
           </div>
         </DisclosurePanel>
       </transition>
     </Disclosure>
    </div>
    </div> 
<!-- THIRVE -->
    <div class="w-full mt-6 pb-12 ">
     <div class="mx-auto w-full max-w-2xl rounded-2xl bg-primary-100 p-2">
      <Disclosure v-slot="{ open }">
         <DisclosureButton
           class="flex w-full justify-between rounded-xl bg-primary-200 px-4 py-5 text-left text-lg md:text-lg font-medium text-gray-700 hover:bg-primary-300 focus:outline-none focus-visible:ring focus-visible:ring-secondary-500/75"
         >
         <span class="font-bold text-xl">THRIVE
            <span class="font-normal"></span></span>
            <ChevronUpIcon
    :class="['h-7 w-auto text-gray-700', { 'rotate-180 transform': open, 'transition-transform duration-200': true }]"
  />
         </DisclosureButton> 
       <!--Transition-->
       <transition
           enter-active-class="transition duration-100 ease-out"
           enter-from-class="transform scale-95 opacity-0"
           enter-to-class="transform scale-100 opacity-100"
           leave-active-class="transition duration-75 ease-out"
           leave-from-class="transform scale-100 opacity-100"
           leave-to-class="transform scale-95 opacity-0"> 
         <!--Content Here--> 
           <DisclosurePanel class="-mt-2 p-4 md:p-8 rounded-b-xl card-shadow leading-normal text-base font-sans font-normal text-white bg-white">     
                <p class="ws-text-body-sm mt-4 ">
                  Beyond the mental health MOT, I offer a number of pathways including one-off mental health booster sessions, weekly therapy and targeted programs that focus on specific challenges like anxiety or stress. I want to ensure there is an option for everyone regardless of personal circumstances.               
                </p>
             <div class="my-8 max-w-sm mx-auto">
             <img class="rounded-xl" src="/img/website/landing/step3-2.jpg" alt="Someone holding a mobile phone with the Youlio app on it" />
           </div>
         </DisclosurePanel>
       </transition>
     </Disclosure>
    </div>
    </div>
    </div>
    
    <!-- CTA Button -->
      <div class="flex flex-col items-center pb-24 pt-4">
          <router-link to="/register/overview-mot">
          <div class="ws-button-pill-landing-secondary">                   
            Get Started     
          </div>
          </router-link>
        </div>
        <!-- END Button -->
     
    </section>
     
<!-- Testimonial Block-->
      <section class="bg-secondary-600 overflow-x-hidden pt-16 md:pt-28 pb-24 md:pb-20">           
      <CarouselMOT /> 
    </section>
  
<!-- FAQ Block-->   
<BlockStructureTitle structureTitle="FAQ" />  
    <BlockFAQ
      heading="Frequently Asked Questions"
      :faqs="[
        { question: 'What is Youlio?', answer: 'Youlio is an online therapy service provided by myself, Tom Mitchell. I carry out psychotherapy through secure video and chat alongside activities and psychological health tracking to help you live a better life.' },
        { question: 'Who is Youlio for?', answer: 'Youlio is available to people 18 years and older who need help with their psychological health.' },
        { question: 'What therapeutic approach do you use?', answer: 'The Youlio approach is based on a combination of therapies that are carried out using an overall structured framework. CBT, ACT, DBT and schema based therapy are all commonly used and you can find explanations of each under the \'types of therapy\' page. This approach allows the therapeutic relationship to grow organically in its own way whilst maintaining an overall sense of progress which leads to positive change.' },
        { question: 'How long does therapy with Youlio take?', answer: 'I base typical treatment over a period of 12 weeks. This is partly because I believe in action orientated change whilst ensuring that the treatment doesn\'t keep costing you money longer than it has to. Of course this not to say that treatment might be shorter or longer and ultimately it is up to you. It is not uncommon for this aspect to evolve with the therapy.' },
        { question: 'What is evidence based therapy?', answer: 'Evidence based therapy is any therapy where scientific research has been carried out to prove that it does what it claims to do. Cognitive Behavioural Therapy is currently the most researched type of therapy with an overwhelming number of studies that show it\'s effectiveness at tackling mental health problems.' }
      ]"
      bgClass="bg-primary-300"
    />


<!-- Final Block-->
<section class="bg-white overflow-hidden" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">  
          <div class="px-6 sm:px-6 pt-16 sm:py-24 lg:px-8">
            
              <h2 class="mx-auto px-8 md:px-4 sm:max-w-3xl ws-text-heading-2-black text-center">
                Start Your Free <br />Mental Health MOT Today!
              </h2>
              <img src="/img/website/landing/line.png" alt="green line" class="max-w-xs md:max-w-lg mx-auto mt-2" />  
             
              <dl class="mt-10 max-w-xl space-y-4 text-lg text-left leading-7 text-gray-600 lg:max-w-md mx-auto">
                            
                <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <CheckIcon class="absolute left-1 top-1 h-6 w-6 text-primary" aria-hidden="true" />
                    Quick and Easy
                  </dt>
                  <dd class="inline">The self-assessment measures 5 key mental health areas in just a few minutes.               
                  </dd>
                </div>

                  <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <CheckIcon class="absolute left-1 top-1 h-6 w-6 text-primary" aria-hidden="true" />
                    Personalised Guidance
                  </dt>
                  <dd class="inline">A free consultation with me enables you to understand your mental health better.                
                  </dd>
                </div>

                  <div class="relative pl-9">
                  <dt class="inline font-semibold text-gray-900">
                    <CheckIcon class="absolute left-1 top-1 h-6 w-6 text-primary" aria-hidden="true" />
                    A Private, Safe Space
                  </dt>
                  <dd class="inline">Youlio is completely confidential and can be accessed whenever you need support.                
                  </dd>
                </div>          
              </dl>
           
      <!--CTA-->  
      <div class="mb-8 mt-8 md:mt-12 md:mb-4 flex items-center justify-center">
            <router-link to="/register/overview-mot">
              <div class="ws-button-pill-landing-secondary">
                Get Started
              </div>
            </router-link>
          </div>

            <div class="max-w-3xl mx-auto pb-20 md:pb-8 pb-12 md:pt-12">
              <img src="/img/website/landing/block-final-landing.jpg" alt="lady wearing a hat with her arms out stretched" />  
            </div>

            </div>
        </section>
<!-- END Final Block-->  


      
  <!--  Emergency Block --> 
      <section class="test-border-black overflow-x-hidden">
        <div class="flex items-center justify-start overflow-hidden bg-gray-100 px-6 py-2.5 sm:px-8">
          <div class="flex my-4">
          <MegaphoneIcon class="h-6 text-gray-800 shrink-0" aria-hidden="true" />
          <p class="ml-2 text-sm leading-6 text-gray-900">
          If you or someone else is in crisis - don't use this site. <a href="/helplines" class="whitespace-nowrap font-semibold">THESE RESOURCES</a> can provide you with immediate help.
          </p>
          </div>
        </div>
      </section>
  
  </template>
      
  <script setup>
      
        import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
        import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
        import { MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/vue/24/outline'
        import Banner from '../../website/components/Banner' ;
      
        import BlockFAQ from '../../website/components/BlockFAQ' ;
        
        import TypingEffect from '../../website/components/TypingEffect' ;
        import GettingStarted from '../../website/components/GettingStarted' ;
        import { KeyIcon, BanknotesIcon, CogIcon, WrenchScrewdriverIcon, PaperAirplaneIcon, ShieldCheckIcon, MegaphoneIcon, InboxIcon, SparklesIcon, VideoCameraIcon, ChatBubbleLeftEllipsisIcon, ChartBarIcon, PuzzlePieceIcon, BookOpenIcon } from '@heroicons/vue/24/outline'
        import { ChevronRightIcon, ChevronUpIcon, InformationCircleIcon, CubeIcon, CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/vue/20/solid'   
        import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/vue/20/solid'

  </script>
      
  <script>
      export default {
        data() {
          return {
            shouldUsePoster: true
          };
        }
      }
  </script>
      
      <style scoped>
      .rotate-180 {
        transform: rotate(180deg);
      }
      .transition-transform {
        transition: transform 0.2s;
      }
      </style>