<template>
  
  <HeaderComponent :pageTitle="client?.name || 'Client'" />
    <div class="overflow-x-hidden">
      <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

    <div class="space-y-6">
    
      <!-- Button Problems -->	
    <ButtonJourney
      :link="{ name: 'ClientViewTracking', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Tracking"
      subtitle="LEARN MORE"
    />

    <!-- Button Problems -->	
<ButtonJourney
      :link="{ name: 'ClientViewDeepDive', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Deep Dive"
      subtitle="LEARN MORE"
    />

        <!-- Button Problems -->	
<ButtonJourney
      :link="{ name: 'ClientViewSpotlights', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Spotlights"
      subtitle="LEARN MORE"
    />

        <!-- Button Problems -->	
<ButtonJourney
      :link="{ name: 'ClientViewActivities', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Activities"
      subtitle="LEARN MORE"
    />

        <!-- Button Problems -->	
<ButtonJourney
      :link="{ name: 'ClientViewStage1', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Stage 1"
      subtitle="LEARN MORE"
    />

        <!-- Button Problems -->	
<ButtonJourney
      :link="{ name: 'ClientViewStage2', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Stage 2"
      subtitle="LEARN MORE"
    />

        <!-- Button Problems -->	
<ButtonJourney
      :link="{ name: 'ClientViewStage3', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Stage3"
      subtitle="LEARN MORE"
    />

            <!-- Button Problems -->	
<ButtonJourney
      :link="{ name: 'ClientViewNotes', params: { clientId: client_id } }"
      imageSrc="/img/webapp/case_formulation/triggers.png"
      imageAlt="Clipboard showing list of symptoms"
      title="Notes"
      subtitle="LEARN MORE"
    />

  </div>
   

  </main>
</div>
  
</template>

<script setup>
  import { ref } from 'vue'
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { sendPost } from '../components/entities/functions.js';
  import HeaderComponent from '../components/HeaderComponent';
  import ButtonJourney from '../components/ButtonJourney';


</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
    
    
    
    },

  

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>