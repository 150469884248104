<template>
  <TransitionRoot as="div" :show="isOpen" @close="closeModal">
    <Dialog as="div" class="relative z-50" @close="closeModal">
      <!-- The overlay -->
      <div class="fixed inset-0 bg-black bg-opacity-80" aria-hidden="true"></div>

      <!-- The modal and close button container -->
      <div class="fixed inset-0 flex items-center justify-center p-4 text-center z-50">
        
        <!-- Close Button "X" -->
        <button 
          @click="closeModal" 
          class="absolute top-2 right-4 bg-primary p-2 rounded-full shadow-lg focus:outline-none z-50">
          <!-- X icon using an SVG -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-5 w-5 text-white">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <!-- The modal -->
        <DialogPanel class="w-full max-w-2xl max-h-[80vh] transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all z-40 overflow-y-auto">
      
          <div class="mt-2">
            <!-- Scrollable content -->
            <p class="border-b text-base font-semibold text-gray-800">{{ spotlightQuestion }}</p>
            <p class="text-base text-gray-700 mt-4">{{ spotlightAnswer }}</p>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import { TransitionRoot, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

const props = defineProps({
  isOpen: Boolean,
  spotlightQuestion: String,
  spotlightAnswer: String
});

const emit = defineEmits(['close']);

const isOpen = ref(props.isOpen);

// Watch for changes in the `isOpen` prop
watch(() => props.isOpen, (newValue) => {
  isOpen.value = newValue;
});

function closeModal() {
  isOpen.value = false;
  emit('close');
}
</script>

<style scoped>
/* Additional styling to make sure the "X" button is outside the modal */
</style>
