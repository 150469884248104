<template>

<!-- Header -->	
    <header class="header-container">
      <router-link to="/activities">
      <div class="flex items-center">
        <ChevronLeftIcon class="flex items-center chevron-header-dark -ml-2" />
        <h1 class="text-page-title-dark">Reading</h1>
      </div>  
    </router-link>   
  </header>
<!-- END Header -->	
<div class="overflow-x-hidden">
  <main class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250"> 

        <div v-if="getReadingInstance && getReadingInstance.reading" class="md:max-w-md">
          <div class="card-bg-white-padding-none pb-8 relative">
            <img class="object-cover top-edge-rounded" :src="'/storage/' + getReadingInstance.reading.image" alt="Image to represent the reading being displayed" />
        <div class="top-edge-rounded absolute inset-0 h-32 md:h-44 bg-reading opacity-30 " />
              
          <div class="px-4 py-3 bg-reading">
            <h4 class="text-activity-type text-reading-100">Reading</h4>
            <p class="text-activity-display">{{ getReadingInstance.reading.title }}</p>
           </div>
            
          <div class="px-4">
          <ul class="py-6 flex">
            <li class="py-2 flex items-center">
              <CircleStackIcon class="activity-icon" />
              <div class="ml-1 text-activity-icon-support">{{ getReadingInstance.status }}</div>
            </li>   
            <li class="ml-8 py-2 flex items-center">
              <ClockIcon class="activity-icon" />
            <div class="ml-1 text-activity-icon-support">{{ getReadingInstance.reading.estimated_time }}</div>
            </li>            
          </ul>

            <p class="text-activity-body">{{ getReadingInstance.reading.description }}</p>

          <router-link :to="{name: 'ActivityReadingInstanceStart', params: { readinginstanceid: getReadingInstance.id }}">
          <button class="bg-reading button-activity">
              {{ buttonText }}
            </button>
          </router-link>
          </div> 
        </div>
      </div>
        <div v-else class="text-display-base">
            Loading...
        </div>
  </main>
</div>
</template>

<script setup>
  
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import {ClockIcon, CircleStackIcon } from '@heroicons/vue/24/solid'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'

</script>

<script>

export default {
  data() {
    return {
      store: null
    };
  },
  mounted() {
    this.store = useStore();
    this.store.dispatch("fetchReadingInstance");
  },
  computed: {
    getReadingInstance() {
      if (this.store?.getters?.getReadingInstance) {
        return this.store.getters.getReadingInstance;
      }
      return null;
    },
    buttonText() {
      let text = 'Begin';
      if (!this.getReadingInstance) {
        return text;
      }

      switch (this.getReadingInstance.status) {
        case 'In Progress':
          text = 'Continue';
        break;
        case 'Complete':
          text = 'Revisit';
        break;
      }
      return text;
    }
  },
  methods: {}
};
</script>

