<template>
 <HeaderComponent :pageTitle="client?.name || 'Client'" />
    <div class="overflow-x-hidden">
      <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

  <loading-overlay :loading="loading"></loading-overlay>
  <div v-if="!loading" class="my-4 md:my-24 overflow-x-hidden">

    <!-- Notes -->
    <div class="bg-gradient-to-r from-primary-200 to-secondary-200 p-4 max-w-6xl">
    <div>
      
        <div class="text-secondary-600 w-full text-left font-semibold">
          <h3>Notes</h3>
        </div>
        <div>
          <div v-for="note in reversedNotes" :key="note.id" class="bg-white border p-4 my-2 shadow-md rounded-md max-w-lg">
            <Disclosure>
              <template #default="{ open }">
                <DisclosureButton class="w-full text-left">
                  <h3 class="font-semibold">{{ note.title }}</h3>
                </DisclosureButton>
                <DisclosurePanel>
                  <hr class="mb-2">
                  <p v-html="note.description"></p>
                </DisclosurePanel>
              </template>
            </Disclosure>
          </div>
        </div>
    
      </div>
  </div>

  </div>
</main>
</div>
</template>

<script setup>
  import { ref } from 'vue'
  import { AdjustmentsVerticalIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  import HeaderComponent from '../components/HeaderComponent';
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'



</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
      totalTodoRecords() {
        let total = 0;
        if (this.activitiesTodo) {
          if (this.activitiesTodo.questionnaire_instances) {
            total += this.activitiesTodo.questionnaire_instances.length;
          }
          if (this.activitiesTodo.reading_instances) {
            total += this.activitiesTodo.reading_instances.length;
          }
          if (this.activitiesTodo.worksheet_instances) {
            total += this.activitiesTodo.worksheet_instances.length;
          }
        }
        return total;
      },
      totalArchiveRecords() {
        let total = 0;
        if (this.activitiesArchive) {
          if (this.activitiesArchive.questionnaire_instances) {
            total += this.activitiesArchive.questionnaire_instances.length;
          }
          if (this.activitiesArchive.reading_instances) {
            total += this.activitiesArchive.reading_instances.length;
          }
          if (this.activitiesArchive.worksheet_instances) {
            total += this.activitiesArchive.worksheet_instances.length;
          }
        }
        return total;
      },
      reversedNotes() {
      // Return a reversed copy of the notes array
      return this.client.note ? this.client.note.slice().reverse() : [];
    }
    },

    watch: {
      activitiesTodoFilters() {
        this.getClientInfo();
      },
      activitiesArchiveFilters() {
        this.getClientInfo();
      },
    },

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      toggleMenu(type) {
        if (type == 'todo') {
          this.showToDoMenu = !this.showToDoMenu;
        } else {
          this.showArchiveMenu = !this.showArchiveMenu;
        }
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>
