<template>
  <div class="mental-health-mot-page">

<!-- SECTION-Hero -->
<section class="relative bg-gradient-to-r from-secondary-600 to-secondary-500" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div class="px-6 pb-4 pt-16 sm:pb-0 lg:col-span-7 lg:px-0 lg:pb-24 lg:pt-20 xl:col-span-6">
                <div class="mx-auto max-w-2xl lg:mx-0">
                    <h1 class="text-center sm:text-left mt-4 sm:mt-8 text-4xl font-bold  text-white sm:text-5xl">My Mental Wellbeing MOT</h1>
                    <p class="text-center sm:text-left mt-4 text-lg text-white sm:text-xl">The Youlio Wellbeing MOT consists of a series of activities, questionnaires followed by a 90-minute 1:1 session focused on giving you a clear snapshot of your mental health and strategies to improve it.</p>
                    <div class="mt-8 sm:mt-10 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <!-- Button1 -->
          <a href="/register/overview" class="w-56 mx-auto sm:mx-0 text-center rounded-full bg-primary px-4 py-3 text-lg font-medium tracking-wide text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
            Get Started  
          </a>
        </div>
          </div>
            </div>
            <div class="pb-8 sm:pt-12 relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                <div class="py-8 px-8  relative w-full h-0" style="padding-bottom:50%;">
                    <img class="sm:px-16 absolute inset-0 w-full h-full object-contain object-center" src="/img/website/hero-weekly.png" alt="" />
                </div>
            </div>
        </div>
    </section>

<!-- SECTION-Benefits the Wellbeing MOT -->
    <BlockWhyChoose
      overline="Personalised Support"
      headline="Benefits of the Mental Wellbeing MOT"
      subheadline="The Wellbeing MOT provides a comprehensive check-up to get a clear picture of your mental health, offering insights and action steps to boost your overall well-being."
      listImageSrc="/img/website/mot-benefits.jpg"
      listImageAlt="Why Choose Us"
      :listItems="[
        { title: 'In-Depth Assessment', description: 'Gain a comprehensive understanding of your current mental health and what’s affecting it.' },
        { title: 'Personalised Action Plan', description: 'Leave with practical, actionable steps to start improving your well-being right away.' },
        { title: 'Deep Dive Session', description: 'Benefit from an in-depth therapy session with me to explore your concerns more deeply.' },
      ]"
    />
      <Wave colorClass="fill-white" shadow position="down" /> 
<!-- SECTION-How Youlio Supports Your Journey -->
      <BlockSwiper
        :slides="3"
        overline="What To Expect"
        headline="How Your Journey Will Look"
        subheadline="Your Mental Wellbeing MOT is a tailored journey, guiding you through assessment, deep insights, and actionable steps to enhance your mental health."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="Discover"
        subheadline="In the Discover phase, we begin by gathering a comprehensive snapshot of your current mental health. This involves a series of thoughtfully designed readings, questionnaires, and exercises, all delivered through the Youlio platform."
        imageSrc="/img/website/mot-discover.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Understand"
        subheadline="The Understand phase is a 90-minute 1:1 deep dive session with me. Together, we will explore the meaning behind our findings, discussing what they reveal about your current situation and how they align with your personal goals."
        imageSrc="/img/website/mot-understand.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Thrive"
        subheadline="In the Thrive phase, we focus on translating our insights into actionable strategies and practical changes. I will provide you with immediate, actionable strategies, coping methods, and lifestyle adjustments that you can integrate into your daily life."
        imageSrc="/img/website/mot-thrive.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template> 
      </BlockSwiper>
<!-- SECTION-Testimonials -->
      <BlockTestimonial
      :testimonials="[
    {
      text: 'I loved the mental health MOT. Instant results, Tom managed to highlight what I need to change in an easy to understand way. A must try for anyone wanting to take charge of their mental health!',
      name: 'James',
      age: 34
    },
    {
      text: 'The mental health MOT was a game-changer for me. Quick, insightful results and it encouraged me to do something about my mental health now and not ignore it. Highly recommend!',
      name: 'Sarah',
      age: 29
    },
    {
      text: 'I took this after being recommended it by a friend, the session was really helpful and Tom made sure I went away knowing what I needed to put into action. Thank you!',
      name: 'Rashida',
      age: 42
    }
  ]"

  sectionBgColor="bg-primary-600"
      />
<!-- SECTION-Getting Started -->
<BlockGettingStarted
        title="Getting Started"
        bgClass="bg-white"
        :steps="[
          { icon: UserIcon, title: 'Step 1: Create a Free Youlio Account', description: 'Get started by creating your free Youlio account. This will allow you to book a free consultation and meet me.' },
          { icon: CalendarIcon, title: 'Step 2: Book a Consultation', description: 'Schedule a consultation to discuss your needs and expectations. We’ll explore how I can best support your mental health journey.' },
          { icon: RocketLaunchIcon, title: 'Step 3: Start Your MOT', description: 'Begin your MOT with a series of readings and questionnaires on the Youlio platform before booking in your 90-minute deep dive therapy session with me.' }
        ]"
      />
<!-- SECTION-Pricing -->
<BlockPricingMOT />    

<!-- SECTION-CTA -->
    <BlockCTA
      heading="Ready to Transform Your Wellbeing?"
      message="Book Your Wellbeing MOT Now and gain the insights and tools you need to make meaningful changes in your life."
      primaryButtonText="Get Started"
      primaryButtonLink="/register/overview"
      secondaryButtonText="Contact Me"
      secondaryButtonLink="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Wellbeing%20MOT"
      bgImage="/path/to/background-image.jpg"
      bgClass="bg-white"
      imageSrc="/img/website/mot-cta.jpg"
      theme="light"
    />

  </div>
</template>

<script>
export default {
  name: "MentalHealthMOTPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockPricingMOT from '../../website/components/BlockPricingMOT' ;
import BlockWhyChoose from '../../website/components/BlockWhyChoose' ;
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper';
import BlockContent from '../../website/components/BlockContent' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockGettingStarted from '../../website/components/BlockGettingStarted' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { RocketLaunchIcon, UserIcon, CheckIcon, CalendarIcon, ClipboardIcon, KeyIcon } from '@heroicons/vue/24/outline' 

const includedFeatures = [
  'Private forum access',
  'Member resources',
  'Entry to annual conference',
  'Official member t-shirt',
]

</script>
