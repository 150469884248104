<template>
 <HeaderComponent :pageTitle="client?.name || 'Client'" />
    <div class="overflow-x-hidden">
      <main class="layout-clientview px-4" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

    
        <div class="pt-4 w-full text-secondary-600 text-lg text-left font-semibold">
          <h3>Activities</h3>
        </div>
        <div>
          <div class="mt-4">
            <!-- To Do Section -->
            <div class="mb-2 flex justify-between">
              <div class="flex items-center text-page-subtitle-dark">To Do</div>
              <div v-if="totalTodoRecords || activitiesTodoFilters.length !== activityTypes.length" class="flex relative">
                <adjustments-vertical-icon class="filter" @click="toggleMenu('todo')" />
                <div v-show="showToDoMenu"
                  class="absolute right-3 top-4 mt-2 w-48 py-2 bg-white rounded-lg rounded-tr-none shadow-xl z-10">
                  <div v-for="todoType in activityTypes" :key="todoType"
                    class="block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white">
                    {{ capitalizeFirstLetter(todoType) }}
                    <input type="checkbox" :value="todoType" v-model="activitiesTodoFilters"
                      class="mt-1 text-primary float-right focus:ring-transparent" />
                  </div>
                </div>
              </div>
            </div>
            <activity-card v-if="totalTodoRecords" :activities="activitiesTodo" :is-interactive="false"
              class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8" />
            <div v-else class="card-no-entries">
              <div v-if="activitiesTodoFilters.length == activityTypes.length">
                <p>There are currently no 'Activities' in ToDo list.</p>
              </div>
              <div v-else>
                <p class="py-8 px-12 md:px-8 text-center text-body-base">
                  Oops! It seems your search didn't match any 'Activities' in ToDo list.
                </p>
              </div>
            </div>

            <!-- Archive Section -->
            <div class="mt-12 flex justify-between mb-2">
              <div class="flex items-center text-page-subtitle-dark">Archive</div>
              <div v-if="totalArchiveRecords || activitiesArchiveFilters.length !== activityTypes.length"
                class="flex relative">
                <adjustments-vertical-icon class="filter" @click="toggleMenu('archive')" />
                <div v-show="showArchiveMenu"
                  class="absolute right-3 top-4 mt-2 w-48 py-2 bg-white rounded-lg rounded-tr-none shadow-xl z-10">
                  <div v-for="archiveType in activityTypes" :key="archiveType"
                    class="block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white">
                    {{ capitalizeFirstLetter(archiveType) }}
                    <input type="checkbox" :value="archiveType" v-model="activitiesArchiveFilters"
                      class="mt-1 text-primary float-right focus:ring-transparent" />
                  </div>
                </div>
              </div>
            </div>
            <activity-card v-if="totalArchiveRecords" :activities="activitiesArchive" :is-interactive="false"
              class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8" />
            <div v-else class="card-no-entries">
              <div v-if="activitiesArchiveFilters.length == activityTypes.length">
                <p>There are currently no 'Activities' in Archive.</p>
              </div>
              <div v-else>
                <p class="py-8 px-12 md:px-8 text-center text-body-base">
                  Oops! It seems your search didn't match any 'Activities' in Archive.
                </p>
              </div>
            </div>
          </div>
        </div>
    
</main>
</div>
</template>

<script setup>
  import { ref } from 'vue'
  import { AdjustmentsVerticalIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  import ActivityCard from '../components/ActivityCard';
  import HeaderComponent from '../components/HeaderComponent';
</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
      totalTodoRecords() {
        let total = 0;
        if (this.activitiesTodo) {
          if (this.activitiesTodo.questionnaire_instances) {
            total += this.activitiesTodo.questionnaire_instances.length;
          }
          if (this.activitiesTodo.reading_instances) {
            total += this.activitiesTodo.reading_instances.length;
          }
          if (this.activitiesTodo.worksheet_instances) {
            total += this.activitiesTodo.worksheet_instances.length;
          }
        }
        return total;
      },
      totalArchiveRecords() {
        let total = 0;
        if (this.activitiesArchive) {
          if (this.activitiesArchive.questionnaire_instances) {
            total += this.activitiesArchive.questionnaire_instances.length;
          }
          if (this.activitiesArchive.reading_instances) {
            total += this.activitiesArchive.reading_instances.length;
          }
          if (this.activitiesArchive.worksheet_instances) {
            total += this.activitiesArchive.worksheet_instances.length;
          }
        }
        return total;
      },
      reversedNotes() {
      // Return a reversed copy of the notes array
      return this.client.note ? this.client.note.slice().reverse() : [];
    }
    },

    watch: {
      activitiesTodoFilters() {
        this.getClientInfo();
      },
      activitiesArchiveFilters() {
        this.getClientInfo();
      },
    },

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      toggleMenu(type) {
        if (type == 'todo') {
          this.showToDoMenu = !this.showToDoMenu;
        } else {
          this.showArchiveMenu = !this.showArchiveMenu;
        }
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>
