
import axios from "axios";

export const state = {
  spotlights: [],
  spotlight: {},
  
};

export const mutations = {
  
  set_spotlights: (state, spotlights) => {
      state.spotlights = spotlights;
  },
  set_spotlight: (state, spotlight) => {
    state.spotlight = spotlight;
},
  
};
export const actions = {
  
  async fetchSpotlights({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/spotlights"
      );
      commit("set_spotlights", response.data.spotlights);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchSpotlight({ commit }) {
    try {
      let id = window.location.pathname.replace('/spotlights/','');
      const response = await axios.get(
        "/api/me/spotlight/" + id
      );
      commit("set_spotlight", response.data.spotlight);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  
  async updateSpotlight({ commit }, payload) {
    try {
      let id = window.location.pathname.replace("/spotlights/", "");
      const response = await axios.put("/api/me/spotlight/" + id, payload);
      commit("set_spotlight", response.data.spotlight);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
};
  

export const getters = {
  
  ['getSpotlights'](state) {
      return state.spotlights;
  },
  
  ['getSpotlight'](state) {
    return state.spotlight;
  },

  ['addSpotlight'](state) {
  return state.spotlight;
  },

  ['updateSpotlight'](state) {
  return state.spotlight;
  },
  
};

export default {
  state,
  actions,
  mutations,
  getters
};
