<!-- SectionHeader.vue -->
<template>
  <div class="bg-none pb-1 border-b border-gray-100">
    <h2 class="text-lg font-semibold text-gray-800">{{ title }}</h2>
    <div v-if="subtitle" class="text-gray-600">{{ subtitle }}</div>
    <div v-if="$slots.actions" class="mt-2">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>

