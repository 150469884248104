<template>

  <!-- Title -->  
  <div class="py-8 px-8"> 
          <h1 class="ws-text-overline block text-center">
            Introducing
          </h1>
          <h1 class="ws-text-heading-2-black mt-2 text-center mx-auto md:max-w-lg">
            Low Self-esteem     
          </h1>
        </div> 
  <!-- Title END -->
  
  <div class="py-4 px-6 mx-auto sm:max-w-xl">

 

<!-- What is depression -->
        <h1 class="ws-text-display-base  block text-left">
          What is low self-esteem?
          </h1>
          <br />
        <p class="ws-text-body-sm  ">
          Self-esteem is the opinion that we have of ourselves. When our self-esteem is low we can believe we are not worthy of love, happiness or often any positive attributes whatsoever. Feeling this way makes our day to day functioning incredibly difficult and taking on life’s challenges can feel completely hopeless.        
        </p>
        <br />
        <p class="ws-text-body-sm ">
          Low self-esteem is something that can fluctuate with life but if it feels constant and pervasive it could be something that needs addressing.        
        </p>  
        <br />    
<!-- What is depression END -->

<!-- What are the effects of depression? -->
        <h1 class="ws-text-display-base  block text-left">
          What are the effects of low self-esteem?
          </h1>
          <br />
        <p class=" ws-text-body-sm">
          When our self-esteem is low we tend to withdraw from others. We might stop socialising or trying new things which in the short term feels like a helpful solution. Doing this, however, is simply a sticking plaster and over time this can make the problem much worse by reinforcing all the negative thoughts and behaviours. We may also take on unhelpful habits like smoking or drinking as a way of coping with the negative feelings.       
        </p>  
        <br />  
<!-- What are the effects of depression? END -->

<!-- Image -->  
        <figure>
          <img class="w-full rounded-lg" src="/img/website/common_problems/depression.jpg" alt="Decorative image" width="1310" height="873" />
        </figure>
        <br />
<!-- Image END -->

<!-- Causes Low Self-esteem -->
        <h1 class="ws-text-display-base  block text-left">
          What causes low self-esteem?
        </h1>
        <br />
          <p class=" ws-text-body-sm">
            As with many psychological problems, low self-esteem can arise from a number of causes including:         
        </p>  
        <br />
        
        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-4">
          <li>Traumatic life experiences such as abuse or bullying.</li>
          <li>Prejudice and discrimination.</li>
          <li>Worries about appearance and body image.</li>
          <li>Relationship problems.</li>
          <li>Mental health problems.</li>
          <li>Physical health.</li>
          <li>Genetics.</li>
          <li>Brain chemistry.</li>
          <li>Societal ideals.</li>
          <li>Stress.</li>
          
        </ul>
        <br />

<!-- Causes Low Self-esteem END -->

<!-- Diagnosing low self-esteem -->
<h1 class="ws-text-display-base  block text-left">
  How do you diagnose low self-esteem? 
        </h1>
        <br />
        <p class=" ws-text-body-sm">
          The DSM-5 does not diagnose self-esteem directly but that doesn’t take away its seriousness. It fixates our life with a negative lens and can often develop into other mental health problems like depression and anxiety disorders. A general negative view of oneself is something we should all look out for and when the negatives far outweigh the positives, perhaps it’s time to seek help.        
        </p>  
        <br />
        
    
<!-- Diagnosing low self-esteem END -->

<!-- Treating low self-esteem -->
        <h1 class="ws-text-display-base  block text-left">
          How do we treat low self-esteem?  
        </h1>
        <br />
        <p class=" ws-text-body-sm">
          Treating low self-esteem is often about restoring the balance within our belief system. Negative beliefs are identified and challenged, making way for more accurate beliefs. Helping the mind to have a more balanced view of ourselves begins the step towards valuing ourselves more.        
        </p> 
        <br /> 
        <p class=" ws-text-body-sm">
          It is important to note that turning every negative belief into a positive one is not the aim but instead to realign the overall balance. People with low self-esteem naturally only see one side of most situations and that will be the negative side.
        </p>
        <br />
        <br />
    
<!-- Treating low self-esteem END -->
    

  </div>
</template>

<script setup>

</script>
