<template>
  <section :class="['py-16 sm:py-32 px-6', bgClass]">
    <div class="container mx-auto" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
      <h2 class="ws-text-heading-md text-center mb-8">{{ title }}</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div v-for="(step, index) in limitedSteps" :key="index" class="text-center">
          <div class="mb-4">
            <!-- Use dynamic component rendering -->
            <component :is="step.icon" class="w-10 h-10 text-primary mx-auto" />
          </div>
          <h3 class="ws-text-heading-xs mb-4">{{ step.title }}</h3>
          <p class="ws-text-body-base text-gray-900 text-opacity-75">{{ step.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Getting Started'
  },
  steps: {
    type: Array,
    required: true,
    validator: value => value.length === 3, // Ensure exactly 3 steps are provided
  },
  bgClass: {
    type: String,
    default: 'bg-white'
  }
});

const limitedSteps = computed(() => props.steps.slice(0, 3)); // Ensure only the first 3 steps are used
</script>
