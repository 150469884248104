<template>
  <section
    :class="[
      paddingTopClass,
      paddingBottomClass,
      'cta-section px-6 text-center',
      bgClass,
      theme === 'dark' ? 'text-white' : 'text-gray-900',
      'relative'
    ]"
    :style="bgImage ? { backgroundImage: 'url(' + bgImage + ')', backgroundSize: 'cover', backgroundPosition: 'center' } : {}"
  >
    <div class="container mx-auto max-w-xl" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
      <h2
        :class="theme === 'dark' ? 'text-white' : 'text-gray-900'"
        class="ws-text-heading-md mb-8"
      >
        {{ heading }}
      </h2>
      <p
        :class="[theme === 'dark' ? 'text-white' : 'text-gray-900', 'opacity-75']"
        class="ws-text-body-base mb-6"
      >
        {{ message }}
      </p>
      <div class="flex flex-col sm:flex-row justify-center gap-4 mb-8 mx-12 sm:mx-0">
        <button v-if="primaryButtonText" @click="handlePrimaryClick" class="ws-cta-primary">
          {{ primaryButtonText }}
        </button>
        <button v-if="secondaryButtonText" @click="handleSecondaryClick" class="ws-cta-secondary">
          {{ secondaryButtonText }}
        </button>
      </div>
      <!-- Optional Image Section -->
      <div v-if="imageSrc" class="mt-8">
        <img :src="imageSrc" alt="CTA Image" class="w-full max-w-2xl mx-auto" />
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'BlockCTA',
  props: {
    heading: {
      type: String,
      default: 'Ready to Take the Next Step?'
    },
    message: {
      type: String,
      default: 'Contact me today to learn more about our services and find the best option for you.'
    },
    primaryButtonText: {
      type: String,
      default: 'Get in Touch'
    },
    primaryButtonLink: {
      type: String,
      default: '#'
    },
    secondaryButtonText: {
      type: String,
      default: ''
    },
    secondaryButtonLink: {
      type: String,
      default: ''
    },
    bgImage: {
      type: String,
      default: ''
    },
    bgClass: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'light' // Default to light theme
    },
    imageSrc: {
      type: String,
      default: ''
    },
    paddingTopClass: {
      type: String,
      default: 'pt-16 sm:pt-20 md:pt-28'
    },
    paddingBottomClass: {
      type: String,
      default: 'pb-16 sm:pb-16 md:pb-28'
    }
  },
  methods: {
    handlePrimaryClick() {
      if (this.primaryButtonLink) {
        window.location.href = this.primaryButtonLink;
      }
    },
    handleSecondaryClick() {
      if (this.secondaryButtonLink) {
        window.location.href = this.secondaryButtonLink;
      }
    }
  }
}
</script>

<style scoped>
.cta-section {
  position: relative;
  background-size: cover;
  background-position: center;
}
</style>
