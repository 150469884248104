<template>
  <HeaderComponent :pageTitle="'My Interfering Life Patterns'" />
  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
 
      <!-- Grouped Schema Cards -->
      <div v-if="sortedCategories.length > 0">
        <!-- Loop through each sorted category and its schemas -->
        <div v-for="category in sortedCategories" :key="category" class="mb-8">
          <!-- Category Title -->
          <h2 class="py-4 text-base md:text-base font-medium text-secondary-600 text-left">
            These are my {{ category }} interfering life patterns:
          </h2>
          
          <!-- Loop through schemas in this category -->
          <div v-for="lifetrap in groupedSchemas[category]" :key="lifetrap.id" class="mb-4">
            <router-link :to="{ name: 'LifePatternView', params: { id: lifetrap.id } }" class="rounded-lg flex items-center justify-between bg-gradient-to-r from-primary-200 to-secondary-200 px-3 py-6 text-base font-semibold text-secondary-600">
              <div class="flex items-center">
                <BeakerIcon class="modal-icon" />
                <p class="ml-2">{{ lifetrap.lifetrap }}</p>
              </div>
              <ChevronRightIcon class="h-6 w-6 text-secondary-600" />
            </router-link>
          </div>
        </div>
      </div>

      <!-- No Entry Card -->
      <div v-else>
        <p class="cf-tp-no-entries-title">No current entries</p>
        <p class="cf-tp-no-entries-body">You and your therapist will add your 'Unhelpful Schemas' during treatment.</p>
      </div>

    </main>
  </div>
</template>

<script setup>
  import { ChevronRightIcon, BeakerIcon } from '@heroicons/vue/24/outline';
  import HeaderComponent from '../components/HeaderComponent';
</script>

<script>
export default {
  props: {
    lifetraps: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    // Group schemas by category
    groupedSchemas() {
      return this.getLifetraps.reduce((groups, lifetrap) => {
        if (!groups[lifetrap.category]) {
          groups[lifetrap.category] = [];
        }
        groups[lifetrap.category].push(lifetrap);
        return groups;
      }, {});
    },

    // Sorted category names (alphabetical order)
    sortedCategories() {
      return Object.keys(this.groupedSchemas).sort();
    },

    getLifetraps() {
      if (!this.onClientPage) {
        return this.$store.getters.getLifetraps;
      }
      return this.lifetraps;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchLifetraps");
    }
  }
};
</script>
