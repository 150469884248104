<template>
  <form ref="passwordUpdateForm" @submit.prevent="updatePassword" class="space-y-6">
    <div class="pt-4">
      <label class="block text-display-sm" for="current_password">
        Current Password
      </label>
      <input v-model="data.current_password" class="text-body-base p-2 rounded-md bg-white border border-gray-300 block mt-1 w-full" type="password" id="current_password" name="current_password" required="required" autocomplete="current-password">
    </div>
    <div>
      <label class="block text-display-sm" for="password">
        New Password
      </label>
      <input v-model="data.password" class="text-body-base p-2 rounded-md bg-white border border-gray-300 block mt-1 w-full" type="password" id="password" name="password" required="required" autocomplete="new-password">
    </div>
    <div>
      <label class="block text-display-sm" for="password_confirmation">
        Password Confirmation
      </label>
      <input v-model="data.password_confirmation" class="text-body-base p-2 rounded-md bg-white border border-gray-300 block mt-1 w-full" type="password" id="password_confirmation" name="password_confirmation" required="required" autocomplete="new-password">
    </div>
    <div v-if="errors" class="text-red-500 py-2 font-semibold">
      <span>{{ errors.message }}</span>
    </div>

    <div v-if="passwordUpdated" class="alert-green">
      Password updated successfully!
    </div>

    <div class="flex items-center">
      <button type="submit" class="mt-4 button-secondary">
        Update
      </button>
    </div>
  </form>
</template>

<script>

  export default {
    data() {
      return {
        passwordUpdated: false,
        errors: null,
        data: {
          current_password: null,
          password: null,
          password_confirmation: null,
        }
      }
    },
    methods: {
      updatePassword() {
        axios.put('/user/password', this.data)
          .then((response) => {
            if(response.status !== 200) return
            this.$store.dispatch('attempt_user')
            this.$refs.passwordUpdateForm.reset()
            this.errors = null
            this.passwordUpdated = true; // Set passwordUpdated to true
            setTimeout(() => {
        this.passwordUpdated = false;
      }, 3000); // 3000 milliseconds = 3 seconds
          })
          .catch((error) => {
            this.errors = error.response.data
          })
      },
    }
  }
</script>

