<template>
  <HeaderComponent :pageTitle="'Feelings Finder'" />

  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

      <h2 class="py-4 text-center text-secondary-600 font-medium text-lg">Choose a Category</h2>

  <div class="grid grid-cols-2 md:grid-cols-3 gap-4 p-4">
    <div 
      v-for="category in categories" 
      :key="category" 
      @click="goToCategory(category)"
      class="bg-primary-200 text-secondary-600 p-6 rounded-md shadow-lg cursor-pointer"
    >
      <h2 class="text-lg font-semibold text-center">{{ category }}</h2>
    </div>
  </div>
</main>
</div>
</template>


<script setup>
import HeaderComponent from '../components/HeaderComponent';
</script>

<script>
export default {
  data() {
    return {
      categories: ['Anger', 'Happiness', 'Sadness', 'Fear', 'Surprise', 'Disgust'] // You can fetch this dynamically
    };
  },
  methods: {
    goToCategory(category) {
      this.$router.push({ name: 'FeelingsByCategory', params: { category } });
    }
  }
};
</script>

<style scoped>
.grid {
  display: grid;
  gap: 1.5rem;
}
</style>
