<template>
  <div class="min-h-screen bg-white overflow-hidden"> 
    <!-- Blur background -->
    <div class="min-h-screen relative isolate overflow-hidden">
      <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
        <div class="opacity-40 aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0]" style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
      </div>
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="opacity-20 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
      <!-- END background -->

      <Header />

      <!-- Card -->
      <div class="mx-6 sm:mx-auto md:min-w-xl mt-4 pt-4 md:pt-12 pb-12 px-6 max-w-2xl border border-gray-400/20 bg-white rounded-lg" data-aos="slide-left" data-aos-once="true" data-aos-duration="1000">

        <!-- Swiper -->
        <div class="swiper-container">
          <swiper :pagination="true" :navigation="true" :modules="modules" class="mySwiper" style="--swiper-pagination-color: #6597B8; --swiper-navigation-size: 24px;">
            <!-- Slide1 -->
            <swiper-slide class="pb-12">
              <div class="md:px-16 sm:pt-4 pb-4 flex flex-col items-center">
  <div class="my-auto mt-4 text-center">
    <p class="text-xl sm:text-3xl font-bold text-primary-600">Start Your Journey Towards Better Mental Health Today!</p>
  </div>
</div>

<div class="md:px-16">
  <p class="ws-text-body-base text-gray-700">“In just three simple steps you can begin your journey to a happier and healthier life. I look forward to being along for the ride.”</p>
</div>

<!-- Avatar, Name, and Position Section -->
<div class="md:px-16 flex items-center mt-6">
  <div class="flex-shrink-0">
    <img class="h-14 w-14 rounded-full" src="/img/avatar.jpg" alt="Youlio founder image" />
  </div>
  <div class="ml-4">
    <p class="text-base font-semibold text-gray-800">Tom Mitchell</p>
    <p class="text-sm font-medium text-primary-600">MSc PGDip MBACP GMBPsS</p>
  </div>
</div>
            </swiper-slide>

            <!-- Slide2 -->
            <swiper-slide class="pb-12">
              <div class="md:px-16 pt-4 pb-8 flex flex-col items-center">
                <div class="inline-block h-14 sm:h-16 w-auto">
                  <img class="h-14 sm:h-16" src="/img/website/landing-sign-up.jpg" alt="Youlio founder image" />
                </div>
                <div class="my-auto mt-4 text-center">
                  <p class="text-xl font-bold text-gray-700">Step 1: Create a Free Youlio Account</p>
                </div>
              </div>
              <div class="md:px-16">
                <p class="text-center ws-text-body-base text-gray-700">Get started by creating your free Youlio account. You can choose to take a quick mental health questionnaire during the process.</p>
              </div>
            </swiper-slide>

            <!-- Slide3 -->
            <swiper-slide class="pb-12">
              <div class="md:px-16 pt-4 pb-8 flex flex-col items-center">
                <div class="inline-block h-14 sm:h-16 w-auto">
                  <img class="h-14 sm:h-16" src="/img/website/landing-book.jpg" alt="Youlio founder image" />
                </div>
                <div class="my-auto mt-4 text-center">
                  <p class="text-xl font-bold text-gray-700">Step 2: Book a Free Consultation</p>
                </div>
              </div>
              <div class="md:px-16">
                <p class="text-center ws-text-body-base text-gray-700">Schedule a 20 minute consultation to discuss your needs, expectations and the options that might be best for you.</p>
              </div>
            </swiper-slide>

            <!-- Slide4 -->
            <swiper-slide class="pb-12">
              <div class="md:px-16 pt-4 pb-8 flex flex-col items-center">
                <div class="inline-block h-14 sm:h-16 w-auto">
                  <img class="h-14 sm:h-16" src="/img/website/landing-meet.jpg" alt="Youlio founder image" />
                </div>
                <div class="my-auto mt-4 text-center">
                  <p class="text-xl font-bold text-gray-700">Step 3: Begin Your Therapy Journey</p>
                </div>
              </div>
              <div class="md:px-16">
                <p class="text-center ws-text-body-base text-gray-700">Start your personalised therapy journey and begin working towards your mental health goals.</p>
              </div>
            </swiper-slide>
            <!-- END Swiper -->
          </swiper>
        </div>
      </div>
        <!-- CTA -->
        <div class="px-6 pt-4 pb-4 mx-auto text-center">
          <button @click="openModal" class="ws-cta-primary w-full sm:max-w-sm">
            Let's Go
          </button>
        </div>

      
  <!-- Modal Transition -->
  <transition name="fade">
    <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
      <div class="absolute inset-0 bg-black opacity-80" @click="closeModal"></div>
      <div class="relative w-11/12 max-w-md bg-white rounded-lg py-12 px-6 sm:px-10 z-10">
        <h2 class="text-lg text-center font-bold mb-12">Do you want to take the Youlio mental health questionnaire?</h2>
        <!-- Stacked Buttons with Text Underneath -->
        <div class="flex flex-col space-y-6">
          <div class="flex flex-col items-center">
            <button @click="proceedWithQuestionnaire" class="w-32 bg-primary text-white py-2 rounded">Yes</button>
            <span class="text-gray-600 text-sm mt-1 text-center">(It only takes a few minutes and you'll see your results straight away on your dashboard.)</span>
          </div>
          <div class="flex flex-col items-center">
            <button @click="proceedWithoutQuestionnaire" class="w-32 bg-secondary-800 text-white py-2 rounded">No</button>
            <span class="text-gray-600 text-sm mt-1 text-center">(If you want to skip the questionnaire you can still take it at a later stage.)</span>
          </div>
        </div>
      </div>
      <!-- Close Button Above the Modal -->
      <button class="absolute top-2 right-2 text-gray-300 hover:text-gray-900 z-20" @click="closeModal">
        <XMarkIcon class="h-6 w-6" />
      </button>
    </div>
  </transition>

      </div>
    </div>
 
</template>

<script setup>
import { onMounted, ref } from 'vue'
import Header from '../../website/components/Header';
import { useRouter } from 'vue-router';
import { XMarkIcon } from '@heroicons/vue/24/outline'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';

// Import required modules
import { Pagination, Navigation } from "swiper";

const router = useRouter();

const isModalOpen = ref(false);

function openModal() {
  isModalOpen.value = true;
}

function closeModal() {
  isModalOpen.value = false;
}

function proceedWithQuestionnaire() {
  sessionStorage.setItem('register_type', 'mot'); // Set type for assessment
  closeModal();
  router.push('/register/start'); // Navigate to the sign-up page
}

function proceedWithoutQuestionnaire() {
  sessionStorage.setItem('register_type', 'consult'); // Set type for consultation
  closeModal();
  router.push('/register/start'); // Navigate to the sign-up page
}

// Swiper modules
const modules = [Pagination, Navigation];

// You can set register_type based on the page that loads it
onMounted(() => {
  if (sessionStorage.getItem('register_type') === null) {
    sessionStorage.setItem('register_type', 'default'); // Set a default value if none exists
  }
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease; /* Duration and easing for transition */
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0; /* Start with opacity 0 for fade effect */
}
.fade-enter-to {
  opacity: 1; /* Final state for fade-in */
}
</style>