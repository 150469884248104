<template>
  <HeaderComponent :pageTitle="'My Interfering Life Patterns'" />
  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div>
        <div v-if="lifetrap" class="bg-white shadow-xl shadow-gray-300/10 rounded-md overflow-hidden">
          <!-- Lifetrap Title -->
          <h1 class="flex bg-gradient-to-r from-primary-200 to-secondary-200 px-4 py-6 text-base font-semibold text-secondary-600">
            {{ lifetrap.lifetrap }}
          </h1>
          
          <!-- Lifetrap Examples -->
          <div v-if="lifetrapExamples.length > 0" class="">
            <ul>
              <li v-for="example in lifetrapExamples" :key="example.id" class="mx-4 py-6 border-b">
                <h2 class="font-semibold text-secondary-600 text-base">{{ example.title }}</h2>
                <p class="pt-2 text-base text-gray-500 font-normal">{{ example.description }}</p>
              </li>
            </ul>
          </div>
          
          <!-- No Examples Message -->
          <div v-else>
            <p class="text-center font-normal text-gray-500 text-base py-12 px-16">We will enter information about this pattern throughout our journey</p>
          </div>

        </div>

 <!-- Leaving out Feelings and thoughts for the time being
      
          <h2 class="px-3 text-lg font-semibold text-secondary-600" v-if="lifetrapFeelings.length > 0">Feelings</h2>
          <ul v-if="lifetrapFeelings.length > 0">
            <li v-for="feeling in lifetrapFeelings" :key="feeling.id" class="px-3 py-2 border-b">
              I feel <span class="font-medium text-secondary-600">{{ feeling.feeling }}</span>
            </li>
          </ul>

          <div v-else>
            <p class="cf-tp-no-entries-title">No feelings entered yet</p>
          </div>
     
          <h2 class="px-3 text-lg font-semibold text-secondary-600" v-if="lifetrapThoughts.length > 0">Thoughts</h2>
          <ul v-if="lifetrapThoughts.length > 0">
            <li v-for="thought in lifetrapThoughts" :key="thought.id" class="px-3 py-2 border-b">
              I think <span class="font-medium text-secondary-600">{{ thought.thought }}</span>
            </li>
          </ul>
  
          <div v-else>
            <p class="cf-tp-no-entries-title">No thoughts entered yet</p>
          </div> -->
       

        <div v-else-if="loading">
          <p>Loading... Please wait</p>
        </div>
        <div v-else>
          <p>Error: Lifetrap not found.</p>
        </div>
      </div>
    </main>
  </div>
</template>


<script setup>
import HeaderComponent from '../components/HeaderComponent';
</script>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    lifetrap() {
      return this.$store.getters.getLifetrapById(this.id);
    },

    lifetrapExamples() {
      return this.$store.getters.getLifetrapExamples(this.id);
    },

    lifetrapFeelings() {
      return this.$store.getters.getLifetrapFeelings(this.id);
    },
    lifetrapThoughts() {
      return this.$store.getters.getLifetrapThoughts(this.id);
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        this.fetchLifetrap(newId);
        this.fetchLifetrapExamples(newId);
        this.fetchLifetrapFeelings(newId);
        this.fetchLifetrapThoughts(newId);
      }
    }
  },
  methods: {
    async fetchLifetrap(id) {
      this.loading = true;
      try {
        if (!this.lifetrap || this.lifetrap.id !== id) {
          await this.$store.dispatch('fetchLifetrap', id);
        }
      } catch (error) {
        console.error('Error fetching lifetrap', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchLifetrapExamples(id) {
      this.loading = true;
      try {
        await this.$store.dispatch('fetchLifetrapExamples', id);
      } catch (error) {
        console.error('Error fetching lifetrap examples', error);
      } finally {
        this.loading = false;
      }
    },
   
    async fetchLifetrapFeelings(id) {
  console.log(`Fetching lifetrap feelings for ID: ${id}`); // Debug log
  this.loading = true;
  try {
    await this.$store.dispatch('fetchLifetrapFeelings', id);
    console.log('Feelings fetched successfully'); // Debug log
  } catch (error) {
    console.error('Error fetching lifetrap feelings', error);
  } finally {
    this.loading = false;
  }
},
async fetchLifetrapThoughts(id) {
  console.log(`Fetching lifetrap thoughts for ID: ${id}`); // Debug log
  this.loading = true;
  try {
    await this.$store.dispatch('fetchLifetrapThoughts', id);
    console.log('Thoughts fetched successfully'); // Debug log
  } catch (error) {
    console.error('Error fetching lifetrap thoughts', error);
  } finally {
    this.loading = false;
  }
}
  },
  mounted() {
    this.fetchLifetrap(this.id);
    this.fetchLifetrapExamples(this.id);
    this.fetchLifetrapFeelings(this.id);
    this.fetchLifetrapThoughts(this.id);
  }
};
</script>
