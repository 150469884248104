
import axios from "axios";

export const state = {
  barriers: [],
  expectations: [],
  goalActions: [],
  goals: [],
  aims: [],
  copings: [],
  supports: []
};

export const mutations = {
  
  set_barriers: (state, barriers) => {
      state.barriers = barriers;
  },
  set_expectations: (state, expectations) => {
    state.expectations = expectations;
  },
  set_goalActions: (state, goalActions) => {
    state.goalActions = goalActions;
  },
  set_goals: (state, goals) => {
    state.goals = goals;
  },
  set_aims: (state, aims) => {
    state.aims = aims;
  },
  set_copings: (state, copings) => {
    state.copings = copings;
  },
  set_supports: (state, supports) => {
    state.supports = supports;
  },
  
};
export const actions = {
  
  async fetchBarriers({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/barriers"
      );
      commit("set_barriers", response.data.barriers);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  
  async fetchExpectations({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/expectations"
      );
      commit("set_expectations", response.data.expectations);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchGoalActions({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/goal-actions"
      );
      commit("set_goalActions", response.data.goalActions);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchGoals({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/goals"
      );
      commit("set_goals", response.data.goals);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchAims({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/aims"
      );
      commit("set_aims", response.data.aims);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchCopings({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/copings"
      );
      commit("set_copings", response.data.copings);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchCopings({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/supports"
      );
      commit("set_supports", response.data.supports);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },



};
export const getters = {
  
  ['getBarriers'](state) {
      return state.barriers;
  },
  ['getExpectations'](state) {
    return state.expectations;
  },
  ['getGoalActions'](state) {
    return state.goalActions;
  },
  ['getGoals'](state) {
    return state.goals;
  },
  ['getAims'](state) {
    return state.aims;
  },
  ['getCopings'](state) {
    return state.copings;
  },
  ['getSupports'](state) {
    return state.supports;
  },




};

export default {
  state,
  actions,
  mutations,
  getters
};
