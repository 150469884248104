<template>
  <HeaderComponent :pageTitle="'Feelings within ' + category" />

  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

  <div class="p-4">
    
    <h2 class="text-lg font-semibold mb-4">{{ category }} Feelings</h2>
    <ul class="list-disc list-inside">
      <li v-for="feeling in feelings" :key="feeling">{{ feeling }}</li>
    </ul>
  </div>
</main>
</div>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent';
</script>

<script>
export default {
  data() {
    return {
      category: this.$route.params.category,
      feelings: []
    };
  },
  created() {
    this.fetchFeelingsByCategory();
  },
  methods: {
    // Hardcoded feelings for each category
    fetchFeelingsByCategory() {
      const feelingsData = {
        Anger: ['Frustration', 'Rage', 'Irritation', 'Annoyance'],
        Happiness: ['Joy', 'Contentment', 'Excitement', 'Gratitude'],
        Sadness: ['Grief', 'Disappointment', 'Loneliness', 'Hopelessness'],
        Fear: ['Anxiety', 'Panic', 'Worry', 'Dread'],
        Surprise: ['Amazement', 'Astonishment', 'Shock', 'Disbelief'],
        Disgust: ['Revulsion', 'Contempt', 'Disdain', 'Nausea']
      };

      // Assign feelings based on the selected category
      this.feelings = feelingsData[this.category] || [];
    }
  }
};
</script>
