<template>

<!-- Title -->  
<div class="py-8 px-8"> 
        <h1 class="ws-text-overline text-primary block text-center">
          Introducing
        </h1>
        <h1 class="ws-text-heading-sm text-gray-800 mt-2 text-center mx-auto md:max-w-lg">
          Depression     
        </h1>
      </div> 
<!-- Title END -->

<div class="py-4 px-6 mx-auto sm:max-w-xl">

<!-- Quote -->
        <blockquote>
          <p class="ws-text-body-sm text-gray-700">
            "Depression is now the number 1 cause of disability in the world" - <span class="text-secondary-600 font-semibold">The World Health Organisation (WHO)</span>
          </p>
        </blockquote> 
        <br />
<!-- What is depression -->
        <h1 class="ws-text-body-base-bold text-gray-800 block text-left">
          What is depression?
          </h1>
          <br />
        <p class="ws-text-body-sm text-gray-700">
          Depression, also known as major depressive disorder or clinical depression can be classified as a mood disorder. The word itself can be casually thrown around but make no mistake, diagnosable depression is far more than a difficult day.
        </p>
        <br />
        <p class="ws-text-body-sm text-gray-700">
          The Diagnostic and Statistical Manual of Mental Disorders (DSM-5) defines it as a feeling of low mood that is continuous and lasts for at least 2 weeks.
        </p>      
        <br />

<!-- What are the effects of depression? -->
        <h1 class="ws-text-body-base-bold text-gray-800 block text-left">
          What are the effects of depression?
          </h1>
          <br />
        <p class="ws-text-body-sm text-gray-700">
          Its tentacles can slither through every part of a person’s life. It affects how we think, feel, behave and even our physiology like eating and sleeping. Life can feel harder, less worthwhile and at the extreme end, depression can make someone want to take their own life.        
        </p>    
        <br />
<!-- Image -->  
        <figure>
          <img class="w-full rounded-lg" src="/img/website/common_problems/depression.jpg" alt="Decorative image" width="1310" height="873" />
        </figure>
        <br />

<!-- Diagnosing Depression -->
        <h1 class="ws-text-body-base-bold text-gray-800 block text-left">
          Diagnosing depression
        </h1>
        <br />
          <p class="ws-text-body-sm">
          The DSM-5 outlines the following criterion to make a diagnosis of depression. The individual must be experiencing five or more symptoms during the same 2-week period and at least one of the symptoms should be either (1) depressed mood or (2) loss of interest or pleasure.        
          </p>  
        <br />
        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm text-gray-700 space-y-4">
          <li>Depressed mood most of the day, nearly every day.</li>
          <li>Markedly diminished interest or pleasure in all, or almost all, activities most of the day, nearly every day.</li>
          <li>Significant weight loss when not dieting or weight gain, or decrease or increase in appetite nearly every day.</li>
          <li>A slowing down of thought and a reduction of physical movement (observable by others, not merely subjective feelings of restlessness or being slowed down).</li>
          <li>Fatigue or loss of energy nearly every day.</li>
          <li>Feelings of worthlessness or excessive or inappropriate guilt nearly every day.</li>
          <li>Diminished ability to think or concentrate, or indecisiveness, nearly every day.</li>
          <li>Recurrent thoughts of death, recurrent suicidal ideation without a specific plan, or a suicide attempt or a specific plan for committing suicide.</li>
        </ul>
        <br />
        <p class="ws-text-body-sm text-gray-700">
        To receive a diagnosis of depression, these symptoms must cause the individual clinically significant distress or impairment in social, occupational, or other important areas of functioning. The symptoms must also not be a result of substance abuse or another medical condition.
        </p>  
        <br />
<!-- What causes depression -->
        <h1 class="ws-text-body-base-bold text-gray-800 block text-left">
          What causes depression?
        </h1>
        <br />
        <p class="ws-text-body-sm text-gray-700">
          One useful way to look at what causes depression is the biopsychosocial approach. This approach can be broken down into:       
        </p>  
        <br />
        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-4">
          <li>A biological component (genes, biochemistry, diseases, drugs)</li>
          <li>A psychological component (cognitive distortions, history, coping styles, attributional style)</li>
          <li>A sociological component (social disturbances, distress, cultural influences)</li>
        </ul>
        <br />
        <p class="ws-text-body-sm text-gray-700">
          Therapy deals mostly with the psychological component but can also help with the sociological component too.
        </p> 
        <br />
     
<!-- Key risk factors -->
<h1 class="ws-text-body-base-bold text-gray-800 block text-left">
          Key risk factors
        </h1>
        <br />
        <p class="ws-text-body-sm text-gray-700">
          Research has shown several areas that can play a strong part in not just the development of depression but also the continuation.
        </p>  
        <br />
        <ul role="list" class="marker:text-secondary-400 list-disc ws-text-body-sm space-y-4">
          <li><span class="font-bold">Our internal orientation</span> - people tend to use themselves as the reference point. This, however, can drive you inwards and create self-absorption especially during depressive episodes. During these episodes your own thinking is potentially dangerous to your well being.</li>
          <li><span class="font-bold">Ruminating about negative and past events</span> - this drives the symptoms of depression and anxiety. If you analyse but never take action it doesn’t help your situation. It’s important to remember that you are more than just your history.</li>
          <li><span class="font-bold">Attributional Style</span> - problems can often be far less about what actually happened and more about how we make sense and interpret it. The meaning we attach to things ultimately drive the feelings that we experience.</li>
          <li><span class="font-bold">Global Thinking</span> - if you think in global terms you see the forest but not the trees. Goals need to be defined and they need steps otherwise they are just wishful thinking. During depression everything can feel overwhelming and breaking the situation down is the only way to enable positive action.</li>
          <li><span class="font-bold">Unrealistic expectations</span> - expectations are the filter for how we judge everything and we don't necessarily know that we have unrealistic expectations. This is where evidence can help us to look at situations with a more accurate lens. By doing this we no longer set ourselves up for failure which in itself can spur on more action.</li>
        </ul>
        <br />
        <p class="ws-text-body-sm text-gray-700">
          Therapy deals mostly with the psychological component but can also help with the sociological component too.
        </p> 
        <br />
<!-- Individualised-->
        <h1 class="ws-text-body-base-bold text-gray-800 block text-left">
          Treatment should be individualised
        </h1>
        <br />
        <p class="ws-text-body-sm text-gray-700">
          The best way to tackle depression is the way that works for you. We create our own way in and maintain the problem through our own behaviour. This means ultimately we need to find our own way out and therapy can be a very effective method for doing so.
        </p> 
        <br /> 

</div>

</template>

<script setup>

</script>
