
import axios from "axios";

export const state = {
  behaviours: [],
  thoughts: [],
  feelings: [],
  problems: [],
  sensations: [],
  symptoms: [],
  characters: [],
  values: [],
  lifeslices: [],
  lifetraps: [],
  lifetrap: {},
  emotionalstates: [],
  emotionalstate: {},
  parentstyles: [],
  lifetrapExamples: [],// Add this line
  lifetrapFeelings: {},
  lifetrapThoughts: {},
  emotionalstateBehaviours: {}
};

export const mutations = {
  
  set_behaviours: (state, behaviours) => {
    state.behaviours = behaviours;
  },
  set_thoughts: (state, thoughts) => {
    state.thoughts = thoughts;
  },
  set_feelings: (state, feelings) => {
    state.feelings = feelings;
  },

  set_lifetrapFeelings(state, { lifetrapId, feelings }) {
    state.lifetrapFeelings[lifetrapId] = feelings;
  },
  set_lifetrapThoughts(state, { lifetrapId, thoughts }) {
    state.lifetrapThoughts[lifetrapId] = thoughts;
  },
  set_emotionalstateBehaviours(state, { emotionalstateId, behaviours }) {
    state.emotionalstateBehaviours[emotionalstateId] = behaviours;
  },

  set_problems: (state, problems) => {
    state.problems = problems;
  },
  set_sensations: (state, sensations) => {
    state.sensations = sensations;
  },
  set_symptoms: (state, symptoms) => {
    state.symptoms = symptoms;
  },



  set_characters: (state, characters) => {
    state.characters = characters;
  },

  set_values: (state, values) => {
    state.values = values;
  },

  set_lifeslices: (state, lifeslices) => {
    state.lifeslices = lifeslices;
  },

  set_lifetraps: (state, lifetraps) => {
    state.lifetraps = lifetraps;
  },

  set_lifetrap(state, lifetrap) {
    state.lifetrap = lifetrap;
  },

  set_lifetrapExamples(state, examples) {
    console.log('Setting examples:', examples); // Check data being set
    state.lifetrapExamples = examples;
  },

  set_emotionalstates: (state, emotionalstates) => {
    state.emotionalstates = emotionalstates;
  },

  set_emotionalstate: (state, emotionalstate) => {
    state.emotionalstate = emotionalstate;
  },

  set_parentstyles: (state, parentstyles) => {
    state.parentstyles = parentstyles;
  },

  
};
export const actions = {
  

  async fetchBehaviours({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/behaviours"
      );
      commit("set_behaviours", response.data.behaviours);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchThoughts({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/thoughts"
      );
      commit("set_thoughts", response.data.thoughts);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchFeelings({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/feelings"
      );
      commit("set_feelings", response.data.feelings);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchLifetrapFeelings({ commit }, lifetrapId) {
    try {
      const response = await axios.get(`/api/me/lifetraps/${lifetrapId}/feelings`);
      console.log('Fetched feelings:', response.data); // Add this line
      commit('set_lifetrapFeelings', { lifetrapId, feelings: response.data });
    } catch (error) {
      console.error('Error fetching feelings:', error);
    }
  },

  async fetchLifetrapThoughts({ commit }, lifetrapId) {
    try {
      const response = await axios.get(`/api/me/lifetraps/${lifetrapId}/thoughts`);
      console.log('Fetched thoughts:', response.data); // Add this line
      commit('set_lifetrapThoughts', { lifetrapId, thoughts: response.data });
    } catch (error) {
      console.error('Error fetching thoughts:', error);
    }
  },

  async fetchEmotionalstateBehaviours({ commit }, emotionalstateId) {
    try {
      const response = await axios.get(`/api/me/emotionalstates/${emotionalstateId}/behaviours`);
      console.log('Fetched behaviours:', response.data); // Add this line
      commit('set_emotionalstateBehaviours', { emotionalstateId, behaviours: response.data });
    } catch (error) {
      console.error('Error fetching behaviours:', error);
    }
  },

  async fetchProblems({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/problems"
      );
      commit("set_problems", response.data.problems);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchSensations({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/sensations"
      );
      commit("set_sensations", response.data.sensations);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },
  async fetchSymptoms({ commit }) {
    try {
      const response = await axios.get("/api/me/symptoms");
      commit("set_symptoms", response.data.symptoms);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 




  async fetchCharacters({ commit }) {
    try {
      const response = await axios.get("/api/me/characters");
      commit("set_characters", response.data.characters);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchValues({ commit }) {
    try {
      const response = await axios.get("/api/me/values");
      commit("set_values", response.data.values);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchLifeslices({ commit }) {
    try {
      const response = await axios.get("/api/me/lifeslices");
      commit("set_lifeslices", response.data.lifeslices);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchLifeslices({ commit }) {
    try {
      const response = await axios.get("/api/me/lifeslices");
      commit("set_lifeslices", response.data.lifeslices);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchLifetraps({ commit }) {
    try {
      const response = await axios.get("/api/me/lifetraps");
      commit("set_lifetraps", response.data.lifetraps);
    } catch (error) {
      console.error("Failed to fetch lifetraps", error);
    }
  },

  async fetchLifetrap({ commit }, id) {
    try {
      const response = await axios.get(`/api/me/lifetrap/${id}`);
      commit("set_lifetrap", response.data.lifetrap);
    } catch (error) {
      alert('Error fetching lifetrap data');
      console.log(error);
    }
  },

  async fetchLifetrapExamples({ commit }, id) {
    try {
      const response = await axios.get(`/api/lifetrap/${id}/lifetrap_examples`);
      console.log('Fetched examples:', response.data); // Check fetched data
      commit('set_lifetrapExamples', response.data);
    } catch (error) {
      console.error('Error fetching lifetrap examples:', error);
    }
  },

  async fetchEmotionalstates({ commit }) {
    try {
      const response = await axios.get("/api/me/emotionalstates");
      commit("set_emotionalstates", response.data.emotionalstates);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }, 

  async fetchEmotionalstate({ commit }, id) {
    try {
      const response = await axios.get(`/api/me/emotionalstate/${id}`);
      commit("set_emotionalstate", response.data.emotionalstate);
    } catch (error) {
      console.error("Failed to fetch emotionalstate", error);
    }
  },

  async fetchParentstyles({ commit }) {
    try {
      const response = await axios.get("/api/me/parentstyles");
      commit("set_parentstyles", response.data.parentstyles);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  } 




};
export const getters = {
  
  ['getBehaviours'](state) {
    return state.behaviours;
  },
  ['getThoughts'](state) {
    return state.thoughts;
  },
  ['getFeelings'](state) {
    return state.feelings;
  },

 getLifetrapFeelings: (state) => (lifetrapId) => {
    return state.lifetrapFeelings[lifetrapId] || [];
  },

  getLifetrapThoughts: (state) => (lifetrapId) => {
    return state.lifetrapThoughts[lifetrapId] || [];
  },

  getEmotionalstateBehaviours: (state) => (emotionalstateId) => {
    return state.emotionalstateBehaviours[emotionalstateId] || [];
  },

  ['getProblems'](state) {
    return state.problems;
  },
  ['getSensations'](state) {
    return state.sensations;
  },
  ['getSymptoms'](state) {
    return state.symptoms;
  },



  ['getCharacters'](state) {
    return state.characters;
  },
  ['getValues'](state) {
    return state.values;
  },
  ['getLifeslices'](state) {
    return state.lifeslices;
  },
  ['getLifetraps'](state) {
    return state.lifetraps;
  },


  getLifetrapById(state) {
    return (id) => state.lifetraps.find(lifetrap => lifetrap.id === id) || state.lifetrap;
  },


  getLifetrapExamples: (state) => (id) => {
    console.log('State:', state.lifetrapExamples);
    console.log('Fetching examples for id:', id);
    
    return state.lifetrapExamples.filter(example => example.lifetrap_id === parseInt(id));
  },

  ['getEmotionalstates'](state) {
    return state.emotionalstates;
  },

  getEmotionalstateById(state) {
    return (id) => state.emotionalstates.find(emotionalstate => emotionalstate.id === id) || state.emotionalstate;
  },

  ['getParentstyles'](state) {
    return state.parentstyles;
  }


};

export default {
  state,
  actions,
  mutations,
  getters
};
