<template>
  <HeaderComponent :pageTitle="'Goal Actions'" />

  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div v-if="goal && goal.actions.length > 0">
        <h2 class="text-lg font-semibold text-gray-700">Actions for {{ goal.goal }}</h2>


        <div v-for="(action, index) in goal.actions" :key="action.id" class="pt-12 mb-4 flex items-start">
          <div class="flex flex-col items-center mr-4">
            <p class="h-7 w-7 flex items-center justify-center rounded-full bg-secondary-300 text-xs font-semibold text-white">
              A{{ index + 1 }}
            </p>
          </div>
          <p class="text-base md:text-base font-normal text-gray-700">{{ action.goal_action }}</p>
        </div>
      </div>
      
      <div v-else>
        <p class="cf-tp-no-entries-title">No actions available for this goal.</p>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'; // Assuming you're using Vuex
import HeaderComponent from '../components/HeaderComponent';

const route = useRoute();
const store = useStore();
const goal = ref(null);
const goalActions = ref([]); // This will hold the actions for the selected goal

onMounted(async () => {
  const goalId = route.query.goalId;
  console.log('Goal ID from route:', goalId); // Debugging statement

  // Always fetch goal actions on mount
  await store.dispatch("fetchGoalActions");
  console.log('Goal Actions fetched:', store.getters.getGoalActions); // Debugging statement

  // Ensure goal actions are set after fetching
  goalActions.value = store.getters.getGoalActions.filter(action => action.goal_id === Number(goalId));
  console.log('Filtered Actions for Goal ID:', goalActions.value); // Debugging statement

  // Fetch the goal details
  const allGoals = store.getters.getGoals; // Assuming this getter returns all goals
  console.log('All Goals fetched:', allGoals); // Debugging statement
  goal.value = allGoals.find(g => g.id === Number(goalId)); // Ensure goalId is a number

  // Attach the actions to the goal
  if (goal.value) {
    goal.value.actions = goalActions.value; // Attach the actions to the goal
  } else {
    console.error('Goal not found for ID:', goalId); // Debugging statement
  }
});
</script>
