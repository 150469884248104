<template>
  <div class="relative w-full">
    <button @click="togglePopup" class="px-4 py-2 bg-primary-600 text-white text-sm rounded-md">
      Select Statuses
    </button>

    <div v-if="showPopup" class="absolute mt-2 bg-white rounded-lg rounded-tl-none shadow-lg p-4 w-full max-w-md">
      <h2 class="text-lg font-bold mb-2">Select Statuses</h2>
      <div class="py-1 flex flex-wrap gap-2 mb-2">
        <button class="h-6 w-6 text-sm font-semibold border border-primary-600 rounded-full" @click="disableAll">
          <MinusIcon class="text-primary-600"></MinusIcon>
        </button>
        <button class="h-6 w-6 text-sm font-semibold border border-primary-600 rounded-full" @click="enableAll">
          <PlusIcon class="text-primary-600"></PlusIcon>
        </button>
      </div>
      <form @submit.prevent="applySelection">
        <div v-for="status in statuses" :key="status.id" class="mb-2">
          <label class="inline-flex items-center text-sm">
            <input type="checkbox" v-model="selectedStatuses" :value="status.id" class="form-checkbox text-primary-600">
            <span class="ml-2">{{ status.name }}</span>
          </label>
        </div>
        <div class="mt-4 flex justify-end text-sm">
          <button type="button" @click="togglePopup" class="px-4 py-2 mr-2 bg-gray-300 rounded-md">Cancel</button>
          <button type="submit" class="px-4 py-2 bg-primary-600 text-white rounded-md">Apply</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
  import { sendPost } from '../components/entities/functions.js';
  import { MinusIcon, PlusIcon } from "@heroicons/vue/20/solid" 
</script>

<script>
  export default {
    data: function () {
      return {
        statuses: [],
        selectedStatuses: [],
        showPopup: false
      }
    },

    async mounted() {
      await this.getStatuses();
      this.selectedStatuses = this.statuses.map(status => status.id);
    },

    methods: {
      async getStatuses() {
        const response = await sendPost('/api/get/statuses');
        if (response) {
          this.statuses = Object.entries(response.statuses).map(([id, name]) => ({ id, name }));
          this.selectedStatuses = this.statuses.map(status => status.id);
        }
      },
      togglePopup() {
        this.showPopup = !this.showPopup;
      },
      applySelection() {
        this.$emit('update:selectedStatuses', this.selectedStatuses);
        this.togglePopup();
      },
      disableAll() {
        this.selectedStatuses = [];
      },
      enableAll() {
        this.selectedStatuses = this.statuses.map(status => status.id);
      }
    }
  }
</script>
