<template>

      <div class="bg-secondary-600 pt-16 pb-20">
    <div class="mx-auto max-w-3xl px-6 text-center lg:px-8">
      <div class="mx-auto max-w-3xl">
        <h2 class="mt-2 ws-text-heading-md text-white">Pricing</h2>
      </div>
      <div class="relative mt-6">
        <p class="mx-auto ws-text-body-base text-white/90">Choose the session length that best fits your needs. Whether you prefer a shorter, focused session or a more in-depth discussion, I offer flexible options to accommodate your preferences.</p>
      </div>
    </div>

        <div class="pt-16 mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
            <div v-for="tier in tiers" :key="tier.id" class="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
              <div>
                <h3 :id="tier.id" class="ws-text-body-base-bold text-primary-600">{{ tier.name }}</h3>
                <div class="mt-4 flex items-baseline gap-x-2">
                  <span class="ws-text-heading-lg text-gray-800">{{ tier.priceMonthly }}</span>
                  <span class="ws-text-body-sm text-gray-600">/session</span>
                </div> 
              </div>
              <a :href="tier.href" :aria-describedby="tier.id" class="mt-8 text-center block ws-cta-primary">Get started</a>
            </div>
            <div class="mt-4 border border-white/30 flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-white/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
              <div class="lg:min-w-0 lg:flex-1">
                <h3 class="ws-text-body-base-bold text-white">Low Cost Support</h3>
                <p class="ws-text-body-sm text-white mt-2">I strongly believe in making mental health support accessible to everyone so I offer low-cost therapy to individuals facing financial challenges. Feel free to reach out if you’d like to know more.</p>
              </div>
              <a href="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Low%20Cost%20Support" class="rounded-md px-3.5 py-2 text-base font-semibold leading-6 text-white ring-1 ring-inset ring-primary-200 hover:ring-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">Contact Me<span aria-hidden="true"> &rarr;</span></a>
            </div>
          </div>
        </div>
      </div>
 
</template>


<script setup>
      
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { CheckIcon, CalendarIcon, ClipboardIcon, KeyIcon } from '@heroicons/vue/24/outline' 

const tiers = [
  {
    name: '30-minute session',
    id: 'tier-hobby',
    href: '/register/overview',
    priceMonthly: '£50',
  },
  {
    name: '50-minute session',
    id: 'tier-team',
    href: '/register/overview',
    priceMonthly: '£70',
    
  },
]

</script>
