<template>
  <!-- Button -->
  <div class="card-cf-tp">
    <button type="button" @click="openModal" class="flex w-full items-center justify-between py-1">
      <div class="flex-1 space-y-1">
        <div class="flex items-center justify-between">
          <div class="ml-4">
            <h3 class="text-left text-display-base">Current Problems</h3>
          </div>
          <ChevronRightIcon class="h-9 w-auto text-gray-800 rounded-full" />
        </div>
      </div>
    </button>
  </div>
  <!-- END Button -->

  <!-- Modal Container -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-90" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="modal-placement">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="modal-open-container">
              <!-- END Modal Container -->

              <!-- Modal Heading Section -->
              <DialogTitle class="modal-open-heading-container">
                <div class="flex items-center">
                  <img class="h-16" :src="imageSource" :alt="imageAltText" />
                  <h3 class="pl-2 text-display-base-thin">{{ title }}</h3>
                </div>
                <button type="button" @click="closeModal" class="modal-dismiss-button">
                  <XMarkIcon class="modal-dismiss-icon" aria-hidden="true" />
                </button>
              </DialogTitle>
              <!-- END Modal Heading Section -->

              <!-- Modal Body Section -->
              <div v-if="Object.keys(groupedProblems).length > 0">
  <div v-for="(problems, category) in groupedProblems" :key="category">
    <h4 class="px-4 text-secondary-600 font-semibold">{{ category }}</h4>
    <div v-for="problem in problems" :key="problem.id" class="modal-list">
      <FireIcon class="modal-icon" />
      <p class="text-body-cf-tp">{{ problem.problem }}</p>
    </div>
  </div>
</div>
<div v-else>
  <p class="cf-tp-no-entries-title"> 
    No current entries
  </p>  
  <p class="cf-tp-no-entries-body"> 
    You and your therapist will add your 'Triggers' during treatment.
  </p>   
</div>
              <!-- END Modal Body Section -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import { ChevronRightIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import { FireIcon } from '@heroicons/vue/24/outline';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

const title = 'My Current Problems';

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}
function openModal() {
  isOpen.value = true;
}
</script>

<script>
export default {
  props: {
    problems: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      imageSource: "/img/webapp/case_formulation/triggers.png",
      imageAltText: 'Three circles connected to each other',
    };
  },

  computed: {
    getProblems() {
      if (!this.onClientPage) {
        return this.$store.getters.getProblems;
      }
      return this.problems;
    },

    groupedProblems() {
    const grouped = this.getProblems.reduce((groups, problem) => {
      if (!problem.category) {
        console.warn("Problem missing category:", problem);
      }
      if (!groups[problem.category]) {
        groups[problem.category] = [];
      }
      groups[problem.category].push(problem);
      return groups;
    }, {});
    console.log("Grouped Problems:", grouped);
    return grouped;
  }
},

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchProblems");
    }
  },
};
</script>

