<template>

   <div :class="{'h-screen': chats.length == 0}">
   
   <!-- Header -->	
      <header class="pt-6 header-chat-container">
         <div class="flex items-center justify-between">
         <div class="flex items-center">
            <button @click="$router.go(-1)" class="flex items-center">
        <ChevronLeftIcon class="-ml-1 h-7 w-auto text-gray-600 stroke-2" />
      </button>
          
           <h1 class="text-lg md:text-xl font-normal font-sans text-gray-600">Chat</h1>
         </div>  
         
      </div>
         <div class="flex items-center pb-4">
         <h1 class="text-body-sm">You are chatting with <span class="text-gray-800 font-semibold">Tom</span></h1>
            <div class="ml-2 inline-block h-7 w-auto overflow-hidden ring-2 ring-white shadow-md rounded-full">
           <img class="h-7" src="/img/avatar.jpg" alt="Therapist face image" />
            </div>
         </div>
   
   <!--Client List For Therapists--> 
      <div v-if="user.isTherapist">
         <div class="sm:max-w-sm px-4 sm:px-0 mb-4 flex flex-wrap gap-2">
            <UserStatusFilter @update:selectedStatuses="handleSelectedStatuses"></UserStatusFilter>
            <select id="tabs" class="bg-secondary-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               v-model="selectedChat">
               <option v-for="chat in chats" :value="chat.chat_id">
                  {{ chat.name }}
                  <span v-if="unreadInChat(chat.chat_id) > 0" class="inline-flex items-center justify-center ml-2 text-xs">
                     ({{ unreadInChat(chat.chat_id) }})
                  </span>
               </option>
            </select>
         </div>
      </div>
   
   </header>  
   <!-- END Header -->
   
   <loading-overlay :loading="loading"></loading-overlay>
    <!--Chat Container-->  
   <div class="chat-layout"> 
      <div v-if="chats.length > 0" ref="chatContainer" class=" border-r bg-gray-100 flex-1 sm:pt-20 justify-between flex flex-col" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
         <div id="messages" class="pt-10 pb-36 px-4 flex flex-col space-y-4 p-1 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
            <div v-if="!loading && messageGroups.length == 0" class="pt-12 font-medium font-sans text-lg text-gray-700 text-center">
               Start a conversation with your therapist...
            </div>
            <div v-for="messageCluster in messageGroups" class="relative md:px-8">
               <div class="flex items-end" :class="{ 'justify-end': messageCluster.from_id == user.id }">
                  <div class="flex flex-col space-y-1 max-w-xs md:max-w-lg mx-0" :class="{ 'items-end': messageCluster.from_id == user.id, 'items-start': messageCluster.from_id != user.id }">
                     <div v-for="(message, index) in messageCluster.messages" :data-message-id="message.id">
                        
                        <span class="px-3 py-1.5 inline-block rounded-lg"
                              :class="{
                                 'ml-12 bg-primary-300 bg-opacity-50 shadow-sm ': user.id == message.from_id,
                                 'mr-12 bg-secondary-300 bg-opacity-50 shadow-sm ': user.id != message.from_id,
                                 'rounded-br-none': messageCluster.messages.length-1 == index && user.id == message.from_id,
                                 'rounded-bl-none': messageCluster.messages.length-1 == index && user.id != message.from_id
                                 }">
   <!--Message Author-->    
      <!-- <div class="flex flex-row gap-2 text-xs text-gray-300">-->
         <!--<div class="flex font-semibold">
            {{ message.from_id == user.id ? user.name : selectedChatData.name }}
         </div>-->                  
   <!--Message Contents-->
                           <div class="chat-text" v-html="preserveLineBreaks(message.text)"></div>
                        </span>
                        <div class="flex items-center justify-end">
                                 <div class="text-sm">
                                    <span v-if="user.id == message.from_id && (message.status == 'sent' || message.is_read)"
                                       :class="{'text-green-400': message.is_read, 'text-zinc-800': !message.is_read}">
                                          <check-icon class="stroke-4 h-3 md:h-4" />
                                    </span>
                                 </div>
                                 <div v-if="false" class="ml-2">
                                    time
                                 </div>
                              </div>
                             <!--    <img v-if="messageCluster.from_id === therapistId" src="/img/avatar.jpg" alt="Therapist Avatar" class="w-6 h-6 rounded-full order-1">-->
                        <div v-if="firstUnreadMessageId !== false && firstUnreadMessageId === message.id" class="absolute -top-2 left-0 w-full h-px bg-gray-300"></div>
                     </div>
                  </div>
              <!--  <img src="/img/avatar.jpg" alt="Therapy face image" class="w-6 h-6 rounded-full order-1">-->
               </div>
            </div>
         </div>
      </div>
         <div v-else-if="!loading" class="px-6 py-12 text-center text-xl text-semibold text-gray-600">
            <span v-if="user.isTherapist">
               We're still in the process of assigning users to you
            </span>
            <span v-else>
               Hold tight whilst we setup chat for you...
            </span>
         </div>
      </div> 
   
   <!--Footer-->
         <div v-if="!loading" class="">
            <div class=" fixed bottom-0 left-0 md:left-96 right-0 z-30 shadow-sm bg-secondary-100 md:px-8 pt-2 pb-4 sm:mb-0">
   <!--Typing Indicator-->
            <div class="ml-2 h-6 my-1 text-sm font-sans italic text-gray-700 flex flex-row">
               <div v-show="isTyping" class="mr-1">
                  {{ user.isTherapist ? 'User is typing...' : 'Therapist is typing...' }}<span class="not-italic ml-1">🖉</span>
               </div>
               <div v-if="!twilioInited" class="flex ml-1">
                  Loading chat...
               </div>
   <!--Online Status-->         
               <div class="flex ml-auto not-italic px-4">
                  {{ onlineStatusLabel }}
               </div>
            </div>
   <!--Message Input--> 
            <div class="flex flex-row mb-4 px-4">
               <textarea :placeholder="user.isTherapist ? 'Write your message...' : 'Type your message...'"
                  class="w-full border-none text-gray-600 text-base font-normal font-sans placeholder-gray400 pl-4 bg-white rounded-l-md py-3 focus:border-gray-300 focus:ring-transparent " rows="1"
                  v-model=messageText
                  @input="onUserTyping"
                  @keyup.enter="sendMessage" >
               </textarea>
   <!--Message Send Button-->      
               <div class="items-center inset-y-0">
                  <button
                     type="button"
                     class="h-full inline-flex items-center justify-center p-3 rounded-r-md transition duration-500 ease-in-out text-white bg-primary-600 focus:outline-none"
                     @click="sendMessage"
                     :disabled="!twilioInited">
                     <paper-airplane-icon class="text-white h-7 w-7" aria-hidden="true" />   
                  </button>
               </div>
            </div>
         </div>
      </div>
      <!--END Footer-->
       
      </div>   
    
   </template>
   
   <script setup>
   
   import { ref } from 'vue'
   import { PaperAirplaneIcon, CheckIcon } from '@heroicons/vue/20/solid'
   import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
   import InfoIcon from '../components/InfoIcon';
   import { Client } from '@twilio/conversations';
   import { mapGetters, mapActions } from 'vuex';
   import { sendPost } from '../components/entities/functions.js';
   import LoadingOverlay from '../components/LoadingOverlay';
   import UserStatusFilter from '../components/UserStatusFilter.vue';
   
   </script>
   
   <script>
   export default {
      data: function () {
         return {
            chats: [],
            user: Object,
            selectedChat: null,
            messageGroups: [],
            messageText: null,
            conversationsClient: null,
            currentConversation: null,
            conversations: Array,
            isTyping: false,
            firstUnreadMessageId: false,
            twilioInited: false,
            lastReadMessageId: -1,
            tokenExpirationTime: null,
            tokenInterval: 3600,
            timer: null,
            loading: false,
            filter: false
         }
      },
      mounted() {
         window.addEventListener('resize', this.adjustChatContainerHeight);
         this.getChats();
   
         this.timer = setInterval(() => {
            this.checkTokenExpiration();
         }, 10 * 1000)
      },
   
      beforeUnmount() {
         window.removeEventListener('resize', this.adjustChatContainerHeight);
         this.disableListeners();
         clearInterval(this.timer);
      },
   
      watch: {
         selectedChat(value) {
            this.messageText = this.getStoredMessageText();
            this.getMessages()
            this.saveSelectedChat();
         },
         selectedChatStatus(value) {
            if (value.last_read !== this.lastReadMessageId) {
               this.lastReadMessageId = value.last_read;
               this.updateMessageReadStatus(this.lastReadMessageId);
            }
         }
      },
   
      computed: {
         ...mapGetters({
            onlineStatuses: 'chatStore/onlineStatuses'
         }),
   
         selectedChatStatus () {
            return this.onlineStatuses.find(status => status.chat_id == this.selectedChat)
         },
   
         onlineStatusLabel() {
            let statusText = '';
            let chat = this.selectedChatStatus;
            if (chat) {
               statusText = 'User ' + (chat.online ? 'online' : 'offline');
               if (!this.user.isTherapist) {
                  statusText = chat.online ? 'Therapist available' : 'Therapist currently unavailable';
               }
            }
            return statusText;
         },
   
         selectedChatData() {
            if (this.selectedChat) {
               return this.chats.find(chat => chat.chat_id == this.selectedChat);
            } else {
               return null;
            }
         },
      },
   
      methods: {
         ...mapActions({
            updateOnlineStatuses: 'chatStore/updateOnlineStatuses'
         }),
         isChatIdInChats(chatId) {
            return this.chats.some(chat => chat.chat_id == chatId);
         },
   
         async getChats() {
            this.loading = true;
            const responseData = await sendPost('/api/chat/get-chats', {
               filter: this.filter
            });
            this.user = responseData.user;
            this.chats = responseData.chats;
            this.buildConversations();
   
            if (this.chats.length > 0) {
               let savedSelectedChat = null;
               if (this.user.isTherapist) {
                  savedSelectedChat = localStorage.getItem('selectedChat');
               }
   
               this.selectChat((savedSelectedChat !== null && this.isChatIdInChats(savedSelectedChat)) ? savedSelectedChat : this.chats[0].chat_id);
            }
            this.loading = false;
         },
   
         selectChat(chat_id) {
            this.selectedChat = chat_id
            this.messageText = this.getStoredMessageText();
         },
   
         async getMessages() {
            this.loading = true;
            this.setUnreadStatusToZero(this.selectedChat);
   
            await sendPost('/api/chat/get-messages', { 'chat_id': this.selectedChat }).then((responseData) => {
               this.messageGroups = responseData.messages
               this.firstUnreadMessageId = responseData.firstUnreadMessageId;
   
               this.fetchAccessToken();
               this.adjustChatContainerHeight();
            });
   
            setTimeout(() => {
               this.scrollToBottom();
            }, 10);
   
            this.loading = false;
         },
   
         async sendMessage() {
            if (!this.messageText) {
               return;
            }
            let text = this.messageText
            this.messageText = '';
            this.saveMessageText();
            const tempId = this.generateHash(text, this.user.id);
            this.addMessageToGroup(this.user.id, text, tempId, 'pending');
   
            await sendPost('/api/chat/send-message', { 'chat_id': this.selectedChat, 'text': text, 'from_id': this.user.id });
            
         },
   
         async sendPost(endpoint, data = null) {
            let responseData = null;
            try {
               await axios.post(endpoint, data, {
                  headers: {
                     'Accept': 'application/json'
                  }
               }).then(response => {
                  if (response) {
                     responseData = response.data;
                  }
               });
            } catch (error) {
               console.log(error); // log the error for debugging
            }
            return responseData;
         },
   
   
         async updateMessageStatus(sid) {
            await sendPost('/api/chat/update-message-status', { 'chat_id': this.selectedChat, 'twilio_sid': sid });
         },
   
         async fetchAccessToken() {
            this.twilioInited = false;
            await sendPost('/api/chat/get-access-token', {'interval': this.tokenInterval }).then((responseData) => {
               const accessToken = responseData.access_token;
               this.tokenExpirationTime = new Date().getTime() + (this.tokenInterval * 1000);
               this.conversationsClient = new Client(accessToken);
               this.conversationsClient.on("connectionStateChanged", this.conversationStateChanged);
            });
         },
   
         conversationStateChanged(state) {
            switch (state) {
               case "connected":
                  console.log('twilio connected');
                  this.twilioInited = true;
                  this.joinConversation(this.conversations[this.selectedChat]);
               break;
               case "disconnected":
                  console.log('Disconnected from Twilio Conversations API');
                  this.fetchAccessToken();
               break;
            }
         },
   
         async joinConversation(conversationSid) {
            try {
               const conversation = await this.conversationsClient.getConversationBySid(conversationSid);
               this.disableListeners();
               this.currentConversation = conversation;
   
               conversation.on('messageAdded', this.onMessageAdded);
               conversation.on('typingStarted', this.onTypingStarted);
               conversation.on('typingEnded', this.onTypingEnded);
   
               await conversation.setAllMessagesRead();
            } catch (error) {
               console.error('Failed to join conversation:', error);
            }
         },
   
         onMessageAdded(message) {
            let from_id = message.author;
            let text = message.body;
            let twilio_sid = message.sid;
   
            if (from_id != this.user.id) {
               this.addMessageToGroup(from_id, text, twilio_sid)
   
               setTimeout(() => { // HACK
                  this.updateMessageStatus(twilio_sid);
               }, 1000);
   
            }
            else {
               const tempId = this.generateHash(text, from_id);
               this.updateMessageId(tempId, twilio_sid);
            }
         },
   
         onTypingStarted(participant) {
            if (this.user.id != participant.identity) {
               this.isTyping = true;
            }
         },
   
         onTypingEnded(participant) {
            if (this.user.id != participant.identity) {
               this.isTyping = false;
            }
         },
   
         addMessageToGroup(from_id, text, twilio_sid, status = 'pending') {
            let newMessage = {
               from_id: from_id,
               text: text,
               twilio_sid: twilio_sid,
               status: status,
               id: -1
            };
   
            if (this.messageGroups.length === 0) {
               this.messageGroups.push({
                  from_id: from_id,
                  messages: [newMessage],
               });
               return;
            }
   
            let lastGroup = this.messageGroups[this.messageGroups.length - 1];
   
            if (lastGroup.from_id === from_id) {
               lastGroup.messages.push(newMessage);
            } else {
               this.messageGroups.push({
                  from_id: from_id,
                  messages: [newMessage],
               });
            }
            this.scrollToBottom();
         },
   
         scrollToBottom() {
            this.$nextTick(() => {
               const messagesContainer = document.getElementById('messages');
               messagesContainer.scrollTop = messagesContainer.scrollHeight;
            });
         },
   
         scrollToFirstUnreadMessage() {
            this.$nextTick(() => {
               const messagesContainer = document.getElementById('messages');
   
               const firstUnreadMessage = this.messageGroups
                  .flatMap((messageCluster) => messageCluster.messages)
                  .find((message) => message.id === this.firstUnreadMessageId);
   
               if (firstUnreadMessage) {
                  const firstUnreadMessageElement = document.querySelector(
                  `[data-message-id="${firstUnreadMessage.id}"]`
                  );
   
                  if (firstUnreadMessageElement) {
                     messagesContainer.scrollTop =
                        firstUnreadMessageElement.offsetTop - messagesContainer.offsetTop;
                  }
               } else {
                  messagesContainer.scrollTop = messagesContainer.scrollHeight;
               }
            });
         },
   
         onUserTyping() {
            this.saveMessageText();
            if (!this.currentConversation || !this.twilioInited || this.checkTokenExpiration()) {
               return;
            }
            this.currentConversation.typing();
         },
   
         buildConversations() {
            this.conversations = {};
            this.chats.forEach(chat => {
               this.conversations[chat.chat_id] = chat.conversation;
            })
         },
   
         generateHash(text, userId) {
            const input = `${text}-${userId}`;
            let hash = 0;
            for (let i = 0; i < input.length; i++) {
               const char = input.charCodeAt(i);
               hash = (hash << 5) - hash + char;
               hash |= 0;
            }
            return `temp-${hash}`;
         },
   
         updateMessageId(tempId, twilio_sid) {
            for (const messageGroup of this.messageGroups) {
               for (const msg of messageGroup.messages) {
                  if (msg.twilio_sid === tempId) {
                     msg.twilio_sid = twilio_sid;
                     msg.status = 'sent';
                     this.scrollToBottom();
                     break;
                  }
               }
            }
         },
   
         updateMessageReadStatus(message_id) {
            if (!message_id) {
               return;
            }
            for (const messageGroup of this.messageGroups) {
               for (const msg of messageGroup.messages) {
                  if (msg.from_id == this.user.id && msg.id <= message_id) {
                     msg.is_read = true;
                  }
               }
            }
         },
   
         saveSelectedChat() {
            localStorage.setItem('selectedChat', this.selectedChat);
         },
   
         disableListeners() {
            if (this.currentConversation) {
               this.currentConversation.off('messageAdded', this.onMessageAdded);
               this.currentConversation.off('typingStarted', this.onTypingStarted);
               this.currentConversation.off('typingEnded', this.onTypingEnded);
               this.currentConversation = null;
            }
            if (this.conversationsClient) {
               this.conversationsClient.off("connectionStateChanged", this.conversationStateChanged);
            }
         },
   
         unreadInChat(chat_id) {
            const chat = this.onlineStatuses.find(status => status.chat_id === chat_id);
            if (!chat) {
               return 0;
            }
            if (chat.unread > 0 && chat_id == this.selectedChat) {
               this.setUnreadStatusToZero(chat_id);
               return 0;
            }
            return chat.unread;
         },
   
         setUnreadStatusToZero(chatId) {
            const updatedStatuses = [...this.onlineStatuses];
            const index = updatedStatuses.findIndex(status => status.chat_id === chatId);
            if (index !== -1) {
               updatedStatuses[index].unread = 0;
               this.updateOnlineStatuses(updatedStatuses);
            }
         },
   
         preserveLineBreaks(text) {
            return text.replace(/\n/g, '<br>');
         },
   
         adjustChatContainerHeight() {
            if (this.chats.length == 0) {
               return;
            }
            const chatContainer = this.$refs.chatContainer;
            const offsetTop = chatContainer.offsetTop;
            chatContainer.style.height = `calc(100vh - ${offsetTop}px)`;
         },
   
         saveMessageText() {
            localStorage.setItem(`messageText_${this.selectedChat}`, this.messageText);
         },
   
         getStoredMessageText() {
            if (!this.selectedChat) {
               return;
            }
            const storedMessageText = localStorage.getItem(`messageText_${this.selectedChat}`);
            if (storedMessageText) {
               return storedMessageText;
            } else {
               return '';
            }
         },
   
         checkTokenExpiration() {
            let currentTime = new Date().getTime();
            let expired = currentTime > this.tokenExpirationTime;
            if (expired && this.twilioInited) {
               this.fetchAccessToken();
            }
            return expired;
         },
   
         handleSelectedStatuses(statuses) {
            this.filter = statuses;
            this.getChats();
         }
      }
   }
   </script>