<template>
  <div>

<!-- SECTION-Hero -->
    <BlockHero
      headline="Personalised Mental Health Support Just for Students"
      subheadline=""
      bgClass="bg-secondary-800"
      theme="dark" 
      bgImage="/img/website/hero-student.jpg"
    />
  
<!-- SECTION-Introduction Video -->
        <section class="px-8 sm:px-6 max-w-4xl mx-auto pb-12 sm:pb-20">
          <TypingEffect class="py-8 sm:pt-16 sm:pb-8" />
        <video class="mb-8 mx-auto sm:max-w-md rounded-xl shadow-lg" controls playsinline preload="metadata" 
          :poster="shouldUsePoster ? '/img/website/video-poster-me-1.jpg' : null">
            <source src="/img/website/landing-video-1.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <div class="max-w-3xl mx-auto border-2 border-primary-600 border-opacity-30 rounded-2xl p-4 sm:p-6">
          <p class=" text-left ws-text-body-lg text-gray-600">
            "With Youlio, I’m here to help guide you through the challenges of mental health as you navigate this important stage of your life. Everyone’s journey is different, which is why I offer three personalised options to support your unique needs as a student."
        </p>       
      </div>
        </section>

<!-- SECTION-Three Services --> 
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
    <section>  
    <BlockContent
      overline="Prioritising Your Needs"
      headline="Discover the Right Path For You"
      subheadline="Whether you're looking for a one-time check-in, a focused mental health program, or ongoing weekly therapy, I’m here to help you find what works best for you as a student. Let’s explore your options:"
      bgClass="bg-white"
      theme="light"
      paddingTopClass="pt-12 sm:pt-20 md:pt-16"
      paddingBottomClass="pb-0 sm:pb-0 md:pb-0"
    /> 
    <BlockThreeCards
  :card1="{
    icon: VideoCameraIcon, 
    title: 'Weekly Therapy', 
    description: 'Ongoing one-on-one sessions for consistent support and guidance. Ideal for those who want regular check-ins and a safe space to work through challenges.', 
    link: '/individuals/weekly-therapy', 
    ctaText: 'Learn More', 
    bgColor: 'primary-100'
  }"
  :card2="{
    icon: MapIcon, 
    title: 'Mental Health Programs', 
    description: 'Structured programs designed to help with challenges like anxiety, school stress, or low self-esteem. These programs are built around 1:1 sessions with me, providing you with ongoing support to make real progress and build confidence during your studies.', 
    link: '/individuals/mental-health-programs', 
    ctaText: 'Learn More', 
    bgColor: 'gray-100'
  }"
  :card3="{
    icon: WrenchScrewdriverIcon, 
    title: 'Wellbeing MOT', 
    description: 'Begin with questionnaires and readings, leading up to a 90-minute, in-depth 1:1 session focused entirely on your mental health. In this session, we’ll dive deep to uncover actionable steps you can take right away to feel more balanced and supported during your studies. ', 
    link: '/individuals/mental-health-mot', 
    ctaText: 'Learn More', 
    bgColor: 'secondary-100'
  }"
    />
    </section>  
         
<!-- SECTION-How Youlio Supports Your Journey -->
    <img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
      <BlockSwiper
        :slides="4"
        overline="Personalised Support"
        headline="Maximising Your Experience with Youlio"
        subheadline="Youlio enables me to provide therapy-driven mental health support in a more effective and connected way. The platform integrates convenient tools and resources to streamline our sessions, track your progress, and offer continuous support."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="Smooth Sailing Sessions"
        subheadline="Youlio makes therapy sessions a breeze! The platform handles all of our sessions, making it super easy for us to connect."
        imageSrc="/img/website/landing-features-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Messaging"
        subheadline="You can reach out to me anytime with our messaging feature, so you're never alone on your journey."
        imageSrc="/img/website/landing-features-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Personalised Support"
        subheadline="With Youlio, I can send you readings, questionnaires, and activities that are completely tailored towards you."
        imageSrc="/img/website/landing-features-3.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiper  
        headline="Progress Tracking"
        subheadline="Youlio enables us to monitor your journey throughout treatment, keeping us focused on the areas that matter most."
        imageSrc="/img/website/landing-features-4.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
      </BlockSwiper>

<!-- SECTION-Example Programs -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
      <BlockSwiper
        :slides="3"
        overline="Example Programs"
        headline="Mental Health Programs Tailored Towards Students"
        subheadline="Explore programs designed to tackle common challenges students face, such as anxiety and self-esteem issues, with tailored solutions to fit your unique needs and school environment."
>
        <template #slide-0>
          <BlockContentInSwiperProgram
        headline="Optimising Your Mental Health"
        subheadline="Enhance your overall well-being with tailored strategies for balance and resilience. This program focuses on practical tools and techniques to optimise your mental health for a more fulfilling life."
        imageSrc="/img/website/program-mental-health-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
         
        </template>
        <template #slide-1>
          <BlockContentInSwiperProgram
        headline="Boosting Your Self-esteem"
        subheadline="Develop a stronger sense of self-worth and confidence to enhance your academic and social experiences. This program provides practical strategies for overcoming self-doubt and building a positive self-image."
        imageSrc="/img/website/program-self-esteem-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />

        </template>
        <template #slide-2>
          <BlockContentInSwiperProgram
        headline="Managing Exam Anxiety"
        subheadline="Learn effective techniques to manage and reduce anxiety related to exams and academic pressure. This program offers tools for relaxation, stress management, and maintaining focus to help you perform at your best."
        imageSrc="/img/website/program-exam-anxiety.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
       
      </BlockSwiper>

      <img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
<!-- SECTION-How I Support Your Journey -->
    <BlockContent
    overline="Here For You"
    headline="Guiding You Every Step of the Way"
    subheadline="As a licensed therapist with extensive experience, I’m dedicated to supporting you throughout your journey with Youlio. I offer personalised guidance and expertise, ensuring that you receive tailored care in every 1:1 session. My goal is to be a constant, supportive presence, helping you navigate challenges and celebrate progress."
    secondaryCtaText="Learn More"
    secondaryCtaLink="/approach"
    imageSrc="/img/website/me-4.jpg"
    bgClass="bg-white"
    theme="light"
    layout="image-right"
    />


<!-- SECTION-CTA -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
<div class="pt-12 sm:pt-12 mx-auto max-w-3xl px-6 text-center lg:px-8">
  <div class="mx-auto max-w-3xl">
    <h2 class="mt-2 ws-text-heading-md text-gray-800">Ready to Make a Change?</h2>
  </div>
  <div class="relative mt-6">
    <p class="max-w-xl mx-auto ws-text-body-base text-gray-700">
      Taking the first step towards better mental health can be transformative. Reach out today and start your path to a better you.
    </p>
  </div>

  <!-- Drop-Down-CTA -->
  <div class="pt-8 text-center">
    <!-- Get Started Button -->
    <button
      @click="toggleDropdown"
      ref="dropdownButton"
      class="ws-cta-primary mx-auto"
    >
      Get Started
    </button>

    <!-- Dropdown Menu -->
    <transition name="fade" mode="out-in">
    <div
      v-show="dropdownVisible"
      ref="dropdownMenu"
      class="absolute bg-white shadow-md rounded mt-2 w-64 z-10 mx-auto"
      style="left: 50%; transform: translateX(-50%);"
    >
      <a
        href="/individuals/weekly-therapy"
        class="text-base font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200"
      >
        Weekly Therapy
      </a>
      <a
        href="/individuals/mental-health-programs"
        class="text-base font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200"
      >
        Mental Health Programs
      </a>
      <a
        href="/individuals/mental-health-mot"
        class="text-base font-semibold block px-4 py-2 text-gray-800 hover:bg-secondary-200"
      >
        My Wellbeing MOT
      </a>
    </div>
  </transition>
  </div>
</div>
  <BlockCTA    
      heading=""
      message=""
      primaryButtonText=""
      primaryButtonLink=""
      secondaryButtonText=""
      secondaryButtonLink=""
      imageSrc="/img/website/schools-cta.jpg"
      bgClass="white"
      theme="light"
      paddingTopClass="pt-0 sm:pt-0 md:pt-0"
      paddingBottomClass="pb-16 sm:pb-20 md:pb-24"
    />

  </div>
</template>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockThreeCards from '../../website/components/BlockThreeCards' ;
import BlockContent from '../../website/components/BlockContent' ;
import TypingEffect from '../../website/components/TypingEffect' ;
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper' ;
import BlockContentInSwiperProgram from '../../website/components/BlockContentInSwiperProgram' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { VideoCameraIcon, WrenchScrewdriverIcon, MapIcon, KeyIcon, CalendarIcon, CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'   

</script>



<script>
export default {
  data() {
    return {
      shouldUsePoster: true,
      dropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    handleClickOutside(event) {
      if (
        this.$refs.dropdownMenu &&
        !this.$refs.dropdownMenu.contains(event.target) &&
        !this.$refs.dropdownButton.contains(event.target)
      ) {
        this.dropdownVisible = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* Fade animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
