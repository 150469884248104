<template>
  <section
    :class="[
      'hero-section text-center',
      bgClass,
      paddingTopClass,
      paddingBottomClass,
      theme === 'dark' ? 'text-white' : 'text-gray-900',
      'relative'
    ]"
    :style="bgImage ? { backgroundImage: 'url(' + bgImage + ')', backgroundSize: 'cover', backgroundPosition: 'center' } : {}"
  >
    <div class="container mx-auto sm:max-w-3xl" >
      <h1
        :class="theme === 'dark' ? 'text-white' : 'text-gray-900'"
        class="px-12 mb-4 text-3xl sm:text-5xl font-bold tracking-tight"
      >
        {{ headline }}
      </h1>
      <p
        :class="theme === 'dark' ? 'text-white' : 'text-gray-600'"
        class="px-6 ws-text-body-base mb-6"
      >
        {{ subheadline }}
      </p>
      <div class="flex justify-center gap-4 mb-6">
        <button v-if="ctaText" @click="handleCtaClick" class="ws-cta-primary">
          {{ ctaText }}
        </button>
        <button v-if="secondaryCtaText" @click="handleSecondaryCtaClick" class="ws-cta-secondary">
          {{ secondaryCtaText }}
        </button>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'BlockHero',
  props: {
    headline: {
      type: String,
      default: 'Welcome to Our Service'
    },
    subheadline: {
      type: String,
      default: 'Discover the benefits and solutions we offer.'
    },
    ctaText: {
      type: String,
      default: ''
    },
    ctaLink: {
      type: String,
      default: '#'
    },
    bgImage: {
      type: String,
      default: ''
    },
    bgClass: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'light'
    },
    secondaryCtaText: {
      type: String,
      default: ''
    },
    secondaryCtaLink: {
      type: String,
      default: ''
    },
    paddingTopClass: {
      type: String,
      default: 'pt-20 sm:pt-28 md:pt-36'
    },
    paddingBottomClass: {
      type: String,
      default: 'pb-12 sm:pb-28 md:pb-32'
    }
  },
  methods: {
    handleCtaClick() {
      if (this.ctaLink) {
        window.location.href = this.ctaLink;
      }
    },
    handleSecondaryCtaClick() {
      if (this.secondaryCtaLink) {
        window.location.href = this.secondaryCtaLink;
      }
    }
  }
}
</script>

<style scoped>
.hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
}
</style>
