<template>
 
  <div class="min-h-screen overflow-x-hidden mx-auto">
    <WelcomeModal v-if="!user.welcome_modal_viewed"/>
 
 <!-- Dashboard Header -->
<header class="md:pt-20">
  <div class=" bg-gradient-to-r from-primary-200 to-secondary-100 px-4  h-20 flex items-center justify-between">  
    <!-- Avatar -->
    <div class="flex items-center space-x-3">
      <div class="inline-block h-10 w-10 overflow-hidden ring-1 ring-white rounded-full shadow-sm">
        <img class="h-full w-full object-cover" src="/img/avatar.jpg" alt="Therapist face image" />
      </div>
      <div class="text-lg font-medium text-gray-700">
        Hi {{ userName }}
      </div>
    </div>
    <div class="flex items-center space-x-2 md:hidden">
      <!-- Notification Bell -->
      <NotificationBell class="h-7 w-auto" />
      
      <!-- User Profile Icon -->
     <InitialsComponent />
    </div>
  </div>
</header>
<!-- END Dashboard Header -->


<main class="px-4 md:px-10 pb-24 md:pb-8 " data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div class="grid grid-cols-1 md:gap-10 md:grid-cols-2 xl:grid-cols-3">
        <div class="md:col-span-1">
          <dashboard-next-session />
          <div class="hidden md:block ">
          <dashboard-spotlights />
        </div>
        </div>
        <div class="md:col-span-1">
          <dashboard-collections />
        </div>
        <div class="md:hidden">
        <dashboard-spotlights />
      </div>
        <div class="md:col-span-1">
          <dashboard-activities />
        </div>
        
      </div>
    </main>

  </div>
</template>

<script setup>

  import DashboardCollections from '../components/DashboardCollections' ;
	import DashboardNextSession from '../components/DashboardNextSession' ;
  import DashboardActivities from '../components/DashboardActivities' ;
  import DashboardSpotlights from '../components/DashboardSpotlights' ;
  import WelcomeModal from '../components/WelcomeModal' ;
  import NotificationBell from '../components/NotificationBell';
  import InitialsComponent from '../components/InitialsComponent.vue';
	
</script>

<script>
  export default {
    computed: {
      user() {
        return this.$store.getters['user'];
      },
      userName() {
        if (this.user) {
          return this.user.name.split(' ')[0];
        }
        return 'there';
      }
    },
  }
</script>

