<template>
  <section
    :class="[
      'content-section',
      paddingTopClass,
      paddingBottomClass,
      '',
      bgClass,
      theme === 'dark' ? 'text-white' : 'text-gray-900',
      imageSrc ? (layout === 'image-right' ? 'text-left' : 'text-right') : 'text-center',
      { 'border border-gray-300': border },  // Conditionally apply border
      'relative'
    ]"
  >
    <div class="container mx-auto flex flex-col md:flex-row items-center" :class="{ 'justify-center': !imageSrc }" data-aos="fade-up" data-aos-once="true" data-aos-duration="1000">
      <!-- Conditional image rendering -->
      <div v-if="imageSrc && layout === 'image-left'" class="md:w-1/2 mb-6 md:mb-0 md:mr-8">
        <img :src="imageSrc" alt="Content Image" class="w-full" />
      </div>

      <!-- Text Content -->
      <div :class="imageSrc ? 'md:w-1/2' : 'md:w-full max-w-3xl'">
        <!-- Overline Text (Optional) -->
        <p v-if="overline" class="ws-text-overline mb-2 text-primary">
          {{ overline }}
        </p>
        <h1 class="ws-text-heading-sm mb-4">{{ headline }}</h1>
        <p
          :class="[theme === 'dark' ? 'text-white' : 'text-gray-900', 'opacity-75']"
          class="ws-text-body-base mb-6"
        >
          {{ subheadline }}
        </p>
        <div v-if="ctaText || secondaryCtaText" class="flex gap-4 mb-6" :class="imageSrc ? 'justify-start' : 'justify-center'">
          <button v-if="ctaText" @click="handleCtaClick" class="ws-cta-primary">
            {{ ctaText }}
          </button>
          <button v-if="secondaryCtaText" @click="handleSecondaryCtaClick" class="ws-cta-secondary">
            {{ secondaryCtaText }}
          </button>
        </div>
      </div>

      <!-- Conditional image rendering -->
      <div v-if="imageSrc && layout === 'image-right'" class="md:w-1/2 md:ml-8">
        <img :src="imageSrc" alt="Content Image" class="w-full max-w-lg" />
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'BlockContent',
  props: {
    overline: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: 'Welcome to Our Service'
    },
    subheadline: {
      type: String,
      default: 'Discover the benefits and solutions we offer.'
    },
    ctaText: {
      type: String,
      default: ''
    },
    ctaLink: {
      type: String,
      default: '#'
    },
    imageSrc: {
      type: String,
      default: ''
    },
    bgClass: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'light'
    },
    secondaryCtaText: {
      type: String,
      default: ''
    },
    secondaryCtaLink: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      default: 'image-right'
    },
    paddingTopClass: {
      type: String,
      default: 'pt-8 sm:pt-20 md:pt-28'
    },
    paddingBottomClass: {
      type: String,
      default: 'pb-8 sm:pb-20 md:pb-28'
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCtaClick() {
      if (this.ctaLink) {
        window.location.href = this.ctaLink;
      }
    },
    handleSecondaryCtaClick() {
      if (this.secondaryCtaLink) {
        window.location.href = this.secondaryCtaLink;
      }
    }
  }
}
</script>

<style scoped>
.content-section {
  position: relative;
}

.border {
  border: 1px solid #818181; /* Example border color (gray-300) */
}
</style>
