<template>
  
  <div class="min-h-screen bg-white"> 

    <!--Blur background-->
    <div class="min-h-screen relative isolate overflow-hidden">
      <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2  lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="opacity-40 aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] " style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)" />
    </div>

    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="opacity-20 relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#6A99BA] to-[#ADD4F0] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
  <!--END background-->

    <div class="flex flex-col justify-center pt-16 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <a href="/">
      <img class="mx-auto h-12 w-auto" src="/img/logo-dark.svg" alt="Youlio logo" />
    </a>
      <h2 class="mt-16 text-center ws-text-heading-thin-black">Reset your password</h2>
      <p class="pt-2 pb-8 px-4 ws-text-body-sm text-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500">
          Choose a new password for your Youlio account.
        </p>
    </div>



    <div class="mx-auto w-full sm:max-w-md mt-6 px-4">
      <div class="ws-card-bg-white-padding px-6 py-8">
      <form @submit.prevent="resetPassword">
        <div>
          <label class="ws-text-label" for="email">
            Email
          </label>
          <input v-model="data.email" class="ws-text-input" id="email" type="email" name="email" required="required" autofocus="autofocus">
        </div>
        <div class="mt-4">
          <label class="ws-text-label" for="password">
            New Password
          </label>
          <input v-model="data.password" class="ws-text-input" id="password" type="password" name="password" required="required" autocomplete="current-password">
        </div>
        <div class="mt-4">
          <label class="ws-text-label" for="password_confirmation">
            New Password Confirmation
          </label>
          <input v-model="data.password_confirmation" class="ws-text-input" id="password_confirmation" type="password" name="password_confirmation" required="required" autocomplete="current-password">
        </div>
        <div v-if="errors" class="text-red-500 py-2 font-semibold">
          <span>{{ errors.message }}</span>
        </div>

        <div class="flex items-center justify-end mt-8">
          <button type="submit" class="ws-button-primary-green">
            Reset Password
          </button>
        </div>

        </form>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const errors = ref(null);
const data = ref({
  email: null,
  password: null,
  password_confirmation: null,
});

const resetPassword = () => {
  errors.value = null;
  axios.post('/reset-password', { ...data.value, ...{ token: router.currentRoute.value.params.token } })
    .then((response) => {
      console.log(response);
      router.push({ name: 'Home' });
    })
    .catch((error) => {
      errors.value = error.response.data;
    });
};
</script>
