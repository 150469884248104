<template>
  <!-- Button -->
  <div class="card-cf-tp">
    <button type="button" @click="openModal" class="flex w-full items-center justify-between py-1">
      <div class="flex-1 space-y-1">
        <div class="flex items-center justify-between">  
          <div class="ml-4">
            <h3 class="text-left text-display-base">Dominant Parts</h3>
          </div>
          <ChevronRightIcon class="h-9 w-auto text-gray-800 rounded-full" />
        </div>
      </div>
    </button>
  </div>

  <!-- Modal Container -->
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-90" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="modal-placement">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="modal-open-container">
              <DialogTitle class="modal-open-heading-container">
                <h3 class="pl-4 py-4 text-display-base-thin">Dominant Parts</h3>
                <button type="button" @click="closeModal" class="modal-dismiss-button">
                  <XMarkIcon class="modal-dismiss-icon" aria-hidden="true" />
                </button>         
              </DialogTitle>

              <!-- Modal Body Section -->
              <div v-if="getEmotionalstates.length > 0">
                <div v-for="emotionalstate in getEmotionalstates" :key="emotionalstate.id" class="">
                  <div class="modal-list" @click="openNestedModal(emotionalstate)">
                    <BeakerIcon class="modal-icon" />
                    <p class="py-3 text-body-cf-tp-semibold">{{ emotionalstate.emotionalstate }}</p>
                  </div>
                </div>
              </div>
              <div v-else>    
                <p class="cf-tp-no-entries-title"> 
                  No current entries
                </p>  
                <p class="cf-tp-no-entries-body"> 
                  You and your therapist will add your 'Unhelpful Schemas' during treatment.
                </p>   
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

<!-- Nested Modal Container -->
<TransitionRoot appear :show="isNestedOpen" as="template">
    <Dialog as="div" @close="closeNestedModal" class="relative z-50">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-90" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div class="modal-placement">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="modal-open-container">
              <DialogTitle class="modal-open-heading-container">
                <h3 class="py-3 px-4 text-display-base-thin">{{ nestedEmotionalstate.emotionalstate }}</h3>
                <button type="button" @click="closeNestedModal" class="modal-dismiss-button">
                  <XMarkIcon class="modal-dismiss-icon" aria-hidden="true" />
                </button>         
              </DialogTitle>
              <div class="my-4 text-body-cf-tp text-left">
                <!-- Leaving out category for now
                <p class="text-display-base-thin">{{ nestedEmotionalstate.category }}</p>
                 -->
              
                <ul>
                  <li v-for="behaviour in emotionalstateBehaviours" :key="behaviour.id" class="px-4 py-4 border-b border-gray-100">
                    - <span class="font-medium text-secondary-600">{{ behaviour.behaviour }}</span> 
                  </li>
                </ul>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>


<script setup>
import { ref, watch, computed  } from "vue";
import { ChevronRightIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import { BeakerIcon } from '@heroicons/vue/24/outline';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import { useStore } from 'vuex';
const store = useStore();

const isOpen = ref(false);
const isNestedOpen = ref(false);
const nestedEmotionalstate = ref({});
const loading = ref(true);

const emotionalstateBehaviours = computed(() => {
  return store.getters.getEmotionalstateBehaviours(nestedEmotionalstate.value.id);
});

function closeModal() {
  isOpen.value = false;
}

function openModal() {
  isOpen.value = true;
}

function openNestedModal(emotionalstate) {
  nestedEmotionalstate.value = emotionalstate;
  loading.value = true; // Start loading
  fetchEmotionalstateData(emotionalstate.id);
  isNestedOpen.value = true;
}

function closeNestedModal() {
  isNestedOpen.value = false;
}

async function fetchEmotionalstateData(id) {
  loading.value = true;
  try {
    await Promise.all([
      store.dispatch('fetchEmotionalstateBehaviours', id),
    ]);
  } catch (error) {
    console.error('Error fetching emotionalstate data', error);
  } finally {
    loading.value = false;
  }
}
</script>

<script>
export default {
  props: {
    emotionalstates: {
      type: Array,
      default: () => []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getEmotionalstates() {
      return this.onClientPage ? this.emotionalstates : this.$store.getters.getEmotionalstates;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchEmotionalstates");
    }
  },
};
</script>
