<template>
  <div class="startups-small-businesses-page">

<!-- SECTION-Hero -->
    <BlockHero
      headline="Boost Mental Well-being in Startups and Small Businesses"
      subheadline=""
      bgClass="bg-gradient-to-r from-primary-600 to-primary-500"
      theme="dark"
      paddingTopClass="pt-20 sm:pt-24 md:pt-24"
      paddingBottomClass="pb-12 sm:pb-16 md:pb-16"
    />

<!-- SECTION-Introduction -->
    <BlockContent
      overline="Empowering Employees"
      headline="Why Youlio is Perfect for Startups and Small Businesses"
      subheadline="Startups and small businesses often face unique pressures as they scale. Youlio provides flexible and scalable mental health solutions that adapt to your business needs, helping you maintain a healthy, productive, and resilient team."
      bgClass="bg-white"
      theme="light"
      layout="image-right"
      imageSrc="/img/website/startups-why-choose-youlio.jpg"
    />
    
    <Wave colorClass="fill-white" shadow position="down" /> 

<!-- SECTION-Key Features -->
<BlockKeyFeatures
      title="Key Features for Startups and Small Businesses"
      :features="[
        { title: 'Scalable Solutions', description: 'Tailored mental health programs that grow with your business, from small teams to larger, more complex organisations.', icon: KeyIcon },
        { title: 'Employee Wellness', description: 'Support your team’s mental health with proactive programs that enhance overall workplace wellness and productivity.', icon: BoltIcon },
        { title: 'Flexible Scheduling', description: 'Easily manage and schedule therapy sessions and workshops that fit into your teams busy schedules.', icon: CalendarIcon },
        { title: 'Confidential Support', description: 'Ensure that all interactions and data are kept confidential, providing a safe space for your team’s mental health.', icon: LockClosedIcon }
      ]"
    />

    <Wave colorClass="fill-white" shadow position="down" /> 
<!-- SECTION-My Commitment to Startups and Small Businesses -->
    <BlockContent
    overline="Backing Your Business"
      headline="My Commitment to Startups and Small Businesses"
      subheadline="I understand the dynamic and often stressful environment of startups and small businesses. With Youlio, I am dedicated to providing flexible and effective mental health support that aligns with your unique business needs and helps your team thrive."
      bgClass="bg-white"
      theme="light"
      layout="image-right"
      imageSrc="/img/website/me-4.jpg"
    />

   <!-- SECTION-Example Programs -->
   <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockSwiper
        :slides="3"
        overline="Example Programs"
        headline="Enhancing Workplace Wellbeing"
        subheadline="Explore programs that address mental health challenges unique to startups and small businesses, customized for your team."
>
        <template #slide-0>
          <BlockContentInSwiperProgram
        headline="Combating Burnout"
        subheadline="This program focuIdentify and address the signs of burnout with a program designed to help you manage stress, prioritise self-care, and regain balance. Learn strategies to prevent burnout and create a healthier work environment."
        imageSrc="/img/website/program-burnout.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiperProgram
        headline="Overcoming Impostor Syndrome"
        subheadline="Develop the confidence to recognise your worth and contributions in a competitive work environment. This program helps you challenge self-doubt, embrace your achievements, and build a stronger sense of professional identity."
        imageSrc="/img/website/program-imposter.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiperProgram
        headline="Boosting Workplace Confidence"
        subheadline="Enhance your self-assurance and effectiveness at work with this program designed to build confidence. Gain practical skills to assert yourself, communicate more effectively, and overcome self-doubt, leading to a more impactful and fulfilling role in your organisation."
        imageSrc="/img/website/program-confidence.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-4 sm:pb-4 md:pb-4"
        />
        </template>
       
      </BlockSwiper>

    <!-- SECTION-CTA -->
    <Wave colorClass="fill-white" shadow position="down" />
    <BlockCTA    
      heading="Ready to Support Your Team?"
      message="Contact me today to discover how Youlio can enhance the well-being of your startup or small business."
      primaryButtonText="Get in Touch"
      primaryButtonLink="mailto:tom@youliohealth.com?subject=I'm%20Interested%20in%20Your%20Work%20with%20Startups%20and%20Small%20Businesses"
      imageSrc="/img/website/startups-cta.jpg"
      bgClass="bg-white"
      theme="light"
      
    />

  </div>
</template>

<script>
export default {
  name: "StartupsSmallBusinessesPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockKeyFeatures from '../../website/components/BlockKeyFeatures' ;
import BlockContent from '../../website/components/BlockContent' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockContentInSwiperProgram from '../../website/components/BlockContentInSwiperProgram' ;
import DisclosureContent from '../../website/components/DisclosureContent' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { KeyIcon, BoltIcon, CalendarIcon, LockClosedIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'   

</script>
