<template>

  <div class="min-h-screen bg-white overflow-hidden"> 
      
      <Header />
  
      <div class="mx-auto px-4 py-4 max-w-3xl">
        <div class="px-6 py-4 mx-auto md:max-w-lg ">

          <h1 class="ws-text-heading-3-black text-center" data-aos="slide-left" data-aos-once="true" data-aos-duration="500">
            Choose a password
          </h1>

          <div class="pt-12">
          <label class="ws-text-label" for="password">
            Password
          </label>
          <input v-model="data.password" class="ws-text-input" id="password" type="password" name="password" required="required" autocomplete="current-password">
          <div v-if="errors && errors.password">
            <p v-for="(error, index) in errors.password" :key="'password-'+index" class="text-red-500">{{ error }}</p> 
        </div>
        <div class="mt-4">
          <label class="ws-text-label" for="password">
            Confirm Password
          </label>
          <input v-model="data.password_confirmation" class="ws-text-input" id="password_confirmation" type="password" name="password_confirmation" required="required" autocomplete="current-password">
          <div v-if="errors && errors.password_confirmation">
            <p v-for="(error, index) in errors.password_confirmation" :key="'password_confirmation-'+index" class="text-red-500">{{ error }}</p>
          </div>
        </div>
        </div>
        <div class="mt-16 mx-auto">
          <button @click="registrationFinish" class="ws-button-primary-blue">
            Create My Account
          </button>
        </div>
      </div>
    </div>
  </div>




</template>

<script>

export default {
  data: () => {
    return {
      data: {
        password: null,
        password_confirmation: null,
      },
      errors: null,
      userId: null
    }
  },
  components: {

  },

  watch: {
    'data.password'() {
      if (this.errors && this.errors.password) {
        this.errors.password = null;
      }
    },
    'data.password_confirmation'() {
      if (this.errors && this.errors.password) {
        this.errors.password = null;
      }
    }
  },

  mounted() {
    this.userId = this.$store.getters['user_id'];
    if (!this.userId) {
      this.$router.push({ name: 'AssessmentStart'});
    }
  },
  methods: {
    async registrationFinish() {
      await sendPost('/api/registration/finish', {
          password: this.data.password,
          password_confirmation: this.data.password_confirmation,
          user_id: this.userId
        }).then(response => {
          if (response) {
            this.errors = response.errors;
          }
          if (!this.errors && response.status == 'ok') {
            this.$store.dispatch('set_user_id', null);
            this.sendVerification();
            this.$router.push({ name: 'VerifyEmail'});
          }
      });
    },
    register() {
      this.errors = null
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/register', this.data)
          .then((response) => {
            this.$store.dispatch('attempt_user')
              .then(() => {
                this.$router.push({name: 'Home'})
              })
              .catch((error) => {
                console.error(error);
              })
          })
          .catch((error) => {
            this.errors = error.response.data.errors
          })
      });
    },
    sendVerification() {
      this.errors = null
      axios.post('/email/verification-notification')
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          this.errors = error.response.data
        })
    }
  }
}
</script>

<script setup>

import Header from '../../../website/components/Header';
import { sendPost } from '../../../components/entities/functions.js';

</script>
