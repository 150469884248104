<template>
   <div class="relative">
  <!-- Notification Bell -->
  <button @click="togglePopup" class="bg-transparent rounded-full text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-indigo-500">
    <BellIcon class="h-7 w-auto cursor-pointer" aria-hidden="true" />
  </button>

  <!-- Notification Popup -->
  <div v-if="showPopup" class="z-50 absolute right-0 mt-2 w-64 max-w-sm bg-white rounded-lg shadow-lg p-2 flex flex-col gap-2">
    <div v-for="notification in unreadNotifications" :key="notification.chat_id" class="bg-blue-100 text-gray-600 text-sm font-normal px-4 py-3" role="alert">
      <router-link :to="{ name: 'Chat', params: { chatId: notification.chat_id } }" class="text-gray-600">
        <p>
          You have {{ notification.unread }} unread message{{ notification.unread > 1 ? 's' : '' }} in your chat with <span class="font-semibold text-gray-800"> {{ notification.name }}</span>
        </p>
      </router-link>
    </div>
  </div>

  <!-- Notification Indicator -->
  <span v-if="hasUnreadNotifications" class="text-xs text-white absolute top-0 right-0 block h-2 w-2 transform translate-x-1/2 -translate-y-1/2 rounded-full animate-pulse bg-green-400 ring-1 border border-primary-200 ring-white"></span>
</div>

  </template>
  
  <script setup>
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { BellIcon } from '@heroicons/vue/24/outline';
  
  const store = useStore();
  const showPopup = ref(false);
  
  const unreadNotifications = computed(() => store.getters['chatStore/unreadNotifications']);
  const hasUnreadNotifications = computed(() => unreadNotifications.value.length > 0);
  
  const togglePopup = () => {
    if (!hasUnreadNotifications.value) {
      showPopup.value = false;
      return;
    }
    showPopup.value = !showPopup.value;
  };
  
  watch(hasUnreadNotifications, (value) => {
    if (!value && showPopup.value) {
      showPopup.value = false;
    }
  });
  </script>
  
  <style scoped>
  /* Add any additional styles if needed */
  </style>
  