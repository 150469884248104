<template>
  <HeaderComponent :pageTitle="'My Dominant Parts'" />
  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
  
      <!-- Grouped Emotional States -->
      <div v-if="sortedCategories.length > 0">
        <!-- Loop through each sorted category and its emotional states -->
        <div v-for="category in sortedCategories" :key="category" class="mb-8">
          <!-- Category Title -->
          <h2 class="py-4 text-base md:text-base font-medium text-secondary-600 text-left">
            These are the {{ category }} parts I engage with:
          </h2>
          
          <!-- Loop through emotional states in this category -->
          <div v-for="emotionalstate in groupedEmotionalStates[category]" :key="emotionalstate.id" class="mb-4">
            <router-link :to="{ name: 'PartView', params: { id: emotionalstate.id } }" class="rounded-lg flex items-center justify-between bg-gradient-to-r from-primary-200 to-secondary-200 px-3 py-6 text-base font-semibold text-secondary-600">
              <div class="flex items-center">
                <BeakerIcon class="modal-icon" />
                <p class="ml-2">{{ emotionalstate.emotionalstate }}</p>
              </div>
              <ChevronRightIcon class="h-6 w-6 text-secondary-600" />
            </router-link>
          </div>
        </div>
      </div>

      <!-- No Entry Card -->
      <div v-else>    
        <p class="cf-tp-no-entries-title">No current entries</p>  
        <p class="cf-tp-no-entries-body">You and your therapist will add your 'Dominant Parts' during treatment.</p>
      </div>
    </main>
  </div>
</template>

<script setup>
  import { ChevronRightIcon, BeakerIcon } from '@heroicons/vue/24/outline';
  import HeaderComponent from '../components/HeaderComponent';
</script>

<script>
export default {
  props: {
    emotionalstates: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    // Group emotional states by category
    groupedEmotionalStates() {
      return this.getEmotionalstates.reduce((groups, emotionalstate) => {
        if (!groups[emotionalstate.category]) {
          groups[emotionalstate.category] = [];
        }
        groups[emotionalstate.category].push(emotionalstate);
        return groups;
      }, {});
    },

    // Sorted category names (alphabetical order)
    sortedCategories() {
      return Object.keys(this.groupedEmotionalStates).sort();
    },

    // Get emotional states (from store or props)
    getEmotionalstates() {
      if (!this.onClientPage) {
        return this.$store.getters.getEmotionalstates;
      }
      return this.emotionalstates;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchEmotionalstates");
    }
  }
};
</script>
