<template>

<div class="min-h-screen bg-white overflow-hidden"> 
    
    <Header />

    <div class="mx-auto px-4 py-4 max-w-3xl">
<!--Card-->
      <div class="px-4 py-4 mx-auto md:max-w-md ">
<!--Step 1-->  
      
        <div v-if="step == 1">
          <div>
          <h1 class="ws-text-heading-sm text-gray-800 text-center" data-aos="slide-left" data-aos-once="true" data-aos-duration="500">
              Step 1 of {{ stepCount }}
            </h1>
            <p class="pt-2 pb-16 ws-text-body-base text-gray-700 text-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500">
              First, some basic information
            </p>
              <label class="ws-text-label" for="email">
                Name
              </label>
              <input v-model="data.name" class="ws-text-input" id="name" type="text" name="name" required="required">
              <div v-if="errors && errors.name">
                <p v-for="(error, index) in errors.name" :key="'name-'+index" class="text-red-500">{{ error }}</p>
              </div>
            </div>
            <div class="my-4">
              <label class="ws-text-label" for="email">
                Email
              </label>
              <input v-model="data.email" class="ws-text-input" id="email" type="email" name="email" required="required">
              <div v-if="errors && errors.email">
                <p v-for="(error, index) in errors.email" :key="'email-'+index" class="text-red-500">{{ error }}</p>
              </div>
            </div>
          </div>
<!--Step 2--> 
      <div v-if="step == 2">
        <h1 class="ws-text-heading-sm text-gray-800 text-center" data-aos="slide-left" data-aos-once="true" data-aos-duration="500">
          Step 2 of 3
        </h1>
        <p class="px-0 pt-2 pb-8 text-center ws-text-body-base text-gray-700" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500">
          Great stuff, it's now time for a quick self-assessment to help see where your mental health is currently at.
        </p>
      </div>
<!--Step 3-->    
      <div v-if="step == 3">
        
          <h1 class="ws-text-heading-sm text-gray-800 text-center" data-aos="slide-left" data-aos-once="true" data-aos-duration="500">
            Step {{ stepCount }} of {{ stepCount }}
          </h1>
          <p class="pt-2 ws-text-body-base text-gray-700 text-center" data-aos="zoom-in" data-aos-once="true" data-aos-duration="500">
            Lastly, choose a password for your Youlio account. This will ensure it remains your private, safe space.
          </p>

        <div class="pt-8">
          <label class="ws-text-label" for="password">
            Password
          </label>
          <input v-model="data.password" class="ws-text-input" id="password" type="password" name="password" required="required" autocomplete="current-password">
          <div v-if="errors && errors.password">
            <p v-for="(error, index) in errors.password" :key="'password-'+index" class="text-red-500">{{ error }}</p>
          </div>
        </div>
        
        <div class="pt-4">
          <label class="mt-4 ws-text-label" for="password">
            Confirm Password
          </label>
          <input v-model="data.password_confirmation" class="ws-text-input" id="password_confirmation" type="password" name="password_confirmation" required="required" autocomplete="current-password">
          <div v-if="errors && errors.password_confirmation">
            <p v-for="(error, index) in errors.password_confirmation" :key="'password_confirmation-'+index" class="text-red-500">{{ error }}</p>
          </div>
          <div v-if="errors && errors.email">
            <p v-for="(error, index) in errors.email" :key="'email-'+index" class="text-red-500">{{ error }}</p>
          </div>
        </div>

        </div>
            
<!-- CTA -->
        <div class="mt-8 mb-12 sm:max-w-sm mx-auto">
          <button class="ws-cta-primary w-full" @click="stepHandler">
            {{ buttonText }}
          </button>
        </div>
    
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      data: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
      },
      errors: null,
      questionnaireinstanceid: null,
      step: 1,
    }
  },
  components: {
    
  },

  computed: {
    buttonText() {
      let text = '';
      switch (this.step) {
        case 1:
         text = 'Next';
        break;
        case 2:
          text = 'Start Assessment';
        break;
        case 3:
          text = 'Create my account';
        break;
      }
      return text;
    },

    userId() {
      return this.$store.getters['user_id'];
    },

    isQuestionnaireCompleted() {
      return Boolean(localStorage?.getItem("isQuestionnaireCompleted"));
    },

    registerType() {
      return sessionStorage.getItem('register_type') || 'mot';
    },

    stepCount() {
      return this.registerType == 'consult' ? 2 : 3;
    }
  },

  watch: {
    'data.name'() {
      if (this.errors && this.errors.name) {
        this.errors.name = null;
      }
    },
    'data.email'() {
      if (this.errors && this.errors.email) {
        this.errors.email = null;
      }
    },
    'data.password'() {
      if (this.errors && this.errors.password) {
        this.errors.password = null;
      }
    },
    'data.password_confirmation'() {
      if (this.errors && this.errors.password) {
        this.errors.password = null;
      }
    }
  },

  mounted() {

    if (this.userId && this.isQuestionnaireCompleted) {
      this.step = 3;
    }

    if (localStorage?.getItem("email")) {
      this.data.email = localStorage.getItem("email");
      this.data.name = localStorage.getItem("name");
    }
  },

  methods: {
    async stepHandler() {
      switch (this.step) {
        case 1:
          localStorage.name = this.data.name;
          localStorage.email = this.data.email;
          if (this.registerType !== 'consult') {
            if (await this.preRegistration()) {
              this.step++;
              localStorage.isQuestionnaireCompleted = '';
            }
          }
          else {
            this.step = 3;
          }
        break;
        case 2:
          this.$router.push({ name: 'RegisterActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: this.questionnaireinstanceid} });
        break;
        case 3:
          this.registrationFinish();
        break;
      }
    },
    async preRegistration() {
      let status = true;
      await sendPost('/api/registration/check', {name: this.data.name, email: this.data.email}).then(response => {
        if (response) {
          this.errors = response.errors;
          if (this.errors) {
            status = false;
          } else {
            this.questionnaireinstanceid = response.questionnaire_instance_id;
            this.$store.dispatch('set_user_id', response.user_id);
          }
        }
      });
      return status;
    },
    async registrationFinish() {
      sendPost('/api/registration/finish', {
        password: this.data.password,
        password_confirmation: this.data.password_confirmation,
        user_id: this.userId,
        utm: this.getUtmParams(),
        name: this.data.name,
        email: this.data.email,
        register_type: this.registerType
      }).then(response => {
        if (response) {
          this.errors = response.errors;
        }
        if (!this.errors && response.status == 'ok') {
          this.$store.dispatch('set_user_id', null);
          localStorage.removeItem('isQuestionnaireCompleted');
          this.clearUtmParams();
          this.sendVerification();
          this.$router.push({ name: 'VerifyEmail'});
        }
     });
    },
    register() {
      this.errors = null
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/register', this.data)
          .then((response) => {
            this.$store.dispatch('attempt_user')
              .then(() => {
                this.$router.push({name: 'Home'})
              })
              .catch((error) => {
                console.error(error);
              })
          })
          .catch((error) => {
            this.errors = error.response.data.errors
          })
      });
    },
    sendVerification() {
      this.errors = null
      axios.post('/email/verification-notification')
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          this.errors = error.response.data
        })
    },
    getUtmParams() {
      const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
      const utmObject = {};

      utmParams.forEach(param => {
        const value = localStorage.getItem(param);
        if (value) {
          utmObject[param] = value;
        }
      });

      return utmObject;
    },

    clearUtmParams() {
      const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
      utmParams.forEach(param => {
        localStorage.removeItem(param);
      });
    }
  }
}
</script>

<script setup>

import Header from '../../website/components/Header';
import { sendPost } from '../../components/entities/functions.js';

</script>
