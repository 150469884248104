<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-90" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >

            <DialogPanel class="mx-auto m-4 w-full max-w-sm overflow-hidden rounded-xl bg-white p-8 shadow-xl transition-all">
              <DialogTitle>
                <img class="mx-auto h-20 w-auto" src="/img/webapp/activities/activity-complete.png" alt="activity complete" />
                <h3 class="pt-2 text-display-base text-center">Questionnaire Complete!</h3>
              </DialogTitle>
              <div class="pt-4 pb-8 mx-auto">
                <p class="text-center text-body-base">
                  Congrats on finishing the questionnaire. You can now see your results in the Deep Dive.
                </p>
              </div>
              <div class="px-12 mx-auto">
                <a type="button" class="button-information-modal" @click="goToDeepDive">Got it, thanks</a>
              </div>
            </DialogPanel>
           
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
</script>

<script>
export default {
  props: {
  },
  data: function () {
    return {
      isOpen: false,
    }
  },
  inject: ['questionnaireFinishActions'],
  mounted() {
    this.questionnaireFinishActions.openModal = this.openModal;
    this.questionnaireFinishActions.closeModal = this.closeModal;
  },

  beforeUnmount() {
    this.questionnaireFinishActions.openModal = null;
    this.questionnaireFinishActions.closeModal = null;
  },

  methods: {
    closeModal() {
      this.isOpen = false;
    },
    openModal() {
      this.isOpen = true;
    },
    goToDeepDive() {
      this.$router.push({ name: 'DeepDive' });
    }
  },
}

</script>
