import axios from "axios";

export const state = {
  questionnaireInstances: [],
  questionnaireInstance: [],
  readingInstances: [],
  readingInstance: [],
  worksheetInstances: [],
  worksheetInstance: [],
};

export const mutations = {
  
set_questionnaireInstances: (state, questionnaireInstances) => {
    state.questionnaireInstances = questionnaireInstances;
},
set_questionnaireInstance: (state, questionnaireInstance) => {
  state.questionnaireInstance = questionnaireInstance;
},
set_readingInstances: (state, readingInstances) => {
  state.readingInstances = readingInstances;
},
set_readingInstance: (state, readingInstance) => {
  state.readingInstance = readingInstance;
},

set_worksheetInstances: (state, worksheetInstances) => {
  state.worksheetInstances = worksheetInstances;
},
set_worksheetInstance: (state, worksheetInstance) => {
  state.worksheetInstance = worksheetInstance;
},

};
export const actions = {
  
  async fetchQuestionnaireInstances({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/questionnaire-instances"
      );
      commit("set_questionnaireInstances", response.data.questionnaire_instances);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchQuestionnaireInstance({ commit }) {
    try {
      let id = window.location.pathname.replace('/activities/questionnaire/','');
      const response = await axios.get(
        "/api/me/questionnaire-instance/" + id
      );
      commit("set_questionnaireInstance", response.data.questionnaire_instance);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchReadingInstances({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/reading-instances"
      );
      commit("set_readingInstances", response.data.reading_instances);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchReadingInstance({ commit }) {
    try {
      let id = window.location.pathname.replace('/activities/reading/','');
      const response = await axios.get(
        "/api/me/reading-instance/" + id
      );
      commit("set_readingInstance", response.data.reading_instance);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchWorksheetInstances({ commit }) {
    try {
      const response = await axios.get(
        "/api/me/worksheet-instances"
      );
      commit("set_worksheetInstances", response.data.worksheet_instances);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

  async fetchWorksheetInstance({ commit }) {
    try {
      let id = window.location.pathname.replace('/activities/worksheet/','');
      const response = await axios.get(
        "/api/me/worksheet-instance/" + id
      );
      commit("set_worksheetInstance", response.data.worksheet_instance);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  },

};

export const getters = {
  
  ['getQuestionnaireInstances'](state) {
      return state.questionnaireInstances;
  },
  ['getQuestionnaireInstance'](state) {
    return state.questionnaireInstance;
},
  ['getReadingInstances'](state) {
    return state.readingInstances;
},
['getReadingInstance'](state) {
  return state.readingInstance;
},
['getWorksheetInstances'](state) {
  return state.worksheetInstances;
},
['getWorksheetInstance'](state) {
  return state.worksheetInstance;
},
};

export default {
  state,
  actions,
  mutations,
  getters
};
