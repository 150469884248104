<template>

<!-- Header -->	
<div class="py-4 px-4 flex flex-row items-center bg-none sticky top-0 z-10 relative flex-shrink-0 flex">
        <div class="go-back grid items-center">
          <button @click="goBack">      
            <ChevronLeftIcon class="h-8 w-8 text-gray-700 stroke-2"/>
          </button>
        </div>
<!-- Logo -->	
          <div class="">
            <router-link to="/">
              <img class="h-10 w-auto sm:h-11" src="/img/logo-dark.svg" alt="Youlio logo " />
            </router-link>
        </div>
      </div>
<!-- END Header -->
    
</template>

<script setup>

  import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
  import { useRouter } from 'vue-router';

  const router = useRouter()

  function goBack() {
  return router.go(-1)
  }
  
</script>
