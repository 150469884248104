<template>
  <HeaderComponent :pageTitle="'Stage 3'" />
  
  <div class="overflow-x-hidden">
    <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div v-if="getGoals.length > 0">
        <!-- Loop through each goal -->
        <div v-for="(goal, index) in getGoals" :key="goal.id" class="mb-8 bg-white shadow-xl shadow-gray-300/10 rounded-lg" @click="goToGoalActions(goal.id)">
          <!-- Goal Title -->
          <div class="bg-gradient-to-r from-primary-200 to-secondary-200 py-4 px-6 rounded-lg">
            <h2 class="text-sm font-semibold text-primary-600 uppercase">Goal {{ index + 1 }}</h2>
            <p v-if="goal?.goal" class="text-base font-semibold text-secondary-600">{{ goal.goal }}</p>
          </div>
        </div>
      </div>
      
      <div v-else>
        <p class="cf-tp-no-entries-title"> 
          No current entries
        </p>  
        <p class="cf-tp-no-entries-body"> 
          You and your therapist will add your 'Actions' during treatment.
        </p>    
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import HeaderComponent from '../components/HeaderComponent';

const router = useRouter();

function goToGoalActions(goalId) {
  // Navigate to the Stage3Actions page with the goalId as a query parameter
  router.push({ name: 'Stage3Actions', query: { goalId } });
}
</script>

<script>
export default {
  computed: {
    getGoals() {
      // Fetch the goals from the store or props as needed
      return this.onClientPage ? this.goals : this.$store.getters.getGoals;
    }
  },
  mounted() {
    if (!this.onClientPage) {
      console.log('Dispatching fetchGoals');
      this.$store.dispatch("fetchGoals")
        .then(() => console.log('fetchGoals dispatched'))
        .catch(error => console.error('fetchGoals error:', error));
    }
  }
};
</script>
