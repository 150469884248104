<template>
  <InformationModal ref="informationModal"/>
  <ActivityFinishQuestionnaire ref="finishModal"/>
  <ActivityFinishQuestionnaireGroup ref="finishGroupModal" :title="groupTitle" :slug="slug"/>
<!--FullViewport-->
    <div class="h-screen">
<!--Header-->
      <header class="header-activity-container h-24 grid items-center">
        <img class="object-cover w-full h-24" :src="image" />
        <div class="absolute inset-0 bg-questionnaire-600 opacity-80 " />
        <div class="px-8 absolute">
            <div class="flex items-center">
              <div class="go-back">
                <button @click="goBack" class="flex items-center">
                <ChevronLeftIcon class="h-6 w-auto text-questionnaire bg-questionnaire-100 rounded-full stroke-2"/>
              </button>
            </div>
          <h2 class="ml-2 text-activity-start-display">{{ title }}</h2>
        </div>
      </div>
  <div class="h-2 w-full bg-gray-100">
    <div class="h-2 bg-primary-500 transition-all duration-300" :style="{ 'width': percentage }"></div>
  </div>
</header>
<!--END Header--> 

  <main class="questionnaire-layout"  > 
  <div class="" :class="{ 'min-h-screen': questions.length == 0 || true }" ref="questionnaireContainer">
  <div v-if="question" class="px-8 pt-2 md:pt-12 max-w-lg bg-white card-shadow min-h-screen">
    <h3 class="text-xl font-normal text-gray-700">{{ questionIndex + 1 }}. {{ question.title }} <span v-if="question.required && false">*</span></h3>
  <fieldset class="pt-8">
    <legend class="sr-only">Questions</legend>

<!--Answer Options-->
    <div v-if="question.type == 'Radio'"
      @click.prevent="select(index)"
      v-for="(option, index) in answerOptions"
      :key="'answer-option-' + index"
      class="flex items-center mb-4 bg-white card-shadow py-4 rounded-md min-w-fit max-w-xs cursor-pointer xl:hover:bg-emerald-700/25"
      :class="{'border-emerald-700': option.order == selected}">
    
      <input :id="'option-' + index" type="radio" name="answers" :value="option.order" v-model="selected" class="hidden peer">
      <label :for="'option-' + index" class="block ml-4 text-lg font-normal text-gray-800 dark:text-gray-300 cursor-pointer">
        <span class="py-1 px-2 text-sm font-medium"
          :class="{'bg-emerald-800 text-white': option.order == selected, 'bg-primary  text-white': option.order != selected}">
          {{ getLetterByIndex(index) }}
        </span>
        <span class="ml-4 font-normal text-emerald-900">
          {{ option.value }}
        </span>
      </label>
    </div>
  </fieldset>
  <div class="flex flex-wrap gap-4">
   
    <p v-show="answerIsRequired" class="text-base text-questionnaire-600">Please provide a response.</p>
  </div>
</div>



<!-- Footer-->
<div class="fixed bottom-0 w-screen max-w-lg h-16 standalone:h-20 bg-questionnaire-100 px-6  pt-5 z-10">
        <div class="flex justify-between">
          <button type="button"
            class="flex font-semibold"
            :class="{'text-questionnaire': questionIndex !== 0, 'text-questionnaire text-opacity-20': questionIndex === 0}"
            @click="previousQuestionHandler"
          >
          <ArrowLeftIcon class="h-7 w-7 stroke-2"/><span></span>
          </button>

          <div class="absolute left-1/2 transform -translate-x-1/2">
          <p class="text-questionnaire font-semibold">
            <span class="h-6 w-6 stroke-2">
              {{ questionIndex + 1 }}/{{ questions.length }}
            </span>
          </p>
        </div>

        <button v-if="questionIndex < questions.length" @click="nextQuestionHandler" class="flex text-questionnaire font-semibold">
          <span v-show="showFinishButton">{{ nextQuestionnaire ? 'NEXT' : 'FINISH' }}</span>

          <ArrowRightIcon v-if="questionIndex < questions.length - 1" class="h-7 w-7 stroke-2"/>
          </button>
        </div>
      </div>
    </div>
  </main>
  </div>

</template>


<script setup>

 import GoBack from '../components/GoBack';
 import {ClockIcon, CircleStackIcon } from '@heroicons/vue/24/solid'
 import { ChevronLeftIcon, ChevronRightIcon, HomeIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
 import ActivityFinishQuestionnaire from '../components/ActivityFinishQuestionnaire';
 import ActivityFinishQuestionnaireGroup from '../components/ActivityFinishQuestionnaireGroup';
 import { useRouter } from 'vue-router';
 import { sendPost } from '../components/entities/functions.js';
 import InformationModal from '../components/InformationModal';

const router = useRouter()

    function goBack() {
        return router.go(-1)
}

  </script>

<script>
export default {
  data() {
    return {
      title: '',
      instructions: '',
      image: '',
      answerOptions: [],
      questions: [],
      isGroup: false,
      nextQuestionnaire: null,
      slug: null,
      groupTitle: null,

      questionAnswers: {},
      selected: null,
      questionIndex: 0,
      answerIsRequired: false,
      isDataBeenSent: false,
      userId: null,
      isAssessment: false,

      informationModalActions: {
        closeModal: null,
        openModal: null
      },
      questionnaireFinishActions: {
        closeModal: null,
        openModal: null
      },
      questionnaireGroupFinishActions: {
        closeModal: null,
        openModal: null
      },
    };
  },

  mounted() {
    this.userId = this.$store.getters['user_id'];
    this.isAssessment = this.$store.getters['is_assessment'];
    this.getQuestions();
    window.addEventListener('resize', this.adjustQuestionnaireContainerHeight);
  },

  beforeUnmount() {
      window.removeEventListener('resize', this.adjustQuestionnaireContainerHeight);
   },

  provide: function() {
    return {
      informationModalActions: this.informationModalActions,
      questionnaireFinishActions: this.questionnaireFinishActions,
      questionnaireGroupFinishActions: this.questionnaireGroupFinishActions
    };
  },

  watch: {
    instanceid() {
      this.getQuestions();
      if (this.userId) {
        localStorage.isQuestionnaireCompleted = '';
      }
    }
  },

  computed: {
    instanceid() {
      return this.$route.params.questionnaireinstanceid;
    },
    question() {
      if (!this.questions || this.questions.length == 0) {
        return null;
      }
      return this.questions[this.questionIndex];
    },
    percentage() {
      const percentage = ((this.questionIndex + 1) / this.questions.length) * 100;
      return Math.round(percentage) + '%';
    },
    questionsAnswered() {
      const count = Object.keys(this.questionAnswers).length;
      return count;
    },
    showFinishButton() {
      return this.questionsAnswered === this.questions.length && this.questionIndex === this.questions.length - 1;
    }
  },

  methods: {
    async getQuestions() {
      this.questionIndex = 0;
      this.selected = null;
      this.isDataBeenSent = false;
      this.questionAnswers = {};

      const response = await sendPost('/api/content/questionnaire-instance/' + this.instanceid + '/begin', {'user_id': this.userId});
      if (response) {
        if (response.unregistered) {
          this.$router.go(-1);
          return;
        }

        this.title = response.title;
        this.instructions = response.instructions;
        this.image = response.image;
        this.questions = response.questions;
        this.answerOptions = response.answerOptions;
        this.isGroup = response.isGroup;
        this.nextQuestionnaire = response.nextQuestionnaire;
        this.slug = response.slug;
        this.groupTitle = response.groupTitle;
        this.openModal(this.title, this.instructions);
      }
    },

    select(index) {
      this.answerIsRequired = false;
      if (this.selected == this.answerOptions[index].order) {
        delete this.questionAnswers[String(this.question.id)];
        this.selected = null;
        return;
      }
      this.selected = this.answerOptions[index].order;
      this.questionAnswers[String(this.question.id)] = this.selected;
      if (this.questionIndex < this.questions.length - 1) {
        this.nextQuestionHandler();
      }
    },

    getLetterByIndex(index) {
      const charCode = index + 65;
      const letter = String.fromCharCode(charCode);
      return letter;
    },

    previousQuestionHandler() {
      if (this.questionIndex - 1 < 0) {
        return;
      }
      this.answerIsRequired = false;
      this.questionIndex--;
      this.selected = this.questionAnswers[String(this.question.id)];
    },

    async nextQuestionHandler() {
      if (this.question.required && this.selected === null) {
        this.answerIsRequired = true;
        return;
      }

      if (this.questionIndex + 1 < this.questions.length) {
        this.selected = null;
        this.questionIndex++;
      } else {
        if (!this.isGroup) {
          this.setFinishModalState(true);
        } else if (!this.nextQuestionnaire) {
          localStorage.isQuestionnaireCompleted = 1;
          this.setFinishGroupModalState(true);
        }

        if (!this.isDataBeenSent) {
          this.isDataBeenSent = true;
          let isLast = this.isGroup && !this.nextQuestionnaire;
          await sendPost('/api/content/questionnaire-instance/' + this.instanceid + '/finish', { answers: this.questionAnswers, user_id: this.userId, last_questionnaire: isLast });
        }

        if (this.nextQuestionnaire) {
          if (!this.userId) {
            this.$router.push({ name: 'ActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: this.nextQuestionnaire } });
          } else {
            this.$store.dispatch('set_user_id', this.userId);
            this.$router.push({ name: 'RegisterActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: this.nextQuestionnaire } });
          }
        }

        return;
      }

      if (this.questionAnswers[String(this.question.id)] !== undefined) {
        this.selected = this.questionAnswers[String(this.question.id)];
      }
    },

    adjustQuestionnaireContainerHeight() {
      if (this.questions.length == 0 || true) {
        return;
      }
      const questionnaireContainer = this.$refs.questionnaireContainer;
      const offsetTop = questionnaireContainer.offsetTop;
      questionnaireContainer.style.height = `calc(100vh - ${offsetTop}px)`;
    },

    openModal(title, text) {
      if (title && text) {
        this.informationModalActions.openModal(title, text);
      }
    },

    closeModal() {
      this.informationModalActions.openModal();
    },

    setFinishModalState(state) {
      if (state && this.questionnaireFinishActions.openModal) {
        this.questionnaireFinishActions.openModal();
      }
      if (!state && this.questionnaireFinishActions.closeModal) {
        this.questionnaireFinishActions.closeModal();
      }
    },

    setFinishGroupModalState(state) {
      if (state && this.questionnaireGroupFinishActions.openModal) {
        this.questionnaireGroupFinishActions.openModal(this.userId, this.isAssessment);
      }
      if (!state && this.questionnaireGroupFinishActions.closeModal) {
        this.questionnaireGroupFinishActions.closeModal();
      }
    },
  }
};
</script>
