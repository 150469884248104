<template>
  <ActivityFinishReading ref="finishModal"/>
<!--FullViewport-->
  <div class="h-screen">
    
<!--Header-->
    <header class="header-activity-container grid items-center">
        <img class="object-cover w-full h-24" :src="image" />
        <div class="absolute inset-0 bg-reading-600 opacity-80 " />

          <div class="px-8 absolute">
            <div class="flex items-center">
              <div class="go-back">
                <button @click="goBack" class="flex items-center">
                <ChevronLeftIcon class="h-6 w-auto text-reading bg-reading-100 rounded-full stroke-2"/>
              </button>
            </div>
          <h2 class="ml-2 text-activity-start-display">{{ title }}</h2>
        </div>       
        </div>
    </header>
<!--END Header-->

  <main class="reading-layout card-shadow">  
    <slide-maker
      ref="slideMakerRef"
      :htmlContent="htmlContent"
      :overridingStyles="sliderStyles"
      @slide-count="handleSlideCount"
      @slide-active="handleSlideActive">
    </slide-maker>
  </main>

<!--Footer-->
    <div v-if="showControls" class="md:ml-96 fixed inset-x-0 bottom-0 w-screen max-w-lg h-16 standalone:h-20 bg-reading-100 px-6 pt-5 z-10">
      <div class="flex justify-between">
        <button type="button"
          class="flex font-semibold"
          :class="{'text-reading': activeSlide != 1, 'text-reading text-opacity-20': activeSlide == 1}"
          @click="previousSlide"
        >
        <ArrowLeftIcon class="h-7 w-7 stroke-2"/><span></span>
        </button>

        <div class="absolute left-1/2 transform -translate-x-1/2">
          <p class="text-reading font-semibold">
            <span class="h-6 w-6 stroke-2">
              {{ activeSlide }}/{{ slideCount }}
            </span>
          </p>
        </div>

        <button type="button"
          class="flex font-semibold text-reading"
          @click="nextSlide">
        <ArrowRightIcon v-if="activeSlide !== slideCount" class="h-7 w-7 stroke-2"/> 
        <span v-else class="tracking-wide font-bold">FINISH</span>
        </button>
      </div>
    </div>
  </div>
<!--END Footer-->

</template>

<script setup>

import ActivityFinishReading from '../components/ActivityFinishReading';
import { ChevronLeftIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
import SlideMaker from '../components/SlideMaker';
import { useRouter } from 'vue-router';
import { sendPost } from '../components/entities/functions.js';

const router = useRouter()

    function goBack() {
        return router.go(-1)
}

</script>

<script>
export default {
  data: function () {
    return {
      title: '',
      image: '',
      htmlContent: '',
      instanceid: null,
      activeSlide: 1,
      slideCount: 1,
      slideActions: {
        nextSlide: null,
        previousSlide: null
      },
      readingFinishActions: {
        closeModal: null,
        openModal: null
      },
      sliderStyles: {
        '--swiper-theme-color': '#60a5fa'
      },
      showControls: false
    }
  },
  mounted() {
    this.instanceid = this.$route.params.readinginstanceid;
    this.getContent();
  },

  computed: {
    finishButtonUrl() {
      return '/api/content/reading/' + this.instanceid + '/finish';
    }
  },

  provide: function() {
    return {
      slideActions: this.slideActions,
      readingFinishActions: this.readingFinishActions
    };
  },

  methods: {
    async getContent() {
      const response = await sendPost('/api/content/reading/' + this.instanceid);
      this.title = response.title;
      this.image = response.image;
      this.htmlContent = response.body;
    },

    handleSlideCount(count) {
      this.showControls = true;
      this.slideCount = count;
    },

    handleSlideActive(index) {
      this.activeSlide = index;
    },

    async nextSlide() {
      if (this.activeSlide === this.slideCount) {
        await sendPost(this.finishButtonUrl);
        this.setModalState(true);
        return;
      }

      if (this.slideActions && this.slideActions.nextSlide) {
        this.slideActions.nextSlide();
      }
    },

    previousSlide() {
      if (this.slideActions && this.slideActions.previousSlide) {
        this.slideActions.previousSlide();
      }
    },

    setModalState(state) {
      if (state && this.readingFinishActions.openModal) {
        this.readingFinishActions.openModal();
      }
      if (!state && this.readingFinishActions.closeModal) {
        this.readingFinishActions.closeModal();
      }
    }
  },
}

</script>
