<template>
    <div class="go-back">
        <button @click="goBack">      
        <ChevronLeftIcon class="chevron-dark -ml-2" />
        </button>
    </div>
</template>

<script setup>

import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { useRouter } from 'vue-router';

const router = useRouter()

    function goBack() {
        return router.go(-1)
}
</script>
